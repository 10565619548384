import React from "react"
import SME from "../components/SME/SME";
import Header from "../components/Layout/Header";

const SMEPage = () => {
  return (
    <div>
      <Header />
      <SME />
      </div>
  );
};


export default SMEPage 

