import axios from "axios";
import { server } from "../../server";

// create stask
export const createSTask =
  (
    name,
    btdescription,
    description,
    category,
    tags,
    taskPayment,
    paymentMode,
    taskDuration,
    durationMode,
    tools,
    requiredSkill,
    taskEnvironment,
    resourceLink1,
    resourceLink2,
    resourceLink3,
    taskQuantity,
    targetedCountry,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "staskCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/stask/create-stask`,
    name,
    btdescription,
    description,
    category,
    tags,
    taskPayment,
    paymentMode,
    taskDuration,
    durationMode,
    tools,
    requiredSkill,
    taskEnvironment,
    resourceLink1,
    resourceLink2,
    resourceLink3,
    taskQuantity,
    targetedCountry,
    shopId,
    images
      );
      dispatch({
        type: "staskCreateSuccess",
        payload: data.stask,
      });
    } catch (error) {
      dispatch({
        type: "staskCreateFail",
        payload: error.response.data.message,
      });
    }
  };


  // get all stasks
  export const getAllSTasks = () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllSTasksRequest",
      });
  
      const { data } = await axios.get(`${server}/stask/get-all-stasks`);
      dispatch({
        type: "getAllSTasksSuccess",
        payload: data.stasks,
      });
    } catch (error) {
      dispatch({
        type: "getAllSTasksFailed",
        payload: error.response.data.message,
      });
    }
  };


  // get All STasks of a shop
export const getAllSTasksShop = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "getAllSTasksShopRequest",
      });
  
      const { data } = await axios.get(
        `${server}/stask/get-all-stasks-shop/${id}`
      );
      dispatch({
        type: "getAllSTasksShopSuccess",
        payload: data.stasks,
      });
    } catch (error) {
      dispatch({
        type: "getAllSTasksShopFailed",
        payload: error.response.data.message,
      });
    }
  };


  // load stask
export const loadSTask = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSTaskRequest",
    });
    const { data } = await axios.get(`${server}/stask/get-stask`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadSTaskSuccess",
      payload: data.stask,
    });
  } catch (error) {
    dispatch({
      type: "LoadSTaskFail",
      payload: error.response.data.message,
    });
  }
};

  // delete stask of a shop
export const deleteSTask = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteSTaskRequest",
      });
  
      const { data } = await axios.delete(
        `${server}/stask/delete-shop-stask/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteSTaskSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteSTaskFailed",
        payload: error.response.data.message,
      });
    }
  };

  // get all stasks --- Open
export const getAllSTasksOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllSTasksOpenRequest",
    });

    const { data } = await axios.get(`${server}/stask/open-all-stasks`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllSTasksOpenSuccess",
      payload: data.stasks,
    });
  } catch (error) {
    dispatch({
      type: "getAllSTasksOpenFailed",
      payload: error.response.data.message,
    });
  }
};