import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

function Hero3() {
    const { seller } = useSelector((state) => state.seller);

  return (
    <div className='w-full'>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className='w-full 800px:w-[50%]'>
        <br />
        <img
            src="https://cdni.iconscout.com/illustration/free/thumb/free-concept-of-data-analysis-and-maintenance-2040897-1721893.png?f=webp"
            alt=""
            className="w-[90%] p-3"
          />

<h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          Clone-<span className="text-[#c89b2a]">Edit</span> Options
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        The Totlesoft platform is tailored to streamline client projects. When you put up a project or campaign as a client, you won't need to start from scratch. Simply select from our range of customizable templates and utilize our Clone & Edit feature to personalize your chosen template. Add your project requirements, and you're all set!  
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        All our templates are completely customizable, saving you the effort of creating one from scratch. Have a project in mind? Don't hesitate any longer—let's start right away!
        </p>
        
       {seller ? (
          <Link to="/dashboard" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Create Campaign
               </span>
          </div>
      </Link>
        ):(
          <Link to="/sign-up" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 Get Started
                 </span>
            </div>
        </Link>
        )}
        <br />
        <br />
        
        <br />
        </div>
        
        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          Customizable Camp<span className="text-[#c89b2a]">aign</span><br /> Templates
        </h1>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        Select from our collection of campaign templates to begin your project as a company or business owner: 
        </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Transcription</span> - Audio/Video/Image <br />
        * <span className="font-[500] mb-3">Social Media Promotion</span> - Follow/Likes/Views/Subscribe <br />
        * <span className="font-[500] mb-3">Research Study</span> - Various survey categoriries <br />
        * <span className="font-[500] mb-3">Data Annotation</span> - Image/Video <br />
        * <span className="font-[500] mb-3">Content Moderation</span> - Ads monitoring tasks <br />
        * <span className="font-[500] mb-3">Rating</span> - Image/Audio/Video/Website <br />
        * <span className="font-[500] mb-3">Data Mining</span> - For collecting spesifications <br />
        * <span className="font-[500] mb-3">Catigorization</span> - Website/Products etc. <br />
        * <span className="font-[500] mb-3">Sentiments Analysis</span> - For determining relevance <br />
        * <span className="font-[500] mb-3">Content Moderation AI</span> - Artificial conversations <br />
        * <span className="font-[500] mb-3">Tagging</span> - Image/Products etc. <br />
        <Link to="#"><span className="text-[teal] font-[500] mb-3 ml-3">See full list</span></Link>
        </p>

        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] mt-3"
        >
          Unmatched exper<span className="text-[#c89b2a]">tise</span><br /> at affordable rates tailored for your needs.
        </h1>
        
        <br />
        <img
            src="https://www.askdataentry.com/wp-content/uploads/2023/03/Semantic-Segmentation.png"
            alt=""
            className="w-[90%] p-3"
          />
        
        </div>
        
        </div>


        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className={`${styles.section} w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         Open Project/Campaign
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Client can run an "Open" Project/Campaign, by choosing a targeted Campaign Zones/Locations. You have the option to choose your targeted country, however, you won't be able to determine or choose Workers from the selected country as every available Worker from the chosen countries will be able to access and accept your task. 
       </p>
        <br />
        </div>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         Totlesoft Direct Hire Grouping
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        "Direct Hire Grouping" is a special Campaign plan that allow a Client to assign choice Workers to their projects. With this package, Client can be able to create special Groups of Workers and assign tasks to that particular Group. If the Client want more Workers to perform their task, they can be able to add more Workers to the Group even while the project/campaign is runing. Take advantage of Totlesoft's search query tools to search for good Workers, sorted by different criteria and hire only the best of Workers. 
       </p>
        <br />
        
        </div>
        </div>

    </div>
  )
}

export default Hero3
