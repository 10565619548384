import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const newcodeReducer = createReducer(initialState, {
  // get all newcodes for codeVendor
  getAllNewCodesUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllNewCodesUserSuccess: (state, action) => {
    state.isLoading = false;
    state.newcodes = action.payload;
  },
  getAllNewCodesUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all newcodes for admin
  adminAllNewCodesRequest: (state) => {
    state.adminNewCodesLoading = true;
  },
  adminAllNewCodesSuccess: (state, action) => {
    state.adminNewCodesLoading = false;
    state.adminNewCodes = action.payload;
  },
  adminAllNewCodesFailed: (state, action) => {
    state.adminNewCodesLoading = false;
    state.error = action.payload;
  },

  // get all newcodes for all users
  getAllNewUCodesUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllNewUCodesUserSuccess: (state, action) => {
    state.isLoading = false;
    state.newcodes = action.payload;
  },
  getAllNewUCodesUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
