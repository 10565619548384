import React from "react";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";

const TaskTnCDetails = () => {
    return (
        <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[80%] 800px:w-[70%]`}>
        <br />
        <img
            src="https://vakilsearch.com/blog/wp-content/uploads/2022/05/master-service-agreement-vs-statement-of-work.jpg"
            alt=""
            className="w-[90%] mb-[15px] mt-3"
          />
        <br />
       <h1
          className={`text-[25px] leading-[1.2] 800px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Tasks Submission Agreement. 
        </h1> 
        <h2 className="pt-5 text-[16px] text-[blue] font-[Poppins] text-[#000000ba]">We're always ensuring clients gets the complete work credit they paid for hence, theses conditions.</h2>
        
        <h3
          className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]"
        >
         Bellow follows Totlesoft Terms and Conditions of Task Submission, which will apply to the tasks done by workers. It collectively constitute the agreed conditions for performing tasks on our platform.
        </h3>
        <br />
        

        <h3
        className={`text-[20px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
        Social Meadia Promotional Tasks Completion:
        </h3>
        
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Every worker performing or about to perform any task on our platform is mandated to have an account with the targeted social handles with an active username. by account we mean Facebook, Instagram, Youtube, Spotify, Linkedin, Twitter, Tiktok, Boomplay, Soundcloud etc.
        Workers are also expected to work only on tasks that are open to their location or worldwide. Workers are not to use VPN to cross-work on tasks outside their country of resident or outside their work scope.
        By conitinuing to work and submiting task on our platform, you have simply agreed to the following: 
        </p>
        <br />

        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        1. You have an account with the targeted social handle with an active username.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        2. You have understood the task description and have the means to complete the task.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        3. You have truly completed all instruction on the task, e.g liked, followed, comment, watched video, downloaded the app, subscribed to channel etc; have cross-checked thoroughly, and have also agreed that your username will be found in the client's targeted channel/posts etc, before hitting the done button.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        4. You are working on the task because it is open to workers in your location/worldwide.
        </p>
        <br />
        <h3
        className={`text-[20px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
        Attestation:
        </h3>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        By Obligation to the above statement and confirmation, you simply agreed that if you're found wanting in breaking any of the rules above, Totlesoft Technologies have the sole right to suspend your account or freez your founds till there's a resonable resolution. 
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        In the same vain, you agreed that Totlesoft Technologies will not be liable for any lose incured in such occation.
        </p>
        <br />
       
        <p className="pt-5 text-[16px] text-[blue] font-[Poppins] text-[#000000ba]">
        You're once agin advise to keep these laws in mind while submitting tasks on our platform. 
        </p>
        
        <Link to="/tasks" className="inline-block" target="_blank">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[14px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Return 
                 </span>
            </div>
        </Link>
        <br /><br /><br />

       
      </div>
    </div>
        
    );
}

export default TaskTnCDetails;