import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { getAllUsedCodesOfUser } from "../../redux/actions/usedcode";

const UsedCodes = () => {
  
  const dispatch = useDispatch();
 
  const { usedcodes, isLoading } = useSelector(
    (state) => state.usedcode
  );

  useEffect(() => {
    dispatch(getAllUsedCodesOfUser());
  }, []);

  
  const columns = [
    { field: "id", headerName: "Code ID", minWidth: 150, flex: 0.7 },

    {
      field: "code",
      headerName: "Code",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "codeUser",
      headerName: "Code User",
      minWidth: 80,
      flex: 0.7,
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 80,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 80,
      flex: 0.8,
    },
  ];

  const row = [];

  usedcodes &&
    usedcodes.forEach((item) => {
      row.push({
        id: item._id,
        code: item.code,
        codeUser: item.codeUser,
        time: item.createdAt.slice(11, 16),
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default UsedCodes;

