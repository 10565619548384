import React from 'react'
import UserInvestDashboardHeader from '../components/UserInvestDashboardHeader';
import UserInvest from "../components/UserInvest";
import UserInvestDashboardSideBar from "../components/UserInvestDashboardSideBar";

const UserInvestPage = () => {
  return (
    <div>
    <UserInvestDashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <UserInvestDashboardSideBar active={2} />
      </div>
       <UserInvest />
    </div>
  </div>
  )
}

export default UserInvestPage;