import React, {useState } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import Spinner from "../../src/components/Spinner/Spinner";
import { RxCross1 } from "react-icons/rx";
import tPay from '../Assests/logos/totlesoftPay.png';
import { Link } from "react-router-dom";
import { dollarRate } from "../dollarRate";
import { PaystackButton } from "react-paystack";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { MdOutlineFolderCopy } from "react-icons/md";

const UserInvest = () => {
  const [open, setOpen] = useState(false);
  const [openCardPayment, setOpenCardPayment] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);
  const [openCrypto, setOpenCrypto] = useState(false);
  const [openBank, setOpenBank] = useState(false);
  const [createCPayInvoice, setCreateCPayInvoice] = useState(true);
  const [showPayCPButton, setShowPayCPButton] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceOrderId, setInvoiceOrderId] = useState("");
  const [pDtoUse, setPDtoUse] = useState([]);

 const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [damount, setDamount] = useState(5);
  const [camount, setCamount] = useState(0);
  const [pamount, setPamount] = useState(5);
  const [duration, setDuration] = useState(0);
  
  const tam = user?.availableBalance;
  const email = user?.email;
  const amount = Number(damount);
  const investorID = user?._id;
  
  const error = () => {
    toast.error("Balance Insuficient!!");
  };

  const payPalAmount = Number(pamount);

  console.log(payPalAmount);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Deposit to Payment Balance",
            amount: {
              currency_code: "USD",
              value: payPalAmount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler();
      }
    });
  };

  const paypalPaymentHandler = async () => {
    
    const investorName = user?.name;
    const investorCountry = user?.country;
    const amountOfTST = Number(payPalAmount);
    const profit = amountOfTST * duration / 100;
    const tPluseC = Number(amountOfTST) + Number(profit);
    const referrer = user?.referrer;

    setIsLoading(true);
      await axios
      .post(
        `${server}/investment/invest/via-others`,
        { investorID, email, investorName, investorCountry, amountOfTST, profit, duration, tPluseC, referrer },
        { withCredentials: true }
      )
      .then((res) => {
          setIsLoading(false);
          setOpenPaypal(false);
          toast.success("Investment successful!");
          window.location.reload();
          });
  };

  
    const publicKey = "pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964";
    const dollar_rate = dollarRate;
    const paymentInNaira = Math.round(damount * dollar_rate)
  
    const componentProps = {
      email: user?.email,
      amount: Math.round(damount * 100 * dollar_rate),
      publicKey,
      referenceID: +Math.floor((Math.random() * 1000000000) + 1),
      text: "Pay now",
      onSuccess: () => ngnInvestmentHandler(),
      onClose: function() {
        alert('Transaction was not completed, window closed.');
      },
     
    }
  
  
  const balanceInvestHandler = async (e) => {

    const investorName = user?.name;
    const investorCountry = user?.country;
    const amountOfTST = amount;
    const profit = amountOfTST * duration / 100;
    const tPluseC = Number(amountOfTST) + Number(profit);
    const referrer = user?.referrer;


    e.preventDefault();

    if (damount < 5 || damount > tam) {
      toast.error("Balance Insuficient!");
    } else {
      setIsLoading(true);
      await axios
        .post(
          `${server}/investment/invest/via-balance`,
          { investorID, email, investorName, investorCountry, amountOfTST, profit, duration, tPluseC, referrer },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          setOpen(false)
          toast.success("Investment successful!");
          window.location.reload();
        });
    }
  };

  const ngnInvestmentHandler = async () => {

    const investorName = user?.name;
    const investorCountry = user?.country;
    const amountOfTST = Number(damount);
    const profit = amountOfTST * duration / 100;
    const tPluseC = Number(amountOfTST) + Number(profit);
    const referrer = user?.referrer;

    setIsLoading(true);
      await axios
      .post(
        `${server}/investment/invest/via-others`,
        { investorID, email, investorName, investorCountry, amountOfTST, profit, duration, tPluseC, referrer },
        { withCredentials: true }
      )
      .then((res) => {
          setIsLoading(false);
          setOpenCardPayment(false)
          toast.success("Investment successful!");
          window.location.reload();
          });
  };

  const invData = {
        amount: camount,
        currency: "USD",
        additional_data: user?.email,
  }

  
  const creatCPInvoice = async () => {
    setIsLoading(true);

   try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${server}/payment/crypto-checkout`,
        invData,
        config
      );

      const url = data.url;
      const order_id = data.order_id;
      //const sign = data.sign;

      if(url !== null && order_id !== null){
        setInvoiceUrl(url);
        setInvoiceOrderId(order_id);
        setCreateCPayInvoice(false);
        setShowPayCPButton(true);
        setIsLoading(false);
      }
    }
    catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };  

  
  const confirmCPaymentInfo = async () => {
    setIsLoading(true);

   try {

    const order_id = invoiceOrderId;

         
    const { data } = await axios.get(
        `${server}/payment/crypto-payment-info/${order_id}`);
        setPDtoUse(data);

        if(!data){
          toast.error("Payment not found with this order Id")
        }

      const status = pDtoUse.cryptopayments[0].status;
      const is_final = pDtoUse.cryptopayments[0].is_final;

      if(status === "paid" && is_final === true){
               
          cryptoPaymentHandler();
         
      }else{
        toast.error("Payment is still processsing");
        setIsLoading(false);
      }
    }
    catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  }; 

  
  const cryptoPaymentHandler = async () => {

    const investorName = user?.name;
    const investorCountry = user?.country;
    const amountOfTST = camount;
    const profit = amountOfTST * duration / 100;
    const tPluseC = Number(amountOfTST) + Number(profit);
    const referrer = user?.referrer;

    setIsLoading(true);
      await axios
      .post(
        `${server}/investment/invest/via-others`,
        { investorID, email, investorName, investorCountry, amountOfTST, profit, duration, tPluseC, referrer },
        { withCredentials: true }
      )
      .then((res) => {
          setIsLoading(false);
          setOpenCrypto(false)
          toast.success("Investment successful!");
          window.location.reload();
          });
  };

  
  const orderID = invoiceOrderId;

  const writeTextToClipboard = (text) => {
    text = orderID;
    navigator.clipboard.writeText(text);
    toast.success("Id Copied!");
  };

  

  
  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full h-[90vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
      <div className="800px:w-[30%] 800px:h-[95%] bg-[#F6F6F5] w-[90%] h-[95%] rounded flex text-center items-center justify-center flex-col 800px:py-2">
        <h5 className="text-[20px] text-[blue]">
          Active Investment (TST): 
        </h5>
        <h2 className="text-[35px] font-[600] pb-1">
        {user?.activeInvestmentBalance === undefined ? 0 : user?.activeInvestmentBalance}
        </h2>
        <h6 className="text-[15px] pb-2">
         Choose Payment Method
        </h6>
        <div
          className={`${styles.button} text-white !h-[42px] !rounded`}
          onClick={() => (tam < 5 ? error() : setOpen(true))}
        >
          Balance
        </div>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenCardPayment(true)}
        >
         Naira 
        </h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenPaypal(true)}
        >
        USD/Paypal 
        </h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenBank(true)}
        >
         Bank 
        </h5>
       <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenCrypto(true)}
        >
         Crypto 
        </h5>
       <Link to="/profile">
        <h5 className="text-[18px] text-[blue] pt-1">
         Cancel 
        </h5>
        </Link>
        <div>
        <img
            src={tPay}
            width='150px'
            alt="totlesoftPay"
          />
        </div>
        </div>
        </div>
        {open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div
            className={`w-[95%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}
          >
                <form onSubmit={balanceInvestHandler}>
                    <div>
                    <br />
                  <div className=" w-[100%] 800px:w-[50%]">
                 <h5 className={`${styles.input} !w-[95%] mb-2 800px:mb-2 text-[600] text-[blue]`}>1TST = 1USD</h5>
                 <label className="block pb-2">Amount TST</label>
                    <div className="mt-1">
                    <select
                    name="damount"
                    onChange={(e) => setDamount(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    </select>
                  </div>
                </div> 
                <br />

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Duration (Days)</label>
                  <div className="mt-1">
                    <select
                    name="duration"
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="7">7</option>
                    <option value="14">14</option>
                    <option value="21">21</option>
                    <option value="28">28</option>
                    </select>
                  </div>
                </div> 

                      <button
                        className={`${styles.button} !h-[42px] text-white`}
                        type="submit"
                      >
                        Confirm
                      </button>
                    </div>
                    <button
                        className={`${styles.button} !h-[42px] text-white mt-5`}
                        onClick={() => setOpen(false)}
                       >
                        Cancel
                      </button>
                    </form>
          </div>
          
        </div>
      )}

            {openPaypal && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                  <div className="w-full flex justify-end p-3">
                    <RxCross1
                      size={30}
                      className="cursor-pointer absolute top-3 right-3"
                      onClick={() => setOpenPaypal(false)}
                    />
                  </div>

                  <form>
                  <br />
                  <div className=" w-[100%] 800px:w-[50%]">
                 <h5 className={`${styles.input} !w-[95%] mb-2 800px:mb-2 text-[600] text-[blue]`}>1TST = 1USD</h5>
                 <label className="block pb-2">Amount TST</label>
                    <div className="mt-1">
                    <select
                    name="pamount"
                    onChange={(e) => setPamount(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    </select>
                  </div>
                </div> 
                <br />

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Duration (Days)</label>
                  <div className="mt-1">
                    <select
                    name="duration"
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="7">7</option>
                    <option value="14">14</option>
                    <option value="21">21</option>
                    <option value="28">28</option>
                    </select>
                  </div>
                </div> 
                     <br />

                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "AWhLs3ILRhUxqvgpcKjd6rvoU47rg_l7Yyc_GW-InHPkuLbpCouEE29HniCePvK8L1QxbneMxJezAzUq",
                      }}
                    >
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        onApprove={onApprove}
                        createOrder={createOrder}
                       />
                    </PayPalScriptProvider>
                    </form>
                </div>
              </div>
            )}



        {openCardPayment && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div
            className={`w-[95%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}
          >
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCardPayment(false)}
                className="cursor-pointer"
              />
            </div >
                <form>
                    <div>
                    <br />
                  <div className=" w-[100%] 800px:w-[50%]">
                 <h5 className={`${styles.input} !w-[95%] mb-2 800px:mb-2 text-[600] text-[blue]`}>1TST = 1USD</h5>
                 <label className="block pb-2">Amount TST</label>
                    <div className="mt-1">
                    <select
                    name="damount"
                    onChange={(e) => setDamount(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    </select>
                  </div>
                </div> 
                <br />

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Duration (Days)</label>
                  <div className="mt-1">
                    <select
                    name="duration"
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="7">7</option>
                    <option value="14">14</option>
                    <option value="21">21</option>
                    <option value="28">28</option>
                    </select>
                  </div>
                </div> 
                <br />

                      <div>
                      <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mt-3">
                      USD/Naira Rate:
                      </label>
                     <div className="mt-1">
                      <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      1USD = {dollar_rate}NGN
                      </h4>
                      </div>
                    </div>

              <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700 mt-3">
                 Amount to pay in Naira
               </label>
               <div className="mt-1">
                 <div>
                <h5
                name="taskbudget"
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >{paymentInNaira}</h5>
                </div>
                </div>
              </div>
              
              <br />
              </div>
              </form>

              <PaystackButton {...componentProps}
             className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
             type="submit"
            />
            </div>
          
            </div>
      )}


          {openCrypto && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] 800px:w-[30%] bg-white shadow rounded h-[70%] min-h-[50vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCrypto(false)}
                className="cursor-pointer"
              />
            </div>
            <h5 className="text-[red] text-[14px] font-[500]">Keep page open till the payment has been marked as "paid"</h5><br />
        

            {createCPayInvoice && 
            <>
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p>Click button bellow to generate crypto payment invoice</p> 

        <form onSubmit={creatCPInvoice}>
        <div>
        <br />
                  <div className=" w-[100%] 800px:w-[50%]">
                 <h5 className={`${styles.input} !w-[95%] mb-2 800px:mb-2 text-[600] text-[blue]`}>1TST = 1USD</h5>
                 <label className="block pb-2">Amount TST</label>
                    <div className="mt-1">
                    <select
                    name="camount"
                    onChange={(e) => setCamount(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    </select>
                  </div>
                </div> 
                <br />

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Duration (Days)</label>
                  <div className="mt-1">
                    <select
                    name="duration"
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="7">7</option>
                    <option value="14">14</option>
                    <option value="21">21</option>
                    <option value="28">28</option>
                    </select>
                  </div>
                </div> 

                      <button
                        className={`${styles.button} !h-[42px] text-white`}
                        type="submit"
                      >
                        Generate
                      </button>
                    </div>
        </form> 

       
        </div>
            </>
            }


            {showPayCPButton && 
            <>
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p className="text-[green] font-[600]">Invoice generated successfully</p> 
        <h5 className="text-[16px] font-[600]">Order Id: {invoiceOrderId} 
        <MdOutlineFolderCopy 
        onClick={writeTextToClipboard}
        size={20}
        className="cursor-pointer mb-2"
        />
        </h5> 
        <h5 className="text-[red] text-[14px] font-[500]">Please save this order_id, it will be requested if there's an error with this order.</h5><br />

        <Link to={`${invoiceUrl}`} target="_blank">
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}>
         Pay 
        </h5>
        </Link>
        </div>
        <br />
        <h5>Wait atleast a minute after the payment has marked 'Payment is paid' before clicking the Completed button.</h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={confirmCPaymentInfo}
        >
         Completed 
        </h5>
        </>
            }
            
        

      </div>
    </div>
    )}


      {openBank && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div className={`w-[95%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
          <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenBank(false)}
                className="cursor-pointer"
              />
            </div>
          <div className="w-full border-b justify-center items-center text-center">
            <h2 className="text-[25px] font-[500] mb-3">(U.S./UK) residence only</h2>
            <Link to="https://wa.me/447418375846" target="_blank"><h5 className="text-[green] cursor-pointer">Get Details</h5></Link>
          </div>  
          </div>
          
        </div>
      )}
 
    </div>
    )}
    </>
    
  );
};

export default UserInvest;
