import React from 'react';
import { Link } from "react-router-dom";

const STaskCard2 = ({data, postAt, filterFunction}) => {
    return (
        
        <div className={`max-w-5xl md:block shadow-lg mx-auto rounded md:flex md:justify-between md:tems-center bg-white p-6 m-6 mb-16 sm:mb-0 ${data?.taskStatus === "Open" && 'border-l-4 border-[#c89b2a]'}`}>
            <div className='md:flex md:items-center'>
                <div>   
                    <span className='text-cyan font-bold text-sm m-2'>{data?.shop.name}</span>
                    {data?.taskStatus === "Open" && <span className='bg-[#c89b2a] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl'>Open !</span>}
                    {data?.taskStatus === "Closed" && <span className='bg-[black] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl mx-2'>Closed</span>}
                    <h1 className='text-black font-medium md:text-xl m-2'>{data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}</h1>
                    <div>
                        <span className='text-dark_gray_cyan text-sm m-2'>${data?.taskPayment} {data?.paymentMode === "Full" ? "Full Payment" : "/" + data?.paymentMode}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{data?.targetedCountry}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{data?.applicants.length} Applications</span>
                    </div>
                </div>
            </div>
            <div className='w-full border-t my-2 border-[gray] md:hidden'></div>
            <div className='my-auto'>
                <button onClick={filterFunction} className ='bg-[#d9dddc] m-2 text-sm p-2 text-cyan focus:outline-none cursor-pointer hover:bg-[black] hover:text-white font-semibold rounded'>{data?.category}</button> 
                {data?.taskStatus === "Closed" ? null : <Link to={`/s-task/${data._id}`}><button className ='bg-[#F6F6F5] text-sm focus:outline-none m-2 p-2 cursor-pointer text-cyan hover:bg-[#c89b2a] hover:text-white font-semibold rounded'>See More Details</button></Link>}
            </div>
        </div> 
       )
}

export default STaskCard2;


/*

import React from 'react';

const STaskCard2 = ({logo, company, isNew, featured, position, postAt, contract, location, role, level, languages, tools, filterFunction}) => {
    return (
        <Link to={`/s-task/${data._id}`}>
        <div className={`max-w-5xl md:block shadow-lg mx-auto rounded md:flex md:justify-between md:tems-center bg-white p-6 m-6 mb-16 sm:mb-0 ${featured && 'border-l-4 border-[#c89b2a]'}`}>
            <div className='md:flex md:items-center'>
                <div className='m-2'>
                    <img className='-mt-16 h-20 w-20 sm:mt-0 sm:h-24 sm:w-24' alt='company logo' src={logo}></img>
                </div>
                <div>   
                    <span className='text-cyan font-bold text-sm m-2'>{company}</span>
                    {isNew && <span className='bg-[#c89b2a] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl'>New !</span>}
                    {featured && <span className='bg-[black] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl mx-2'>featured</span>}
                    <h1 className='text-black font-medium md:text-xl m-2'>{position}</h1>
                    <div>
                        <span className='text-dark_gray_cyan text-sm m-2'>{postAt} days ago</span>
                        <span className='m-1 h-1 w-1 inline-block rounded bg-dark_gray_cyan'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{contract}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded bg-dark_gray_cyan'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{location}</span>
                    </div>
                </div>
            </div>
            <div className='w-full border-t my-2 border-[gray] md:hidden'></div>
            <div className='my-auto'>
                <button onClick={filterFunction} className ='bg-[#d9dddc] m-2 text-sm p-2 text-cyan focus:outline-none cursor-pointer hover:bg-cyan hover:text-white font-semibold rounded'>{role}</button> 
                <button onClick={filterFunction} className ='bg-[#F6F6F5] m-2 text-sm p-2 text-cyan focus:outline-none cursor-pointer hover:bg-cyan hover:text-white font-semibold rounded'>{level}</button> 
                {languages.map((language, index) => <button onClick={filterFunction} key={index} className ='bg-[#F6F6F5] text-sm focus:outline-none m-2 p-2 cursor-pointer text-cyan hover:bg-cyan hover:text-white font-semibold rounded'>{language}</button> )}
                {tools.map((tool, index) => <button onClick={filterFunction} key={index} className='bg-[#d9dddc] text-sm m-2 p-2 text-cyan focus:outline-none cursor-pointer font-semibold hover:bg-cyan hover:text-white rounded'>{tool}</button> )}
            </div>
        </div> 
        </Link>
    )
}

export default STaskCard2;
*/