import React, { useEffect, useState } from 'react'
import UserInvestDashboardHeader from '../components/UserInvestDashboardHeader';
import UserInvestDashboardSideBar from "../components/UserInvestDashboardSideBar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "../styles/styles";
import { server } from "../server";
import { toast } from "react-toastify";
import axios from "axios";

const UserInvestDetailPage = () => {
  return (
    <div>
    <UserInvestDashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <UserInvestDashboardSideBar active={2} />
      </div>
       <InvestmentDetails />
    </div>
  </div>
  )
}

const InvestmentDetails = () => {
    const { user } = useSelector((state) => state.user);
    const investorID = user?._id;
    const { id } = useParams();
    const [iData, setIdata] = useState([]);
    const email = user?.email;

    useEffect(() => {
        axios
          .get(`${server}/investment/get-all-investments/${investorID}`, {
            withCredentials: true,
          })
          .then((res) => {
            setIdata(res.data.investments);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }, []);

      const investData = iData && iData.find((itm) => itm._id === id);

      console.log(investData);

      const investmentWithdrawalHandler = async (e) => {
        e.preventDefault();
      
        await axios
      
          .put(
            `${server}/investment/update-investment/${id}`,
            {
                isWithdrawed: true,
                email,
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("Fund Withdrawal completed!");
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
      }

    return (
       
        <div className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}>
        <div className={`${styles.section} w-[90%] 800px:w-[90%]`}>
        <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          This is a single Investment Detail. To See all investments please navigate to the investment page.
        </h2>
        <br />
        <hr />
        <br />

        <h3 className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}>
          { investData && investData?.investorName } <span className='text-[15px] text-[green]'> - Investor</span>
        </h3>
        
        <div className="flex w-full">
            <div className="relative">
              <img
                src={`${user?.avatar?.url}`}
                className="w-[80px] h-[80px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
                alt=""
              />
            </div>
          </div>
          

          <br />
        <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        Investment Details:
        </h1>
        <br />

        <div className='bg-[#000000ba] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Amount:</label>
                  <h5 className={`${styles.input} !bg-[#fff] !w-[95%] mb-4 800px:mb-0`}>{ investData && investData?.amountOfTST } TST</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Duration:</label>
                  <h5 className={`${styles.input} !bg-[#fff] !w-[95%] mb-4 800px:mb-0`}>{ investData && investData?.duration } Days</h5>
                  </div>
               </div>

               <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Profit:</label>
                  <h5 className={`${styles.input} !bg-[#fff] !w-[95%] mb-4 800px:mb-0`}>{ investData && investData?.profit } USD</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Status:</label>
                  <h5 className={`${styles.input} !bg-[#fff] !w-[95%] mb-4 800px:mb-0`}>{ investData && investData?.investmentStatus }</h5>
                  </div>
               </div>

               <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Start Date:</label>
                  <h5 className={`${styles.input} !bg-[#fff] !w-[95%] mb-4 800px:mb-0`}>{ investData && investData?.createdAt.slice(0, 10) }</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">End Date:</label>
                  <h5 className={`${styles.input} !bg-[#fff] !w-[95%] mb-4 800px:mb-0`}>{ investData?.updatedAt === undefined ? "Pending" : investData?.updatedAt.slice(0, 10) }</h5>
                  </div>
               </div>
               </div>
            </div>
            <br />

            <div>
            {investData?.isWithdrawed === false && (
            <>
             {investData?.investmentStatus === "ended" ? (
            <div className={`${styles.buttonRed} !bg-[green] !ml-2 mb-3`}
            onClick={investmentWithdrawalHandler}
            >
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Cash Out
               </span>
            </div>
        ) : (
            <div className={`${styles.buttonRed} !bg-[#00008B] !ml-2 mb-3`}>
            <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
            Pending
            </span>
            </div>
        )}
           </>
        ) }
            


            {investData?.isWithdrawed === true ? (
            <div className={`${styles.buttonRed} !bg-[red] !ml-2 mb-3`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               closed
               </span>
            </div>
        ) : (
            <div className={`${styles.buttonRed} !bg-[green] !ml-2 mb-3`}>
            <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
            Open
            </span>
            </div>
        )}

        
            </div>

        
      </div>
      <br />
      <br />


        </div>

        </div>
       
    )

}

export default UserInvestDetailPage;