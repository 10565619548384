import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const CVendorApplication = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <br /><br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Become A Code Vendor 
        </h1> 
        <h2 className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
            Kindly read through the bellow requirements to see if you qualify and apply by sending all required details to totlesoft.hr@gmail.com
        </h2>
        <br />
        <hr />
        <br />
        
       
        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Social Media Qualification <span className="text-[15px] text-[green] ml-3 font-[400]">required</span>
              </h3><br />
            </div>
            <div>
            <h5 className="pt-4 pl-2">
            1. WhatsApp Group (Min 1000 members) send profile link<br />
            2. Facebook followers (Min 5k followers) send profile link<br />
            3. Instagram followers (Min 5k followers) send profile link<br />
            4. Youtube subscribers (Min 5k subscribers) send profile link<br />
            5. Telegram (Min 1000 members) send profile link
            </h5>
            </div>
        </div>
        </div>

        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                KYC Qualification <span className="text-[15px] text-[green] ml-3 font-[400]">required</span>
              </h3><br />
            </div>
            <div>
            <h5 className="pt-4 pl-2">
            1. ID Card: (Scan back and front, save as PDF and attach file to message)<br />
            2. Phone Number<br />
            3. WhatsApp Number<br />
            4. Email<br />
            5. Country and years of residence.<br />
            6. Passport Photograph
            </h5>
            </div>
        </div>
        </div>


        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                About Your Experience <span className="text-[15px] text-[green] ml-3 font-[400]">required</span>
              </h3><br />
            </div>
            <div>
           <h5 className="pt-4 pl-2">
            1. Write briefly what you understand about the term "Code Vendor" as it relates to TotleSoft and how you to plan to contribute to TotleSoft's workforce in that regards.
            </h5><br />
            </div>
        </div>
        </div>
       

        <br />
       <Link to="mailto:totlesoft.hr@gmail.com" className="inline-block" target="_blank">
            <button 
            className={`${styles.button} text-[#fff] justify-center items-center text-center`}
            >
               Apply
              </button>
            </Link>
            <br />
            <br />
            <br />
      </div>
    </div>
  );
};

const CVendorApplicationPage = () => {
    return (
      <div>
        <Header />
        <CVendorApplication />
        <Footer />
      </div>
    );
  };

export default CVendorApplicationPage;

