import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import CountDown from "../../Events/CountDown";

const EventAdCard = ({ data }) => {
   
  
  return (
    <>
      <div className="w-full h-[600px] bg-[#F6F6F5] rounded-lg shadow-sm py-2 px-3 relative cursor-pointer">
       <Link to={`${data.eventLink}`}>
          <img
            src={`${data.eventCoverAddress}`}
            alt=""
            className="w-full h-[170px] object-contain"
          />
        </Link>
        <h5 className={`${styles.shop_name} !text-[#475ad2] !text-[13px]`}>Event by: {data.eventBy}</h5>
        
        <h4 className="pb-3 font-[500] text-[#000]">
            {data.name.length > 80 ? data.name.slice(0, 80) + "..." : data.name}
        </h4> 
        <h4 className="pb-1 text-[#000] text-[12px]">
            Date: {data.eventDate}
        </h4> 
        <h4 className="pb-1 text-[#000] text-[12px]">
            Time: {data.eventTime}
        </h4> 
        <h4 className="pb-1 text-[#000] text-[12px]">
            Venue: {data.eventVenue}
        </h4>
        <h4 className="pb-1 text-[#000] text-[12px]">
            Powered By: {data.poweredBy}
        </h4>
        <h4 className="pb-1 text-[#000] text-[12px]">
            Supported By: {data.supportedBy}
        </h4>
        <h4 className="text-[#000] font-[500] text-[12px]">
            Speaker(s):
        </h4>
        <h5 className={`!text-[#475ad2] !text-[13px]`}>{data.speaker1 && data.speaker1 === "" ? null : data.speaker1}</h5>
        <h5 className={`!text-[#475ad2] !text-[13px]`}>{data.speaker2 && data.speaker2 === "" ? null : data.speaker2}</h5>
        <h5 className={`!text-[#475ad2] !text-[13px]`}>{data.speaker3 && data.speaker3 === "" ? null : data.speaker3}</h5>
        <h5 className={`!text-[#475ad2] !text-[13px]`}>{data.speaker4 && data.speaker4 === "" ? null : data.speaker4}</h5>
        <h5 className={`!text-[#475ad2] !text-[13px]`}>{data.speaker5 && data.speaker5 === "" ? null : data.speaker5}</h5>

        <CountDown data={data} />
                
        <Link to={`${data.eventLink}`}>
        <div className={`${styles.button} !w-[70px] !h-[30px] mt-3`}>
                 <span className="text-[#fff] font-[Poppins] text-[14px]">
                    Atend
                 </span>
            </div>
        </Link>                

       </div>
    </>
  );
};

export default EventAdCard;
