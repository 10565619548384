import React from 'react'
import DashboardHeader from '../../components/Article/Layout/ArticleDashboardHeader'
import DashboardSideBar from '../../components/Article/Layout/ArticleDashboardSideBar'
import CreateArticle from "../../components/Article/CreateArticle";
import { useSelector } from "react-redux";

const CreateArticlePage = () => {
    const { user } = useSelector((state) => state.user);

  return (
    <div>
        {user && user?.staffRole === "Content Writer" ? (
        <>
        <DashboardHeader />
        <div className="flex items-center justify-between w-full">
            <div className="w-[80px] 800px:w-[330px]">
              <DashboardSideBar active={15} />
            </div>
           <div className="w-full justify-center flex">
                <CreateArticle />
            </div>
           </div>
           </>
           ) : (
                <div className="w-full justify-center mt-9 items-center text-center">
                <h1 className='text-[40px] font-[500]'>Not Authorized to use this resource!</h1>
                <p>Only certified content writers have access to this route.</p>
                </div>
            )}
    </div>
  )
}

export default CreateArticlePage;