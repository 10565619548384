import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import ArticleCard from "../../pages/ResourceCenter/ArticleCard";
import { server } from "../../server";
import axios from "axios";


const MostRead = () => {
  
  const [data, setData] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  
  useEffect(() => {
    axios.get(`${server}/article/get-all-articles`, {withCredentials: true}).then((res) => {
        setAllArticles(res.data.articles);
    })
  }, []);

  
  useEffect(() => {
    const allArticlesData = allArticles ? [...allArticles] : [];
    const sortedData = allArticlesData?.sort((a,b) => b.views - a.views); 
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allArticles]);
  

  return (
    <div>
      <br />
      <div className={`${styles.section} bg-[#fff]`}>
        <div className="grid grid-cols-1 gap-[15px] md:grid-cols-2 md:gap-[18px] lg:grid-cols-4 lg:gap-[20px] xl:grid-cols-5 xl:gap-[22px] mb-12">
          {data && data.map((i, index) => <ArticleCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]"></h1>
        ) : null}
      </div>
      </div>
    );
};

export default MostRead;
