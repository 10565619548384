import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const videotaskReducer = createReducer(initialState, {
  videotaskCreateRequest: (state) => {
    state.isLoading = true;
  },
  videotaskCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.videotask = action.payload;
    state.success = true;
  },
  taskCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //load videotask
  LoadVideoTaskRequest: (state) => {
    state.isLoading = true;
  },
  LoadVideoTaskSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.isLoading = false;
    state.videotask = action.payload;
  },
  LoadVideoTaskFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // get all videotasks
  getAllVideoTasksRequest: (state) => {
    state.isLoading = true;
  },
  getAllVideoTasksSuccess: (state, action) => {
    state.isLoading = false;
    state.allVideoTasks = action.payload;
  },
  getAllVideoTasksFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },

  // get all videotasks of shop
  getAllVideoTasksShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllVideoTasksShopSuccess: (state, action) => {
    state.isLoading = false;
    state.videotasks = action.payload;
  },
  getAllVideoTasksShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete videotask of a shop
  deleteVideoTaskRequest: (state) => {
    state.isLoading = true;
  },
  deleteVideoTaskSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteVideoaskFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

});

