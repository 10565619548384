import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import PackageAccess from "../components/Talent/PackageAccess";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../server";


const PackageAccessPage = () => {
  const { id } = useParams();
  const [dataToUse, setDataToUse] = useState(null);
  

  useEffect(() => {
    axios
      .get(`${server}/talent/get-all-talents`, {
        withCredentials: true,
      })
      .then((res) => {
        setDataToUse(res.data.talents);
    })
      .catch((error) => {
        console.log(error.response.data.message);
    });
  }, []);
  

 const data = dataToUse && dataToUse.find((i) => i._id === id);
      
  return (
    <div>
      <Header />
      <br />
      <>
        {data ? (
        <PackageAccess data={data} />
      ) : 
        <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500]">Ops! Slow Network!</h1>
          <p>Please try again later.</p>
        </div>
     }
        </>
     <br />
      <Footer />
    </div>
  );
};

export default PackageAccessPage;
