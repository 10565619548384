import React, {useEffect} from 'react';
import styles from "../../../styles/styles";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import people1 from "../../../Assests/images/peoplef.png";
import 'animate.css';
import './horizon.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


function HorizontalScroll() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <div className='w-full'>
    <div className={`relative min-h-[60vh] 800px:min-h-[70vh] w-full bg-no-repeat ${styles.noramlFlex}`}
    style={{
      backgroundImage:
        "url(https://res.cloudinary.com/totlesoft/image/upload/v1706023934/herobag_pcto7x.png)",
    }}
    >
        <div className={`${styles.section} w-[90%] 800px:w-[90%] gap-5`}>
        <section className="hcontact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6} className="cont-left">
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={people1} alt="Freelancers"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6} className="cont-right">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeInRight" : ""}>
                <h1 className='text-[25px] font-[600] px-3 pb-5 pt-9 text-[#000]'>{t('Love_by_freelancers_h')}</h1>
                <p className='text-[16px] text-[#000] px-3'>
                  {t('Love_by_freelancers_b')} </p>

            <Link to="/faq" className="inline-block">
            <div className={`${styles.button} mt-5 ml-2`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('learn_more')}
                 </span>
            </div>
            </Link>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
        </div>
    </div>
    
    </div>
  );
}

export default HorizontalScroll;