import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const taskReducer = createReducer(initialState, {
  taskCreateRequest: (state) => {
    state.isLoading = true;
  },
  taskCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.task = action.payload;
    state.success = true;
  },
  taskCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //load task
  LoadTaskRequest: (state) => {
    state.isLoading = true;
  },
  LoadTaskSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.isLoading = false;
    state.task = action.payload;
  },
  LoadTaskFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // get all tasks
  getAllTasksRequest: (state) => {
    state.isLoading = true;
  },
  getAllTasksSuccess: (state, action) => {
    state.isLoading = false;
    state.allTasks = action.payload;
  },
  getAllTasksFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },

  // get all tasks of shop
  getAllTasksShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllTasksShopSuccess: (state, action) => {
    state.isLoading = false;
    state.tasks = action.payload;
  },
  getAllTasksShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete task of a shop
  deleteTaskRequest: (state) => {
    state.isLoading = true;
  },
  deleteTaskSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteTaskFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all tasks --- for Open use
  getAllTasksOpenRequest: (state) => {
    state.tasksLoading = true;
  },
  getAllTasksOpenSuccess: (state,action) => {
    state.tasksLoading = false;
    state.tasks = action.payload;
  },
  getAllTasksOpenFailed: (state,action) => {
    state.tasksLoading = false;
    state.error = action.payload;
  },

});

