import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import styles from "../styles/styles";
import "./animstyles.css";
import Imgg2 from "../AnimationHero/images/bg-bottom-hero.png";
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


function AnimHeroSection3() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <div className='hero-main'>
        <section className="hero">
        <div className="row container">

         <TrackVisibility>
          {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__none" : ""}> 
        <div className="w-[90%]">
            <h2 className='text-[#fff] text-[25px] font-[600]'>{t('Reliable_Product_Testing_h')}</h2>
            <p className='!text-[16px] text-[#fff]'>
            {t('Reliable_Product_Testing_b')} 
            </p>

            <Link to="/product-testing" className="inline-block">
            <div className={`${styles.button} !bg-[#c89b2a] mt-5 ml-2`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('learn_more')}
                 </span>
            </div>
            </Link>
            </div>
            </div>
          }
          </TrackVisibility>
          
          </div>
        <img src={Imgg2} alt="" className="curveImg" />
      </section>
    </div>
  )
}

export default AnimHeroSection3