import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function WnMAppDev() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  
  return (
    <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
      <br />
      <br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-15px`}
        >
          {t('sd_title')}
        </h1>

        <div className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        <p>{t('sd_p1')}</p>
        <br />
        <p className="font-[600]">{t('sd_p2t')}</p>
        <p>{t('sd_p2')}</p>
        <br />

        <p>{t('sd_p3')}</p>
          
          <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px]">
                 {t('sd_Connect1')}
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <p className="font-[600]">{t('sd_p4t')}</p>
        <p>
        {t('sd_p4')}
        </p>
          <br />
          <br />

          <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://totlesoft.vercel.app/static/media/mobilevid0.ccbc6063e8c6e998d832.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('sd_lc')}</p>


        <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px]">
                 {t('sd_Connect2')}
                 </span>
            </div>
        </Link>
        <br />
        <br />


        </div>
        <br />
        <br />
       
      </div>
  )
}

export default WnMAppDev