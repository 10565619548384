import React, { useState, useEffect } from "react";
import ArticleCard from "../../pages/ResourceCenter/ArticleCard";
import styles from "../../styles/styles";
import { server } from "../../server";
import axios from "axios";
import Spinner2 from "../../components/Spinner/Spinner2";


const Articles = () => {
   const [data, setData] = useState([]);
   const [dataLoading, setDataLoading] = useState(false);
     

    useEffect(() => {
        setDataLoading(true);
    axios.get(`${server}/article/get-all-articles`, {withCredentials: true}).then((res) => {
        setData(res.data.articles);
        setDataLoading(false);
    })
  }, []); 

  
  return (
   <div className="!bg-[#fff] w-full">
      <br />
      {dataLoading ? <Spinner2 /> : (
        <div className={`${styles.section} bg-[#fff] p-4`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <ArticleCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]"></h1>
        ) : null}
      </div>
      )}
      </div>
    );
};

export default Articles;

/*

useEffect(() => {
        if (categoryData === null) {
          const d = allArticles;
          setData(d);
        } else {
          const d =
          allArticles && allArticles.filter((i) => i.category === categoryData);
          setData(d);
        }
        //    window.scrollTo(0,0);
      }, [allArticles]);

      console.log("Chris data isL: ");
      console.log(data);

      */