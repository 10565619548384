import React from 'react'
import NewCodes from "../components/CodeVendor/NewCodes";
import CodeVendorHeader from '../components/Layout/CodeVendorHeader';
import CodeVendorSideBar from '../components/CodeVendor/Layout/CodeVendorSideBar';

const CodeVendorDashboardNewCodes = () => {
  return (
    <div>
    <CodeVendorHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <CodeVendorSideBar active={2} />
        </div>
        <NewCodes />
      </div>
    </div>
  </div>
  )
}

export default CodeVendorDashboardNewCodes