import axios from "axios";
import { server } from "../../server";

// get all newcodes created by a CodeVendor
export const getAllNewCodesOfUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllNewCodesUserRequest",
    });

    const { data } = await axios.get(
      `${server}/newcode/get-all-newcodes/${userId}`
    );

    dispatch({
      type: "getAllNewCodesUserSuccess",
      payload: data.newcodes,
    });
  } catch (error) {
    dispatch({
      type: "getAllNewCodesUserFailed",
      payload: error.response.data.message,
    });
  }
};

// get all newcodes for Admin
export const getAllNewCodesOfAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "adminAllNewCodesRequest",
    });

    const { data } = await axios.get(`${server}/newcode/get-all-newcodes-admin`, {
      withCredentials: true,
    });

    dispatch({
      type: "adminAllNewCodesSuccess",
      payload: data.newcodes,
    });
  } catch (error) {
    dispatch({
      type: "adminAllNewCodesFailed",
      payload: error.response.data.message,
    });
  }
};


// get all newcodes for all users  
export const getAllNewUCodesOfUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllNewUCodesUserRequest",
    });

    const { data } = await axios.get(
      `${server}/newcode/get-all-newcodes`
    );

    dispatch({
      type: "getAllNewUCodesUserSuccess",
      payload: data.newcodes,
    });
  } catch (error) {
    dispatch({
      type: "getAllNewUCodesUserFailed",
      payload: error.response.data.message,
    });
  }
};
