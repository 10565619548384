import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo1 from '../../Assests/images/logo.png'

const CodeVendorHeader = () => {
    const {user} = useSelector((state) => state.user);

  return (
         <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img
            src={logo1}
            width='130px'
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <h5 className='mr-4 text-[16] text-[blue]'>
            {user?.username}
          </h5>
            <img
              src={`${user?.avatar?.url}`}
              alt="" 
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
        </div>
      </div>
    </div>
  )
}

export default CodeVendorHeader