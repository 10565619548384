import React from "react"
import Contact from "../../components/Contact/Contact";
import Header from "../../components/Layout/Header";

const ContactPage = () => {
  return (
    <div>
      <Header />
      <Contact />
      </div>
  );
};


export default ContactPage 

