import React from "react";
import {  AiOutlineFolderAdd, } from "react-icons/ai";
import { 
  MdPlaylistAddCircle, 
  MdPlaylistAdd,
  MdForwardToInbox,
  MdAddHomeWork
} from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo1 from '../../../Assests/images/logo.png'

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
 

  return (
    <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img
            src={logo1}
            width='130px'
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4 justify-between gap-[20px]">

        <Link
          to="/dashboard-create-job"
          className="800px:block hidden"
        >
          <MdAddHomeWork
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
            Post Job
          </h5>
        </Link>

        <Link
          to="/dashboard-create-uns-task"
          className="800px:block hidden"
        >
          <MdPlaylistAdd
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
          Create Campaign
          </h5>
        </Link>
        

        <Link
          to="/dashboard-create-s-task"
          className="800px:block hidden"
        >
          <MdPlaylistAddCircle
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
            Create Project
          </h5>
        </Link>

        {seller && seller?.role === "ProSeller" && (
          <Link
          to="/dashboard-create-product"
          className="800px:block hidden"
        >
          <AiOutlineFolderAdd
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
            List Product
          </h5>
        </Link>
        )}
        

        {seller && seller?.role === "ProSeller" && (
          <Link
          to="/dashboard-messages"
          className="800px:block hidden"
        >
          <MdForwardToInbox
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
            Inbox
          </h5>
        </Link>
        )}
        

                  
          <Link to={`/shop/${seller._id}`}>
            <img
              src={`${seller.avatar?.url}`}
              alt=""
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
