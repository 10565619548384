import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import { loadUser } from "../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import Spinner from "../../src/components/Spinner/Spinner";
import tPay from '../Assests/logos/totlesoftPay.png';
import { Link } from "react-router-dom";


const UserWithdrawMoney = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [wType, setWtype] = useState("");
  const [wDetails, setWdetails] = useState("");

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(50);
  const [isLoading, setIsLoading] = useState(false);
  const availableBalance = user?.availableBalance.toFixed(2);
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankSwiftCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });


  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const mcDataToUse = mycontrolData && mycontrolData.find((iiti) => iiti.controlPin === "114455chris");
  console.log(mcDataToUse);

  const vat = withdrawAmount * 3 / 100;

    const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawMethod = {
      bankName: bankInfo.bankName,
      bankCountry: bankInfo.bankCountry,
      bankSwiftCode: bankInfo.bankSwiftCode,
      bankAccountNumber: bankInfo.bankAccountNumber,
      bankHolderName: bankInfo.bankHolderName,
      bankAddress: bankInfo.bankAddress,
    };

    setPaymentMethod(false);

    await axios
      .put(
        `${server}/user/update-payment-methods`,
        {
          withdrawMethod,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Withdraw method added successfully!");
        dispatch(loadUser());
        setBankInfo({
          bankName: "",
          bankCountry: "",
          bankSwiftCode: null,
          bankAccountNumber: null,
          bankHolderName: "",
          bankAddress: "",
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/user/delete-withdraw-method`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Withdraw method deleted successfully!");
        dispatch(loadUser());
      });
  };

  const error = () => {
    toast.error("You don't have enough balance to withdraw!");
  };

  
  const amountpvat = Number(withdrawAmount) + Number(vat);
  const withUserUserId = user?._id;
      
  const withdrawHandler = async () => {
    if (amountpvat > availableBalance){
      toast.error("Balance Insuficient!");
    } else {
      setIsLoading(true);
      await axios
        .post(
          `${server}/withdrawuser/create-withdraw-request-user`,
          { amount: amountpvat,
            wType: wType, 
            wDetails: wDetails,
            withUserUserId },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          toast.success("Withdrawal request successful!");
          window.location.reload();
        });
    }
  };

  console.log("wType is:");
  console.log(wType);

  console.log("wDetails is:");
  console.log(wDetails);


  

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      {!mcDataToUse || mcDataToUse.isPaymentDay === "false" ? <div>
        <h1 className="text-[60px] justify-center items-center pt-8 px-8"> 404!</h1>
        <h1 className=" text-[#077f9c] text-[30px] justify-center items-center py-8 px-8 text-center"> Not Yet Withdrawal Day</h1>
        </div> : (
        <div className="w-full h-[90vh] p-8">
        <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
        <div className="800px:w-[30%] 800px:h-[300px] bg-[#F6F6F5] w-[90%] h-[70%] rounded flex text-center items-center justify-center flex-col">
          <h5 className="text-[20px] text-[blue]">
            Earning Balance: 
          </h5>
          <h2 className="text-[35px] font-[600] pb-1">
          ${availableBalance}
          </h2>
          <h6 className="text-[15px] pb-2">
            Min withdraw amount = $2
          </h6>
          <div
            className={`${styles.button} text-white !h-[42px] !rounded`}
            onClick={() => (availableBalance < 2 ? error() : setOpen(true))}
          >
            Withdraw
          </div>
          <Link to="/profile">
          <h5 className="text-[18px] text-[blue] pt-1">
           Cancel 
          </h5>
          </Link>
          <div>
          <img
              src={tPay}
              width='150px'
              alt="totlesoftPay"
            />
          </div>
          </div>
          </div>
        {open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div
              className={`w-[95%] 800px:w-[30%] bg-white shadow rounded ${
                paymentMethod ? "h-[80vh] overflow-y-scroll" : "h-[unset]"
              } min-h-[40vh] p-3`}
            >
              <div className="w-full flex justify-end">
                <RxCross1
                  size={25}
                  onClick={() => setOpen(false) || setPaymentMethod(false)}
                  className="cursor-pointer"
                />
              </div>
              {paymentMethod ? (
                <div>
                  <h3 className="text-[22px] font-Poppins text-center font-[600]">
                    Add new Withdraw Method:
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>
                        Bank Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankName}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                        id=""
                        placeholder="Enter your Bank name!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    <div className="pt-2">
                      <label>
                        Bank Country <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        value={bankInfo.bankCountry}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankCountry: e.target.value,
                          })
                        }
                        id=""
                        required
                        placeholder="Enter your bank Country!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    <div className="pt-2">
                      <label>
                        Bank Swift Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={bankInfo.bankSwiftCode}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankSwiftCode: e.target.value,
                          })
                        }
                        placeholder="Enter your Bank Swift Code!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
  
                    <div className="pt-2">
                      <label>
                        Bank Account Number{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name=""
                        id=""
                        value={bankInfo.bankAccountNumber}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAccountNumber: e.target.value,
                          })
                        }
                        required
                        placeholder="Enter your bank account number!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    <div className="pt-2">
                      <label>
                        Bank Holder Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankHolderName}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankHolderName: e.target.value,
                          })
                        }
                        id=""
                        placeholder="Enter your bank Holder name!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
  
                    <div className="pt-2">
                      <label>
                        Bank Address <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        id=""
                        value={bankInfo.bankAddress}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAddress: e.target.value,
                          })
                        }
                        placeholder="Enter your bank address!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
  
                    <button
                      type="submit"
                      className={`${styles.button} mb-3 text-white`}
                    >
                      Add
                    </button>
                  </form>
                </div>
              ) : (
                <>
                  <h3 className="text-[22px] font-Poppins">
                    Default Bank Details:
                  </h3>
  
                  {user && user?.withdrawMethod ? (
                    <div>
                      <div className="800px:flex w-full justify-between items-center mb-2">
                        <div className="800px:w-[50%]">
                          <h5>
                            Account Number:{" "}
                            {"*".repeat(
                              user?.withdrawMethod.bankAccountNumber.length - 3
                            ) +
                              user?.withdrawMethod.bankAccountNumber.slice(-3)}
                          </h5>
                          <h5>Bank Name: {user?.withdrawMethod.bankName}</h5>
                          <h5>Holder's Name: {user?.withdrawMethod.bankHolderName}</h5>
                          <h5>Bank Country: {user?.withdrawMethod.bankCountry}</h5>
                        </div>
                      </div>
                      <div className="800px:w-[50%]">
                          <AiOutlineDelete
                            size={25}
                            className="cursor-pointer"
                            onClick={() => deleteHandler()}
                          /> 
                        </div>
                        <h5 className="text-[15px]">Change Account</h5>
                      <br />
                      <h5 className="text-[18px] text-[blue]">Available Balance:</h5>
                      <h2 className="text-[35px] font-[600] pb-1">${availableBalance}</h2>
                      <br />
                      <div className="800px:flex w-full items-center">
                        <input
                          type="number"
                          placeholder="Amount..."
                          value={withdrawAmount}
                          onChange={(e) => setWithdrawAmount(e.target.value)}
                          className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                        />
                      </div>
                      <br />
                      <h5 className="text-[18px] mb-2 text-[blue]">Amount + 3% Vat: ${amountpvat}</h5>

    <div className="w-full 800px:flex block pb-3">
    <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2 font-[500]">Payment Type:</label>
    <div className="mt-1">
                    <select
                    name="wType"
                    onChange={(e) => setWtype(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="Choose">Choose</option>
                    <option value="Paypal">Paypal</option>
                    <option value="Crypto">Crypto</option>
                    <option value="Payoneer">Payoneer</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="Gift Card">Gift Card</option>
                    </select>
                  </div>
              </div>
  
              </div>



    <div className="w-full 800px:flex block pb-3">
    <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2 font-[500]">Payment Details:</label>
    <div className="mt-1">
                    <select
                    name="wDetails"
                    onChange={(e) => setWdetails(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="Choose">Choose</option>
                    <option value={user?.wmPaypal === undefined ? "Not Set" : user?.wmPaypal}>Paypal</option>
                    <option value={user?.wmCrypto === undefined ? "Not Set" : user?.wmCrypto}>Crypto</option>
                    <option value={user?.wmPayoneer === undefined ? "Not Set" : user?.wmPayoneer}>Payoneer</option>
                    <option value={user?.wmBankTransfer === undefined ? "Not Set" : user?.wmBankTransfer}>Bank Transfer</option>
                    <option value={user?.wmGiftCard === undefined ? "Not Set" : user?.wmGiftCard}>Gift Card</option>
                    </select>
                  </div>
              </div>
  
              </div>

                      <div
                          className={`${styles.button} !h-[42px] text-white mb-2`}
                          onClick={withdrawHandler}
                        >
                          Withdraw
                        </div>
                        <div>
                        <img
                        src={tPay}
                        width='150px'
                        alt="totlesoftPay"
                        />
                        </div>
                    </div>
                  ) : (
                    <div>
                      <p className="text-[14px] text-[red] pt-2">
                        Default Bank Details Not Set (This must be set in other to procceed!)
                      </p>
                      <div className="w-full flex items-center">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}
                          onClick={() => setPaymentMethod(true)}
                        >
                          Add new
                        </div>
                      </div>
                     
                      <hr />

                      <p className="text-[14px] text-[blue] pt-2">
                        Kindly update all withdrawal methods details via settings before proceeding to make withdrawal.
                      </p>

                      <div className="w-full block">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}>
                          PayPal
                        </div>
                        <h5 className="text-[15px]">
                        {user?.wmPaypal === undefined ? "Not Set" : user?.wmPaypal}
                      </h5>
                      </div>

                      <div className="w-full block">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}>
                          Crypto
                        </div>
                        <h5 className="text-[15px]">
                        {user?.wmCrypto === undefined ? "Not Set" : user?.wmCrypto}
                      </h5>
                      </div>

                      <div className="w-full block">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}>
                          Payoneer
                        </div>
                        <h5 className="text-[15px]">
                        {user?.wmPayoneer === undefined ? "Not Set" : user?.wmPayoneer}
                      </h5>
                      </div>

                      <div className="w-full block">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}>
                          Transfer
                        </div>
                        <h5 className="text-[15px]">
                        {user?.wmBankTransfer === undefined ? "Not Set" : user?.wmBankTransfer}
                      </h5>
                      </div>

                      <div className="w-full block">
                        <div
                          className={`${styles.button} text-[#fff] text-[18px] mt-4`}>
                          GiftCard
                        </div>
                        <h5 className="text-[15px]">
                        {user?.wmGiftCard === undefined ? "Not Set" : user?.wmGiftCard}
                      </h5>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      )}
      </>
      
    )}
    </>
    
  );
};

export default UserWithdrawMoney;
