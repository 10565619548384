import React from "react";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const UserInvestDashboardSideBar = ({ active }) => {
  const { user } = useSelector((state) => state.user);
  
  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/profile" className="w-full flex items-center">
          <RxDashboard
            size={30}
            color={`${active === 1 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            User Profile
          </h5>
        </Link>
      </div>

      
      <div className="w-full flex items-center p-4">
        <Link
          to="#"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={30}
            color={`${active === 2 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[blue]" : "text-[#555]"
            }`}
          >
           Total ID: {user?.totalInvestmentDeposit === undefined ? 0 : user?.totalInvestmentDeposit}
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="#"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={30}
            color={`${active === 3 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Total IW: {user?.totalInvestmentWithdrawal === undefined ? 0 : user?.totalInvestmentWithdrawal}
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="#"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={30}
            color={`${active === 4 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Total IR: {user?.totalInvestmentReferrer === undefined ? 0 : user?.totalInvestmentReferrer}
          </h5>
        </Link>
      </div>

      
      <div className="w-full flex items-center p-4">
        <Link to="#" className="w-full flex items-center">
          <CiSettings
            size={30}
            color={`${active === 6 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default UserInvestDashboardSideBar;
