import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";

const Career = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <br />
        <br />
      <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Career 
        </h1> 
        <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          At Totlesoft we provide creative solutions to marketing needs and understand every business needs. We provide solutions with our wide range of design &
          advertising services. We would do all the hard work to ensure a company or individual brand is successful. Our philosophy says "client success is our success".<br /><br />
          
          We're always looking for great minds to join us in driving this great vision: Please choose and apply for a job bellow:</h2>
        <br />
        <hr />
        <br />


        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Affiliate Marketing (Sales.) - Totlesoft (Full Time) 
        </h3>
        <h4>Remote - Worldwide - $50-$2000/month </h4>
        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Job Description
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        We are seeking a skilled Marketer to join our team. The ideal candidate will have expertise in various digital marketing strategies and tools to drive brand awareness and customer engagement with the following responsibilities:<br />
        • Develop and implement marketing strategies to promote products or services.<br />
        • Manage online campaigns across various platforms such as Google Ads, social media, and affiliate marketing<br />
        • Optimize website content for SEO to improve search engine rankings<br />
        • Monitor and analyze campaign performance metrics to enhance results<br />
        • Collaborate with the team to create compelling marketing materials<br />
        • Budgeting and allocating resources effectively for marketing campaigns<br />
</p>

        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Requirements & Skills
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Proven experience in E-commerce and digital marketing<br />
        • Proficiency in Google Analytics, Google Ads, and SEO techniques<br />
        • Familiarity with affiliate marketing and campaign management<br />
        • Strong research skills to stay updated on industry trends<br />
        • Knowledge of SEM practices and digital advertising strategies<br />
        </p>

        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        This position offers the opportunity to work in a dynamic environment where creativity and innovation are valued. If you are passionate about marketing and possess the required skills, we encourage you to apply.
        </p>
        
        <Link to="/cv" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Apply Now
                 </span>
            </div>
        </Link>
        <br /><br /><br />


        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Graphics Designer (Tech.) - Totlesoft (Full Time) 
        </h3>
        <h4>Remote - Worldwide - $50-$150/month </h4>
        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Job Description
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Thinking creatively to produce new ideas and concepts and developing interactive design.<br />
        • Develop illustrations, logos and other designs using software or by hand.<br />
        • Creates visual communications for a variety of print and digital formats including magazines, newspapers, and websites.<br /> 
        • Use the appropriate colors and layouts for each graphics.<br /> 
        • Should be able to come up with creative ideas quickly.<br /> 
        • Create graphic content for company online pages.<br /> 
        • Create Social Media Covers for Facebook, Instagram, Youtube, Twitter etc.<br />
        • Attend to design request for company's client.<br /> 
        • Develop marketing materials and brand identity in collaboration with marketing unit.<br /> 
        • Work with copywriters and creative director to produce final design.<br /> 
        • Test graphics across various media.<br /> 
        • Amend designs after feedback.<br />
        • Ensure final graphics and layouts are visually appealing and on-brand.<br />
        • Works with UX team to conduct applicable pre-launch user testing to ensure implemented designs meet user needs and brand experience expectations. 
        </p>

        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Requirements & Skills
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Applicant must be familiar with Photoshop, Coreldraw or Adobe illustrator 
        (advantaged when also familiar with Canva & Pixellab)
        <br />
        •  SSCE, OND, HND, Bachelor's degree or equivalent work experience<br />
        •  A portfolio that showcases samples of UX/UI design work on desktop and mobile<br />
        •  Experience creating wireframes, storyboards, user flows, process flows and sitemaps to effectively communicate design and interaction ideas<br />
        *  Good grasp of Microsoft Office (Word, PowerPoint and Excel) and/or iWork (Keynote, Pages and Numbers)<br />
        •  Experience in creating digital/interactive tools (i.e., Adobe DPS, interactive PDFs, AfterEffects, Articulate, etc.)<br />
        •  Able to work under pressure and meet deadlines.
        </p>
        
        <Link to="/cv" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Apply Now
                 </span>
            </div>
        </Link>
        <br /><br /><br />


        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Social Media Marketer (Marketing) - Totlesoft (Full Time) 
        </h3>
        <h4>Remote - Worldwide - $100-$300/month </h4>
        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Job Description
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">We are looking for an experienced and creative Social Media Marketer to join the Totlesoft Marketing Team. 
        The ideal candidate should have a minimum of 2 years of professional experience in planning, executing, and optimizing social media marketing campaigns.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Develop and execute comprehensive social media marketing strategies across platforms such as Facebook, Youtube, Instagram, Twitter, LinkedIn, and others.<br />
        • Create and curate engaging and shareable content, including text, images, videos, and infographics, to build a strong social media presence.<br />
        • Manage and schedule social media posts, staying on top of trending topics and hashtags to maximize reach and engagement.<br />
        • Monitor and analyze key social media metrics, including engagement rates, click-through rates, and conversion rates, and use data-driven insights to refine strategies.<br />
        • Engage with the audience, respond to comments and messages, and foster meaningful conversations around our brand.<br />
        • Stay up-to-date with industry trends, algorithm changes, and best practices in social media marketing.<br />
        • Present performance reports to stakeholders, highlighting key insights and recommendations for future campaigns.<br />
        </p>

        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Requirements & Skills
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Bachelor's degree in marketing, advertising, communications, or a related field.<br />
        • Minimum of 2+ years of professional experience, with a portfolio showcasing successful social media campaigns.<br />
        • Proficiency in social media management tools and analytics platforms.<br />
        • Strong understanding of social media platforms, algorithms, and advertising options.<br />
        • Excellent written and verbal communication skills.<br />
        • Creative thinking and the ability to generate innovative social media ideas.<br />
        • Strong organizational skills to manage multiple social media channels and campaigns.<br />
        • Up-to-date knowledge of social media marketing best practices.<br />
        </p>
        
        <Link to="/cv" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Apply Now
                 </span>
            </div>
        </Link>
        <br /><br /><br />



        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Full Stack Developer - MERN (Tech.) - Totlesoft (Full Time) 
        </h3>
        <h4>Remote - Worldwide - $200-$500/month </h4>
        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Job Description
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">We are looking for experienced MERN developers to ensure easier and faster deployment of our web applications.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Developing and maintaining web applications using MongoDB, Expressjs, Reactjs, Redux and Nodejs.<br />
        • Creating and consuming RESTful APIs using Nodejs and Expressjs.<br />
        • Collaborate with cross-functional teams to ensure a better user experience.<br />
        • Ensure code quality by performing integration and unit tests.<br />
        • Write cross-platform-compatible code.<br />
        • Develop software to meet reliability, security, performance, scalability, and maintainability requirements.<br />
        • Participate in discussions about new features and approaches to implement new services with industry trends, algorithm changes, and best practices in social media marketing.<br />
        • Present performance reports to stakeholders, highlighting key insights and recommendations for future campaigns.<br />
        </p>

        <p className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#000000ba]">
        Requirements & Skills
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        • Bachelor’s/Master’s degree in Computer Science (or equivalent experience) <br />
        • 3+ years of development experience with MERN stack (rare exceptions for highly skilled candidates). <br />
        • Proficiency in MongoDB, Node.js Express.js, Redux and React.js. <br />
        • Ability to perform unit and integration tests. <br />
        • Solid understanding of REST APIs. <br />
        • Familiarity with Git repository and Jira. <br />
        • Knowledge of data storage solutions like NoSQL and RDBMS. <br />
        • Fluency in English to collaborate with engineering managers. <br />
        • Able to work under pressure and meet deadlines.
        </p>
        
        <Link to="/cv" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Apply Now
                 </span>
            </div>
        </Link>
        <br /><br /><br />



        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Legal Counsel (Financial Services) - Totlesoft (Full Time) 
        </h3>
        <h4>Port Harcourt - Nigeria - $12,000/yr</h4>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        As Legal Counsel (Financial Services) for Totlesoft (West Africa), you will manage all legal and 
        regulatory matters relating to the company’s payments business in West Africa 
        (Nigeria and any other countries in the region) as well as support the company’s 
        finance operations and treasury teams on intercompany cash flows and cross-border payment issues. 
        At times, you will also support strategic projects in other Totlesoft countries of operation as and when necessary. <br /><br />
        The ideal candidate will come from “start-up” like environments in digital payments or the financial services industry, 
        be result-oriented, and a clear communicator with a solid background in banking, payments or the fintech space. 
        The role is located in Port Harcourt, Nigeria with possible travel to other markets as and when required. <br />
       </p>
        
        <Link to="/cv" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Apply Now
                 </span>
            </div>
        </Link>
        <br /><br /><br />

        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
         Senior JavaScript Developer (Tech.) - Totlesoft (Full Time)
        </h3>
        <h4>Remote - UK - $30,000/yr</h4>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        As a Senior Software Developer, you will be part of an agile Software Development Team and 
        will be responsible for the full lifecycle of an application.<br /><br />
        You will be working with a team of talented Engineers across geographies, and entrusted in 
        defining the solution, leading the design, architecture and development of applications 
        using JavaScript and related technologies.<br /><br />
        You can expect to use cutting-edge technologies to deliver amazing value and the best product 
        to our customers while scaling our platform to meet the demands of worldwide markets. 
        You will be required to program well-designed, testable, efficient code to solve business needs, 
        as well as test and document the implemented solutions.<br />  
       </p>
        
        <Link to="/cv" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Apply Now
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

const CareerPage = () => {
    return (
      <div>
        <Header activeHeading={6} />
        <Career />
       </div>
    );
  };

export default CareerPage;

