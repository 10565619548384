import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const CV = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
      <br />
        <img
            src="https://vakilsearch.com/blog/wp-content/uploads/2022/05/master-service-agreement-vs-statement-of-work.jpg"
            alt=""
            className="w-[90%] mb-[15px] mt-3"
          />
        <br />
      <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[50px] text-[#3d3a3a] font-[500] capitalize`}
        >
          Join Totlesoft Work-force. 
        </h1> 
        <h2 className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">We're always looking for great minds to join us in driving this great vision: Please use bellow email to send your CV as instructed:</h2>
        
        <h3
          className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]"
        >
         On the email subject, Enter 'My CV for + (the Role you are applying for)
        </h3>
        <h4 className="text-[16px] font-[Poppins] text-[#000000ba]">e.g: My CV for Graphics Designer</h4>
        <br />
        

        <h3
        className={`text-[18px] leading-[1.2] 500px:text-[20px] text-[#3d3a3a] font-[500] capitalize`}
        >
        What makes Totlesoft work culture great:
        </h3>
        
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">
        Totlesoft built a collaborative team of independent owners. All of the company’s team members 
        are quick to brainstorm ideas and take ownership on tasks to make ideas happen. 
        <br />
        <br />
        <span className="text-[blue]">Send CV to: totlesoft.hr@gmail.com</span>
        </p>
        
        <Link to="mailto:totlesoft.hr@gmail.com" className="inline-block" target="_blank">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Send CV 
                 </span>
            </div>
        </Link>
        <br /><br /><br />

       
      </div>
    </div>
  );
};

const CVPage = () => {
    return (
      <div>
        <Header />
        <CV />
        <Footer />
      </div>
    );
  };

export default CVPage;


