import React from 'react'
import UsedCodes from "../components/CodeVendor/UsedCodes";
import CodeVendorHeader from '../components/Layout/CodeVendorHeader';
import CodeVendorSideBar from '../components/CodeVendor/Layout/CodeVendorSideBar';

const CodeVendorDashboardUsedCodes = () => {
  return (
    <div>
    <CodeVendorHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <CodeVendorSideBar active={3} />
        </div>
        <UsedCodes />
      </div>
    </div>
  </div>
  )
}

export default CodeVendorDashboardUsedCodes