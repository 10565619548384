import React, { useEffect, useState } from "react";
import { Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { createTask } from "../../redux/actions/task";
import { categoriesData } from "../../static/data";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../server";
import axios from "axios";
import { PaystackButton } from "react-paystack";
import styles from '../../styles/styles';
import { AiOutlinePlusCircle } from "react-icons/ai";
import Spinner from "../Spinner/Spinner";
import { dollarRate } from "../../dollarRate";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineFolderCopy } from "react-icons/md";

const CreateTask = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.tasks);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showdata, setShowData] = useState(true)
  const [images, setImages] = useState([]);
  const showImageButton = false;
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);
  const [openCrypto, setOpenCrypto] = useState(false);

  const [createCPayInvoice, setCreateCPayInvoice] = useState(true);
  const [showPayCPButton, setShowPayCPButton] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceOrderId, setInvoiceOrderId] = useState("");
  const [pDtoUse, setPDtoUse] = useState([]);

   
  const [hasPaid, setHasPaid] = useState(false);
  const [hasNotPaid, setHasNotPaid] = useState(true);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [taskPayment, setTaskPayment] = useState(0);
  const [taskBudget, setTaskBudget] = useState(0);
  const [paymentRef, setPaymentRef] = useState("")
  const taskQuantity = Math.round(taskBudget / taskPayment)
  const [targetedURL, setTargetedURL] = useState("");

  const [facebookURL, setFacebookURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [tiktokURL, setTiktokURL] = useState("");
  const [whatsAppGroupURL, setWhatsAppGroupURL] = useState("");


  const [spotifyURL, setSpotifyURL] = useState("");
  const [boomPlayURL, setBoomPlayURL] = useState("");
  const [soundCloudURL, setSoundCloudURL] = useState("");
  const [telegramURL, setTelegramURL] = useState("");
  const [googleURL, setGoogleURL] = useState("");
  const [trustpilotURL, setTrustpilotURL] = useState("");
    
  const [targetedCountry, setTargetedCountry] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    
  }, [dispatch, error]);  


  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

    const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("taskPayment", taskPayment);
    newForm.append("taskBudget", taskBudget);
    newForm.append("taskQuantity", taskQuantity);
    newForm.append("targetedURL", targetedURL);
    newForm.append("facebookURL", facebookURL);
    newForm.append("twitterURL", twitterURL);
    newForm.append("instagramURL", instagramURL);
    newForm.append("youtubeURL", youtubeURL);
    newForm.append("linkedinURL", linkedinURL);
    newForm.append("tiktokURL", tiktokURL);
    newForm.append("whatsAppGroupURL", whatsAppGroupURL);
    newForm.append("spotifyURL", spotifyURL);
    newForm.append("boomPlayURL", boomPlayURL);
    newForm.append("soundCloudURL", soundCloudURL);
    newForm.append("telegramURL", telegramURL);
    newForm.append("googleURL", googleURL);
    newForm.append("trustpilotURL", trustpilotURL);
    newForm.append("targetedCountry", targetedCountry);
    newForm.append("paymentRef", paymentRef);
    newForm.append("shopId", seller._id);
    dispatch(
      createTask({
        name,
        description,
        category,
        tags,
        taskPayment,
        paymentRef,
        taskBudget,
        taskQuantity,
        targetedURL,
        facebookURL,
        twitterURL,
        instagramURL, 
        youtubeURL, 
        linkedinURL, 
        tiktokURL, 
        whatsAppGroupURL,
        spotifyURL,
        boomPlayURL,
        soundCloudURL,
        telegramURL,
        googleURL,
        trustpilotURL,
        targetedCountry,
        shopId: seller._id,
        images
      })
    );
    setIsLoading(false);
    toast.success("Task created successfully!");
    navigate("/dashboard-uns-tasks");
    window.location.reload();
  };
//  pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964
  
  const publicKey = "pk_test_ce3becdbd917d77292665073fccbc6de12dfa5b8";
  const dollar_rate = dollarRate;

  const vat = taskBudget * 3 / 100;
  const pTaskBudget = Number(taskBudget) + Number(vat);
 
  const paymentInNaira = Math.round(pTaskBudget * dollar_rate)
  const vatinNaira = Math.round(vat * dollar_rate)

       
  const componentProps = {
    name: seller?.name,
    email: seller?.email,
    amount: Math.round(pTaskBudget * 100 * dollar_rate),
    publicKey,
    referenceID: +Math.floor((Math.random() * 1000000000) + 1),
    text: "Pay Now",
    onSuccess: () => paywithPaystack(),
    onClose: function() {
      alert('Transaction was not completed, window closed.');
    },
   
  }


  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Task Listing Fee",
            amount: {
              currency_code: "USD",
              value: pTaskBudget,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler();
      }
    });
  };

  const paypalPaymentHandler = async () => {

          toast.success("Payment Completed!");
          paywithPaystack();
                     
  };

      
  const paywithPaystack = () => {
    setShowData(false)
    setHasPaid(true)
    setHasNotPaid(false)
    setOpen(false)
    setOpenPaypal(false)
    setOpenCrypto(false)
    setShowPayCPButton(false)
    
  }

  const invData = {
    amount: taskBudget,
    currency: "USD",
    additional_data: seller?.email,
}


const creatCPInvoice = async () => {
setIsLoading(true);

try {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { data } = await axios.post(
    `${server}/payment/crypto-checkout`,
    invData,
    config
  );

  const url = data.url;
  const order_id = data.order_id;
  
  if(url !== null && order_id !== null){
    setInvoiceUrl(url);
    setInvoiceOrderId(order_id);
    setCreateCPayInvoice(false);
    setShowPayCPButton(true);
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
};  


const confirmCPaymentInfo = async () => {
setIsLoading(true);

try {

const order_id = invoiceOrderId;

     
const { data } = await axios.get(
    `${server}/payment/crypto-payment-info/${order_id}`);
    setPDtoUse(data);

    if(!data){
      toast.error("Payment not found with this order Id")
    }

  const status = pDtoUse.cryptopayments[0].status;
  const is_final = pDtoUse.cryptopayments[0].is_final;

  if(status === "paid" && is_final === true){
           
    paywithPaystack();
    setIsLoading(false);
     
  }else{   

    toast.error("Payment is still processsing");
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
}; 

const orderID = invoiceOrderId;

  const writeTextToClipboard = (text) => {
    text = orderID;
    navigator.clipboard.writeText(text);
    toast.success("Id Copied!");
  };

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full items-center ml-5 py-5 px-5">
      <h5 className="text-[30px] font-Poppins">Create Campaign</h5>
      
      <div className="w-[90%]">
      {/* create task form */}
      <div className="w-full 800px:w-[65%]">
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Campaign Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g Subscribe to My Youtube Page..."
          />
        </div>
        <br />
        <p>NOTE: You cannot, for example, promote Facebook and Instagram  on one singe task. If you want to grow Facebook and Intagram or any other of your social handles, then you are to create separate tasks each. This is to help our system to track workers credibility on tasks done.</p>
        <br />
        <div>
          <label className="pb-2">
            Full Campaign Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your task description..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Campaign Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose task category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">Tags</label>
          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTags(e.target.value)}
            placeholder="Enter your task tags..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Earning</label>
          <input
            type="number"
            name="price"
            value={taskPayment}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskPayment(e.target.value)}
            placeholder="Enter price per task..."
          />
        </div>
        <br />
        
        <br />
        <div>
          <label className="pb-2">Campaign Budget: USD <span className="text-red-500">*</span></label>
          <input
            type="number"
            name="taskbudget"
            value={taskBudget}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskBudget(e.target.value)}
            placeholder="Enter task budget..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Quantity <span className="text-red-500">*</span>
          </label>
          <h5
            name="taskquantity"
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >{taskQuantity}</h5>
        </div>
        <br />
        <hr />
        <br />
        <label className="pb-2 font-[600]">
            Campaign Targeted URL
          </label>
        <p>NOTE: You're to provide just a single targeted link. You cannot add two targeted link per task.</p>
        <br />
        
        <div>
          <label className="pb-2">
            Facebook URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="facebookURL"
            value={facebookURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setFacebookURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Twitter URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="twitterURL"
            value={twitterURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTwitterURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Instagram URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="instagramURL"
            value={instagramURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setInstagramURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Youtube URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="youtubeURL"
            value={youtubeURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setYoutubeURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Linkedin URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="linkedinURL"
            value={linkedinURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setLinkedinURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Tiktok URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="tiktokURL"
            value={tiktokURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTiktokURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            WhatsApp Group URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="whatsAppGroupURL"
            value={whatsAppGroupURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setWhatsAppGroupURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Spotify URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="spotifyURL"
            value={spotifyURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpotifyURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Boomplay URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="boomPlayURL"
            value={boomPlayURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setBoomPlayURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Soundcloud URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="soundCloudURL"
            value={soundCloudURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSoundCloudURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Telegram URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="telegramURL"
            value={telegramURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTelegramURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />


        <div>
          <label className="pb-2">
            Google URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="googleURL"
            value={googleURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setGoogleURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Trustpilot URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="trustpilotURL"
            value={trustpilotURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTrustpilotURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Other Campaign Target URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="targetedURL"
            value={targetedURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTargetedURL(e.target.value)}
            placeholder="Enter your task quantity..."
          />
        </div>
        <br />
        <hr />
        <br />

        <div>
              <label
                htmlFor="targetedCountry"
                className="block text-sm font-medium text-blue-700"
              >
                <span className="text-[16px] text-[#000] text-[400]">Targeted Country</span> (Choose "Worldwide" if you wnat your task to be open to all countries") <span className="text-red-500">*</span>
              </label>
                  <div className="mt-1">
                  <select
                      name=""
                      id=""
                      value={targetedCountry}
                      onChange={(e) => setTargetedCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                       Choose Country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                        <option value="Worldwide">Worldwide</option>
                    </select>
                  </div>
              <h6
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country Not Listed and you want us to add it? send an email to us at <span className="text-blue-500">info@totlesoft.com</span>
              </h6>
            </div>

        <div>
          {showImageButton && 
          <div>
            <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
            </div>}
          

          <br />
          {hasPaid && 
          <>
          <div>
          <label className="pb-2">
            Payment Reference: <span className="text-red-500 font-[600]">Only when paying with Naira.</span> (Check payment receipt in email) else enter "Card/Paypal" or "Crypto" into the bux <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="tasktargeturl"
            value={paymentRef}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setPaymentRef(e.target.value)}
            placeholder="e.g. T181308212431343"
          />
        </div>
        <br />
        <div>
            <input
              type="submit"
              value="Create Campaign"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          </>

          }
        </div>

        <br />
         {hasNotPaid && 
         <p className="mb-4 text-[16px] font-[600] text-[red]">Pay Listing Fee bellow to Activate Create Button and get your Payment Ref:</p>
         }
        
         </form>
         {hasNotPaid && (
            <>
            <div className="flex justify-between w-[90%] 800px:w-[50%]">
            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center`}
            onClick={() => setOpen(true)}
            >
                Naira
            </button>

            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center !px-3`}
            onClick={() => setOpenPaypal(true)}
            >
                USD/PayPal
            </button>

            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center`}
            onClick={() => setOpenCrypto(true)}
            >
                Crypto
            </button>
            </div>
            </>
          )}
         
         </div>
                  

         {open &&  (
          
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] 800px:w-[50%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-6" >
            <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  USD/Naira Rate:
                </label>
                {showdata && 
                <div className="mt-1">
                  <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    $1USD = N{seller && dollar_rate} Naira
                  </h4>
                 </div>
                  }
              </div>
  
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Full Name:
                </label>
                {showdata && 
                <div className="mt-1">
                  <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    {seller && seller?.name}
                  </h4>
                 </div>
                  }
              </div>
  
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address:
                </label>
                {showdata && 
                <div className="mt-1">
                  <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    {seller && seller?.email}
                  </h4>
                 </div>
                 }
              </div>
 
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  10% Vat NGN:
                </label>
                {showdata && 
                <div className="mt-1">
                  <div>
                 <h5
                 name="taskbudget"
                 className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                 >{vatinNaira}</h5>
                 </div>
                 </div>
                 }
              </div>
  
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Task Budget + Vat NGN:
                </label>
                {showdata && 
                <div className="mt-1">
                  <div>
                 <h5
                 name="taskbudget"
                 className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                 >{paymentInNaira}</h5>
                 </div>
                 </div>
                 }
              </div>
              </form>
  
            {showdata ? (
             
              <PaystackButton {...componentProps}
              className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
              type="submit"
              
              />
 
            ) : (
              <div>
                <br />
              <button 
              className={`!bg-[#0fa311] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] pl-[10px] pr-[10px]`} 
             
              >Payment is Completed. Create Campaign</button>
              <br />
              </div>
            )}
          <div>
          <br />
          </div>       
         </div>
         </div>
        )}


            {openPaypal && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] 800px:w-[50%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenPaypal(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
         {hasNotPaid &&
         
         <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
           <br />

          <PayPalScriptProvider
            options={{
            "client-id":
            "AWhLs3ILRhUxqvgpcKjd6rvoU47rg_l7Yyc_GW-InHPkuLbpCouEE29HniCePvK8L1QxbneMxJezAzUq",
            }}
          >
          <PayPalButtons
          style={{ layout: "vertical" }}
          onApprove={onApprove}
          createOrder={createOrder}
          />
        </PayPalScriptProvider>       
        </div>
       }
       </div>

      </div>
    </div>
    )}



          {openCrypto && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] 800px:w-[30%] bg-white shadow rounded h-[70%] min-h-[50vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCrypto(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        {createCPayInvoice &&
          <>
          <p>Click button bellow to generate crypto payment invoice</p> 
          <div>
           <br />
            <button
                className={`${styles.button} !h-[42px] text-white`}
                onClick={creatCPInvoice}
              >
                Generate
              </button>
            </div>

        </>      
       }
       </div>

       {showPayCPButton && 
            <>
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p className="text-[green] font-[600]">Invoice generated successfully</p> 
        <h5 className="text-[16px] font-[600]">Order Id: {invoiceOrderId} 
        <MdOutlineFolderCopy 
        onClick={writeTextToClipboard}
        size={20}
        className="cursor-pointer mb-2"
        />
        </h5> 
        <h5 className="text-[red] text-[14px] font-[500]">Please save this order_id, it will be requested if there's an error with this order.</h5><br />

        <Link to={`${invoiceUrl}`} target="_blank">
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}>
         Pay 
        </h5>
        </Link>
        </div>
        <br />
        <h5>Wait atleast a minute after the payment has marked 'Payment is paid' before clicking the Completed button.</h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={confirmCPaymentInfo}
        >
         Completed 
        </h5>
        </>
            }

      </div>
    </div>
    )}


    </div>
    </div>
    )}
    </>
    
  );
};

export default CreateTask;

