import axios from "axios";
import { server } from "../../server";

// load user
export const loadMiner = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadMinerRequest",
    });
    const { data } = await axios.get(`${server}/miner/getMiner`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadMinerSuccess",
      payload: data.miner,
    });
  } catch (error) {
    dispatch({
      type: "LoadMinerFail",
      payload: error.response.data.message,
    });
  }
};



// get all users --- Open
export const getAllUsersOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersOpenRequest",
    });

    const { data } = await axios.get(`${server}/miner/all-miners`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllMinersOpenSuccess",
      payload: data.miners,
    });
  } catch (error) {
    dispatch({
      type: "getAllMinersOpenFailed",
      payload: error.response.data.message,
    });
  }
};
