import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo1 from '../../Assests/images/logo.png'
import { FcMoneyTransfer } from "react-icons/fc";
import { BiTransfer } from "react-icons/bi";
import { CiMoneyBill } from "react-icons/ci";

const AdminHeader = () => {
    const {user} = useSelector((state) => state.user);

  return (
         <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img
            src={logo1}
            width='130px'
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4 justify-between gap-[20px]">
          
        <Link
          to="/admin-transfers"
          className="800px:block hidden"
        >
          <BiTransfer
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
          All Transfers
          </h5>
        </Link>
        

        <Link
          to="/admin-withdraw-request-workers"
          className="800px:block hidden"
        >
          <FcMoneyTransfer
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
          Withdrawal-w
          </h5>
        </Link>

        <Link
          to="/admin-withdraw-request"
          className="800px:block hidden"
        >
          <CiMoneyBill
            size={20}
            color="#555"
            className="mx-5 cursor-pointer"
           />
          <h5>
          Withdrawal-c
          </h5>
        </Link>

            <img
              src={`${user?.avatar?.url}`}
              alt=""
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
        </div>
      </div>
    </div>
  )
}

export default AdminHeader