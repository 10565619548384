import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function ProductTesting() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

   return (
    <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
      <br />
      <br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-15px`}
        >
          {t('pt_title')}
        </h1>

        <div className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        <p>
        {t('pt_p1')}
        </p>
        <br />
        <p>
        {t('pt_p2')}  
        </p>
        <br />
        <p className="font-[600]">{t('pt_p3t')}</p>
        <p>{t('pt_p3')}</p>
          
          <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px]">
                 {t('pt_Connect1')}
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <p className="font-[600]">{t('pt_p4t')}</p>
        <p>{t('pt_p4')}</p>
          <br />
          <br />

          <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://thumbs.dreamstime.com/b/modern-technology-electronics-programmer-testing-diy-product-engineering-his-new-invention-laptop-166007054.jpg"
            alt="Product Testing"
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('pt_p5t')}</p><br />
        <p className="font-[500]">
        {t('pt_p5a1t')} 
        </p>
        <p>
        {t('pt_p5a1')}
        </p>
        <br />
        <br />

        <p className="font-[500]">
        {t('pt_p5a2t')}
        </p>
        <p>
        {t('pt_p5a2')}
        </p>        
        <br />
        <br />

        <p className="font-[500]">
        {t('pt_p5a3t')} 
        </p>
        <p>{t('pt_p5a3')}
        </p>        
        <br />
        <br />


        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://conjointly.com/img/identify-the-best-claims.jpg"
            alt="Product Testing"
          />
        </div>
        <br />
        <br />

        <p className="font-[500]">
        {t('pt_p5a4t')} 
        </p>
        <p>{t('pt_p5a4')}
        </p><br />
        <p>{t('pt_p5a4b')}
        </p>        
        <br />
        <br />

        <p className="font-[500]">
        {t('pt_p5a5t')}
        </p>
        <p>{t('pt_p5a5')}
        </p>
        <br />
        <br />

        <p className="font-[600]">{t('our_methods')}</p>
        <p className="font-[500]">{t('our_methods_a')}</p>
        <p className="font-[500]">{t('our_methods_b')}</p>
        <br />
        <br />

        <p className="font-[600]">{t('pt_p6t')}</p>
        <p>
        {t('pt_p6')}
        </p>
        <br />
        <br />

        <p className="font-[600]">{t('h_a_job')}</p>


        <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px]">
                 {t('pt_Connect2')}
                 </span>
            </div>
        </Link>
        <br />
        <br />


        </div>
        <br />
        <br />
       
      </div>
  )
}

export default ProductTesting;