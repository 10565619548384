import React from 'react'
import DashboardHeader from '../../components/Talent/Layout/TalentDashboardHeader';
import DashboardSideBar from '../../components/Talent/Layout/TalentDashboardSideBar';
import CreateTalent from "../../components/Talent/CreateTalent";
import { useSelector } from "react-redux";

const CreateTalentPage = () => {
    const { user } = useSelector((state) => state.user);

  return (
    <div>
       <DashboardHeader />
        <div className="flex items-center justify-between w-full">
            <div className="w-[80px] 800px:w-[330px]">
              <DashboardSideBar />
            </div>
           <div className="w-full justify-center flex">
                <CreateTalent />
            </div>
           </div>
          </div>
  )
}

export default CreateTalentPage;