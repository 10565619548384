import React from "react"
import ProductTesting from "../components/ProductTesting/ProductTesting";
import Header from "../components/Layout/Header";

const ProductTestingPage = () => {
  return (
    <div>
      <Header />
      <ProductTesting />
      </div>
  );
};


export default ProductTestingPage 

