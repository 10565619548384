import React, { useEffect } from "react";

import facebook from '../../Assests/logos/facebook.png';
import instagram from '../../Assests/logos/instagram.png';
import trustpilot from '../../Assests/logos/trustpilot.png';
import googleNew from '../../Assests/logos/googleNew.png';

import youtube from '../../Assests/logos/youtube.png';
import spotify from '../../Assests/logos/spotify.png';

import boomplay from '../../Assests/logos/boomplay.png';
import linkedin from '../../Assests/logos/linkedin.png';
import tiktok from '../../Assests/logos/tiktok.png';
import whatsapp from '../../Assests/logos/whatsapp.png';

import soundcloud from '../../Assests/logos/soundcloud.png';
import twitter from '../../Assests/logos/twitter.png';
import telegram from '../../Assests/logos/telegram.png';


import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const SupportedHandles = () => {

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

    
  
  return (
    <div
      className={`relative min-h-[60vh] 800px:min-h-[70vh] w-full bg-no-repeat`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
        <br />
        <br />
        <h2 className="text-[25px] font-[500] text-center items-center justify-center">{t('Supported_Handles')}</h2>
        <div className="px-10 py-12 grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] my-12">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src={facebook}
            width='100px'
            alt="facebook"
          />
        </div>
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src={trustpilot}
            width='100px'
            alt="trustpilot"
          />
        </div>
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src={instagram}
            width='100px'
            alt="instagram"
          />
        </div>
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src={googleNew}
            width='100px'
            alt="google"
          />
        </div>
       </ul>

        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={boomplay}
            width='40px'
            alt="boomplay"
          />
        </div>
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={linkedin}
            width='80px'
            alt="linkedin"
          />
        </div>
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={spotify}
            width='100px'
            alt="spotify"
          />
        </div>
        </ul>

        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={soundcloud}
            width='60px'
            alt="soundcloud"
          />
        </div>
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={tiktok}
            width='80px'
            alt="tiktok"
          />
        </div>
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={whatsapp}
            width='80px'
            alt="whatsapp"
          />
        </div>
        </ul>

        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={twitter}
            width='30px'
            alt="twitter"
          />
        </div>
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={telegram}
            width='30px'
            alt="telegram"
          />
        </div>
        <div className="mb-6 sm:block flex items-center justify-center w-full">
          <img
            src={youtube}
            width='60px'
            alt="youtube"
          />
        </div>
       </ul>
       </div>
    
    </div>
  );
};

export default SupportedHandles;
