import { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../../Assests/images/contact-phone2.png";
import 'animate.css';
import './contact.css';
import TrackVisibility from 'react-on-screen';
import emailjs from '@emailjs/browser';

  const Contact = () => {

  const form = useRef();

  const [delMessage, setDelMessage] = useState('')
  
   
  const sendEmail = (e) => {
    e.preventDefault();
   
    emailjs.sendForm('service_0992ipa', 'template_lvzq8nv', form.current, '67_o2ZZDP1_OUY8sw')
    .then((result) => {
      console.log(result.text);
      setDelMessage('Message Sent Successfully! Expect a feedback soon.')
  }, (error) => {
      console.log(error.text);
      setDelMessage('Message sending failed! Please try again later.')
  });

    e.target.reset();
  };

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6} className="cont-left">
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6} className="cont-right">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                <h2 className="mb-8 text-[25px] font-[600] text-[#fff] text-center">Get In Touch</h2>
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" required='true' placeholder="First Name" name="first_name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="Last Name" name="last_name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" required='true' placeholder="Email Address" name="user_email"  />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" required='true' placeholder="Phone No." name="user_phone" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" required='true' placeholder="Type of Job (e.g Graphic design, Web)." name="job_type" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" required='true' placeholder="Location (e.g UK)." name="user_location" />
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" required='true' placeholder="Message" name="message" ></textarea>
                    <Col>
                    <p className="text-[#fff]">{delMessage}</p>
                    </Col>
                      <button type="submit"><span>Send</span></button>
                    </Col>
                    
                  </Row>
                  
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact;