import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TaskDetails from "../components/Tasks/TaskDetails";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


const TaskDetailsPage = () => {
  const { allTasks } = useSelector((state) => state.tasks);
  const { id } = useParams();
  const [data, setData] = useState(null);
  

  useEffect(() => {
      const data = allTasks && allTasks.find((i) => i._id === id);
      setData(data);
   
  }, [allTasks]);
  
  return (
    <div>
      <Header />
      {data ? (
        <TaskDetails data={data} />
      ) : 
        <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500]">Loading...</h1>
          <p>Slow network detected.</p>
        </div>
     }
      <Footer />
    </div>
  );
};

export default TaskDetailsPage;
