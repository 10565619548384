import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const articleReducer = createReducer(initialState, {
    articleCreateRequest: (state) => {
    state.isLoading = true;
  },
  articleCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.article = action.payload;
    state.success = true;
  },
  articleCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all articles of user
  getAllArticlesUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllArticlesUserSuccess: (state, action) => {
    state.isLoading = false;
    state.articles = action.payload;
  },
  getAllArticlesUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all articles
  getAllArticlesRequest: (state) => {
    state.isLoading = true;
  },
  getAllArticlesSuccess: (state, action) => {
    state.isLoading = false;
    state.allArticles = action.payload;
  },
  getAllArticlesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },
});
