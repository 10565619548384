import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import logo1 from "../Assests/images/logo.png";
import logo0 from "../Assests/images/complete.png"
import tpilot from "../Assests/images/trustpilotIcon.png";
import greviews from "../Assests/images/greviews.png"
import { Link } from "react-router-dom";



const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  
  return (
    <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
      <br /><br />
      <h5 className="text-center mb-5 mt-15 text-[35px] text-[green]">
        Your order was successful 
      </h5>
      <div className="pb-3 pt-3">
            <Link to="/">
              <img
                src={logo0}
                width='70px'
                alt=""
              />
            </Link>
          </div>
      <div className="text-center mb-14 text-[20px] text-[#000000a1] p-3">
      <p className="text-[30px] font-[500]">Thanks for your patronage!</p>
      <p style={{paddingRight: "15px", paddingLeft: "15px"}}>If we have satisfied you please tell others, if we didn't satisfy you please tell us.</p>
      <p style={{paddingRight: "15px", paddingLeft: "15px"}}>You can also leave a rating to help push this product to many hands.</p>
      </div>

      <div className="pb-3 pt-1">
            <Link to="/">
              <img
                src={logo1}
                width='150px'
                alt="Totlesoft"
              />
            </Link>
          </div>
      <br />
      <br />

      <p className="text-[17px] text-[green] font-[500]">kindly review us on:</p><br />
        <div className="pb-3 pt-1">
        <a href="https://www.trustpilot.com/review/totlesoft.com" target="_blank" rel="noopener">
          <img
            src={tpilot}
            width='150px'
            alt="Trustpilot review"
          />
          </a>
        </div>
        <br />

        <div className="pb-3 pt-1">
        <a href="https://www.google.com.ng/search?trk=https://notifications.google.com/g/p/ANiao5otKVDTZoku5LglPd1530Kr6CoBW-_flCOxr-YFiwBfH5L4zL_vFitnDly_8lLWEVQyjR8Ap8pq6nfhOSkCK2DFD_mZjboA5-Ad_H8hio1cH1NXQMOzU9gsUVxvtZROAjCK1vMadEIl_iAtiZQuJ4T98c17SBLUg2IDo-T1R2mZUKY2m_yf9T3VotKKsobUh02SjSVcb3Gm7xkMKrOxkw&q=Totlesoft&ludocid=17255362347513318282&localrlb=1&lsig=AB86z5XOsUeXaQ5vL9gg2qUbQ8oq#ip=1" target="_blank" rel="noopener">
          <img
            src={greviews}
            width='100px'
            alt="Google review"
          />
          </a>
        </div>
        <br />
        <br />
    </div>
  );
};

export default OrderSuccessPage;
