import React from 'react';
import { Link } from "react-router-dom";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";

const ArticleCard = ({ data }) => {
       
  const viewsHandler = async () => {
    
    const viewsN = 1;

    await axios

      .put(
        `${server}/article/update-article-views/${data._id}`,
        {
          newView: viewsN,
        },
        { withCredentials: true }
      )
     .catch((error) => {
        toast.error("Views update failed!");
      });
  }

  
    return (
        <div className="w-full h-[470px] bg-[#F6F6F5] rounded-lg shadow-lg px-2 py-1 relative cursor-pointer"
        onClick={viewsHandler}
        >
          <Link to={`/resource-center/article/${data._id}`}>
        <img
            src={`${data.images && data.images[0]?.url}`}
            alt="code vendor"
            className="w-full object-contain pt-2"
          />
          <h4 className="py-1 px-1 text-[18px] font-[500] text-[#000000ba]">
           {data.name}
          </h4>
          <h5 className='text-[13px] text-[blue] px-1'>By: {data.user.name}</h5>
          <h5 className='text-[13px] mb-1 px-1'>{data.views} views</h5>
          <div className="py-2 px-1 flex items-center justify-between">
           <span className="font-[400] text-[13px] text-[blue]">
            read more
            </span>
          </div>
          </Link>
        </div>

    )
}

export default ArticleCard;