import React, { useEffect, useState } from "react";

import styles from "../../styles/styles";
import clogo from "../../Assests/images/ocpics.png";

import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import { RxCross1 } from "react-icons/rx";
import tPay from '../../Assests/logos/totlesoftPay.png';
import { Link, useNavigate } from "react-router-dom";
import { dollarRate } from "../../dollarRate";
import { PaystackButton } from "react-paystack";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { MdOutlineFolderCopy } from "react-icons/md";
import { useSelector } from "react-redux";

function OPS() {

  const [openCardPayment, setOpenCardPayment] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);
  const [openCrypto, setOpenCrypto] = useState(false);
  const [openBank, setOpenBank] = useState(false);
  const [createCPayInvoice, setCreateCPayInvoice] = useState(true);
  const [showPayCPButton, setShowPayCPButton] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceOrderId, setInvoiceOrderId] = useState("");
  const [pDtoUse, setPDtoUse] = useState([]);
  const navigate = useNavigate();

  const [codeQuantity, setCodeQuantity] = useState(0);
  const codeAmount = 6;

  const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  
  //const [camount, setCamount] = useState(0);

    
  const payPalAmount = Number(codeQuantity) * Number(codeAmount);

  const damount = Number(codeQuantity) * Number(codeAmount);

  const realCAmoun = damount;


  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Deposit to Payment Balance",
            amount: {
              currency_code: "USD",
              value: payPalAmount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler();
      }
    });
  };

  const paypalPaymentHandler = async () => {

    if(codeQuantity === 0){
      toast.error("Invalid Code Quantity")
    }
    
    const paymentType = "USD/PayPal"
    const email = user?.email;
    const amount = Number(payPalAmount);
    const codepayerId = user?._id;
    
    setIsLoading(true);
      await axios
        .post(
          `${server}/codepayment/direct-purchase`,
          { amount, email, codepayerId, codeQuantity, paymentType },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          setOpenPaypal(false);
          toast.success("Purchase successful!");
          navigate("/order/success");
          window.location.reload();
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsLoading(false);
      });
  };

    const publicKey = "pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964";
    const dollar_rate = dollarRate;
    const paymentInNaira = Math.round(damount * dollar_rate)
  
    const componentProps = {
      email: user?.email,
      amount: Math.round(damount * 100 * dollar_rate),
      publicKey,
      referenceID: +Math.floor((Math.random() * 1000000000) + 1),
      text: "Pay now",
      onSuccess: () => ngnPaymentHandler(),
      onClose: function() {
        alert('Transaction was not completed, window closed.');
      },
     
    }

    const ngnPaymentHandler = async () => {

      
      const paymentType = "Naira"
      const email = user?.email;
      const amount = Number(damount);
      const codepayerId = user?._id;
  
      setIsLoading(true);
        await axios
          .post(
            `${server}/codepayment/direct-purchase`,
            { amount, email, codepayerId, codeQuantity, paymentType },
            { withCredentials: true }
          )
          .then((res) => {
            setIsLoading(false);
            setOpenCardPayment(false)
            toast.success("Purchase successful!");
            navigate("/order/success");
            window.location.reload();
            })
            .catch((error) => {
              toast.error(error.response.data.message);
              setIsLoading(false);
        });
    };

    const cPayToString = realCAmoun.toString();
   
      
    const invData = {
          amount: cPayToString,
          currency: "USD",
          additional_data: user?.email,
    }
  
    
    const creatCPInvoice = async () => {
      
      if(codeQuantity === 0){
        toast.error("Invalid Code Quantity")
      }

      setIsLoading(true);
  
     try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        const { data } = await axios.post(
          `${server}/payment/crypto-checkout`,
          invData,
          config
        );
  
        const url = data.url;
        const order_id = data.order_id;
          
        if(url !== null && order_id !== null){
          setInvoiceUrl(url);
          setInvoiceOrderId(order_id);
          setCreateCPayInvoice(false);
          setShowPayCPButton(true);
          setIsLoading(false);
        }
      }
      catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    };  
  
    
    const confirmCPaymentInfo = async () => {
      setIsLoading(true);
  
     try {
  
      const order_id = invoiceOrderId;
  
           
      const { data } = await axios.get(
          `${server}/payment/crypto-payment-info/${order_id}`);
          setPDtoUse(data);
  
          if(!data){
            toast.error("Payment not found with this order Id")
          }
  
        const status = pDtoUse.cryptopayments[0].status;
        const is_final = pDtoUse.cryptopayments[0].is_final;
  
        if(status === "paid" && is_final === true){
                 
            cryptoPaymentHandler();
           
        }else{
          toast.error("Payment is still processsing");
          setIsLoading(false);
        }
      }
      catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    }; 
  
    
    const cryptoPaymentHandler = async () => {
  
      const paymentType = "Crypto"
      const email = user?.email;
      const amount = realCAmoun;
      const codepayerId = user?._id;
  
      setIsLoading(true);
        await axios
          .post(
            `${server}/codepayment/direct-purchase`,
            { amount, email, codepayerId, codeQuantity, paymentType },
            { withCredentials: true }
          )
          .then((res) => {
            setIsLoading(false);
            setOpenCrypto(false)
            toast.success("Purchase successful!");
            navigate("/order/success");
            window.location.reload();
            })
            .catch((error) => {
              toast.error(error.response.data.message);
              setIsLoading(false);
        });
    };
  
    
    const orderID = invoiceOrderId;
  
    const writeTextToClipboard = (text) => {
      text = orderID;
      navigator.clipboard.writeText(text);
      toast.success("Id Copied!");
    };

    console.log(payPalAmount, damount, cPayToString, codeQuantity);


  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
      <br />
      <br />

      <div className="pb-3 pt-3">
            <Link to="/">
              <img
                src={clogo}
                width='80px'
                alt=""
              />
            </Link>
          </div>

        <h1
          className={`text-[18px] leading-[1.2] 800px:text-[30px] text-[#3d3a3a] font-[600] capitalize mt-15px`}
        >
          Totlesoft Other Countries Activation Code Purchase
        </h1>
        <h5 className="pt-5 text-[16px] font-[Poppins] font-[500] text-[#000000ba]">Use any of the bellow payment options to complete your checkout on activation code purchase.</h5>
        <h5 className="pt-1 text-[16px] font-[Poppins] font-[500] text-[#000000ba]">NOTE: The code will be sent to the email address in your totlesoft account.<br /> 
        <span className="text-[red]">Do not reveal your code till it has been used</span> for we will not be liable for any lose. Thanks.</h5>

        <div className="pt-5 text-[18px] font-[Poppins] font-[400] text-[#000000ba]">






        <div className="w-full h-[90vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
      <div className="800px:w-[60%] 800px:h-[95%] bg-[#F6F6F5] w-[90%] h-[95%] rounded flex text-center items-center justify-center flex-col 800px:py-2">
        <h5 className="text-[20px] text-[blue]">
          Payment Options: 
        </h5>
        <div className="w-full pb-3">
                    <div className=" w-[100%]">
                      <label className="block pt-2">Code Quantity</label>
                      <div className="mt-1">
                        <select
                        name="codeQuantity"
                        onChange={(e) => setCodeQuantity(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />
        
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenCardPayment(true)}
        >
         Naira 
        </h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenPaypal(true)}
        >
        USD/Paypal 
        </h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenBank(true)}
        >
         Bank 
        </h5>
       <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={() => setOpenCrypto(true)}
        >
         Crypto 
        </h5>
       <Link to="/profile">
        <h5 className="text-[18px] text-[blue] pt-1">
         Cancel 
        </h5>
        </Link>
        <div>
        <img
            src={tPay}
            width='150px'
            alt="totlesoftPay"
          />
        </div>
        </div>
        </div>
        
        {openPaypal && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                  <div className="w-full flex justify-end p-3">
                    <RxCross1
                      size={30}
                      className="cursor-pointer absolute top-3 right-3"
                      onClick={() => setOpenPaypal(false)}
                    />
                  </div>

                  <br />

                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "AWhLs3ILRhUxqvgpcKjd6rvoU47rg_l7Yyc_GW-InHPkuLbpCouEE29HniCePvK8L1QxbneMxJezAzUq",
                      }}
                    >
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        onApprove={onApprove}
                        createOrder={createOrder}
                       />
                    </PayPalScriptProvider>
                    
                </div>
              </div>
            )}



        {openCardPayment && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div
            className={`w-[95%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}
          >
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCardPayment(false)}
                className="cursor-pointer"
              />
            </div >
                <form>
                    <div>
                     <div>
                      <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700">
                      Deposit Amount:
                      </label>
                      <div className="mt-1">
                      <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      {damount} USD
                      </h4>
                      </div>
                    </div>

                      <div>
                      <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mt-3">
                      USD/Naira Rate:
                      </label>
                     <div className="mt-1">
                      <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      1 USD = {dollar_rate} NGN
                      </h4>
                      </div>
                    </div>

              <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700 mt-3">
                 Amount to pay in Naira
               </label>
               <div className="mt-1">
                 <div>
                <h5
                name="taskbudget"
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >{paymentInNaira} NGN</h5>
                </div>
                </div>
              </div>
              
              <br />
              </div>
              </form>

              <PaystackButton {...componentProps}
             className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
             type="submit"
            />
            </div>
          
            </div>
      )}


          {openCrypto && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] 800px:w-[30%] bg-white shadow rounded h-[70%] min-h-[50vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCrypto(false)}
                className="cursor-pointer"
              />
            </div>
            <h5 className="text-[red] text-[14px] font-[500]">Keep page open till the payment has been marked as "paid"</h5><br />
        

            {createCPayInvoice && 
            <>
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p>Click button bellow to generate crypto payment invoice</p> 

       
        <div>
          <button
                        className={`${styles.button} !h-[42px] text-white`}
                        onClick={creatCPInvoice}
                      >
                        Generate
                      </button>
                    </div>
        

       
        </div>
            </>
            }


            {showPayCPButton && 
            <>
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p className="text-[green] font-[600]">Invoice generated successfully</p> 
        <h5 className="text-[16px] font-[600]">Order Id: {invoiceOrderId} 
        <MdOutlineFolderCopy 
        onClick={writeTextToClipboard}
        size={20}
        className="cursor-pointer mb-2"
        />
        </h5> 
        <h5 className="text-[red] text-[14px] font-[500]">Please save this order_id, it will be requested if there's an error with this order.</h5><br />

        <Link to={`${invoiceUrl}`} target="_blank">
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}>
         Pay 
        </h5>
        </Link>
        </div>
        <br />
        <h5>Wait atleast a minute after the payment has marked 'Payment is paid' before clicking the Completed button.</h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={confirmCPaymentInfo}
        >
         Completed 
        </h5>
        </>
            }
            
        

      </div>
    </div>
    )}


      {openBank && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div className={`w-[95%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
          <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenBank(false)}
                className="cursor-pointer"
              />
            </div>
          <div className="w-full border-b justify-center items-center text-center">
            <h2 className="text-[25px] font-[500] mb-3">(U.S./UK) residence only</h2>
            <Link to="https://wa.me/447418375846" target="_blank"><h5 className="text-[green] cursor-pointer">Get Details</h5></Link>
          </div>  
          </div>
          
        </div>
      )}
 
    </div>
        
        










        </div>
        <br />
        <br />
       
      </div>
    )}
    </>
  )
}

export default OPS