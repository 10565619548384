import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createDJob } from "../../redux/actions/djob";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import styles from '../../styles/styles';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Country } from "country-state-city";

const CreateJob = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.djobs);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const showImageButton = false;
  const [name, setName] = useState("");
  const [btdescription, setBTDescription] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [taskPayment, setTaskPayment] = useState(0);
  const [taskDuration, setTaskDuration] = useState(0);
  const [durationMode, setDurationMode] = useState("");
  const [deadLine, setDeadLine] = useState("");
  const [tools, setTools] = useState("");
  const [requiredSkills, setRequiredSkills] = useState("");
  const [taskEnvironment, setTaskEnvironment] = useState("");
  const [resourceLink1, setResourceLink1] = useState("");
  const [resourceLink2, setResourceLink2] = useState("");
  const [resourceLink3, setResourceLink3] = useState("");
  const [targetedCountry, setTargetedCountry] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Job created successfully!");
      navigate("/dashboard-job");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

    const handleSubmit = (e) => {
    e.preventDefault();
    
    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("btdescription", btdescription);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("taskPayment", taskPayment);
    newForm.append("taskDuration", taskDuration);
    newForm.append("durationMode", durationMode);
    newForm.append("deadLine", deadLine);
    newForm.append("tools", tools);
    newForm.append("requiredSkills", requiredSkills);
    newForm.append("taskEnvironment", taskEnvironment);
    newForm.append("resourceLink1", resourceLink1);
    newForm.append("resourceLink2", resourceLink2);
    newForm.append("resourceLink3", resourceLink3);
    newForm.append("targetedCountry", targetedCountry);
    newForm.append("shopId", seller._id);
    dispatch(
      createDJob({
        name,
        btdescription,
        description,
        category,
        taskPayment,
        taskDuration,
        durationMode,
        deadLine,
        tools,
        requiredSkills,
        taskEnvironment,
        resourceLink1,
        resourceLink2,
        resourceLink3,
        targetedCountry,
        shopId: seller._id,
        images
      })
    );
  };

  return (
    <div className="w-full flex flex-col items-center py-1 px-5">
      <h5 className="text-[30px] font-Poppins 800px:text-[center] text-center">Post Direct Job</h5>
      
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
      {/* create job form */}
      <div className="w-full 800px:w-[65%]">
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Job Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="enter task tiltle..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
          Brief Job Description (Everyone will see this) <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="4"
            type="text"
            name="description"
            value={btdescription}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setBTDescription(e.target.value)}
            placeholder="Enter your task description..."
          ></textarea>
        </div>
        
        <br />
        <div>
          <label className="pb-2">
            Full Job Description (Only the job accepter can see this) <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your task description..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Job Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose job category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">Job Payment $ <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={taskPayment}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskPayment(e.target.value)}
            placeholder="Enter task payment..."
          />
        </div>
        <br />

        
        <div className="flex">
        <div>
          <label className="pb-2">job Duration <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="taskDuration"
            value={taskDuration}
            className="mt-2 appearance-none block w-[100px] px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskDuration(e.target.value)}
            placeholder="Enter task duration..."
          />
          <div className="mt-1">
                              <select
                              name="durationMode"
                              id="durationMode"
                              onChange={(e) => setDurationMode(e.target.value)}
                              className="w-[100px] h-[35px] border rounded"
                              >
                              <option value="-">-</option>
                              <option value="Hours">Hours</option>
                              <option value="Days">Days</option>
                              <option value="Weeks">Weeks</option>
                              <option value="Months">Months</option>
                              <option value="Years">Years</option>
                              </select>
                              </div>
        </div>
       </div>

       <br />
        <div>
          <label className="pb-2">Deadline (YYYY-MM-DD) - Please allow for atleast a day </label>
          <input
            type="text"
            name="deadline"
            value={deadLine}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDeadLine(e.target.value)}
            placeholder="Enter deadline..."
          />
        </div>
                  
        
        <br />
        <div>
          <label className="pb-2">Tools </label>
          <input
            type="text"
            name="tools"
            value={tools}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTools(e.target.value)}
            placeholder="Enter tools..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2">Required Skills (enter skills separated by a commer) <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="requiredSkills"
            value={requiredSkills}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setRequiredSkills(e.target.value)}
            placeholder="Enter required skills..."
          />
        </div>
        <br />

        <br />
        <div>
              <label
                htmlFor="taskEnvironment"
              >
                <span className="text-[16px] text-[#000]">Job Environment</span> <span className="text-red-500">*</span>
              </label>
                              <div className="mt-1">
                              <select
                              name="taskEnvironment"
                              id="taskEnvironment"
                              onChange={(e) => setTaskEnvironment(e.target.value)}
                              className="w-[200px] h-[35px] border rounded"
                              >
                              <option value="-">-</option>
                              <option value="Online">Online</option>
                              <option value="Client's Home">Client's Home</option>
                              <option value="Onsite">Onsite</option>
                              <option value="Online and Onsite">Online and Onsite</option>
                              </select>
                              </div>
             </div>
        <br />

        <h2 className="text-[16px] font-[500]">Links to the Resource/Materials that will be used for the job</h2>
        <p>Only the person that accepted the job can see this</p>
        <br />
        <div>
          <label className="pb-2"> Resource Link 1
          </label>
          <input
            type=" resourceLink1"
            name=" resourceLink1"
            value={resourceLink1}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setResourceLink1(e.target.value)}
            placeholder="Enter link..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2"> Resource Link 2
          </label>
          <input
            type=" resourceLink2"
            name=" resourceLink2"
            value={resourceLink2}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setResourceLink2(e.target.value)}
            placeholder="Enter link..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2"> Resource Link 3
          </label>
          <input
            type=" resourceLink3"
            name=" resourceLink3"
            value={resourceLink3}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setResourceLink3(e.target.value)}
            placeholder="Enter link..."
          />
        </div>
        <br />
        
        <div>
              <label
                htmlFor="targetedCountry"
              >
                <span className="text-[16px] text-[#000]">Targeted Country</span> (Choose "Worldwide" if you wnat your job to be open to all countries") <span className="text-red-500">*</span>
              </label>
                  <div className="mt-1">
                  <select
                      name=""
                      id=""
                      value={targetedCountry}
                      onChange={(e) => setTargetedCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                       Choose Country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                        <option value="Worldwide">Worldwide</option>
                    </select>
                  </div>
              <h6
               className="block text-sm font-medium text-gray-500"
              >
                Country Not Listed and you want us to add it? send an email to us at <span className="text-blue-500">news.totlesoft@gmail.com</span>
              </h6>
            </div>

        <div>
          {showImageButton && 
          <div>
            <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
            </div>}
          

          <br />
          
            <div>
                <button
                onClick={handleSubmit}
                className={`${styles.button} !h-[42px] text-white`}
                >Post Job</button>
            </div>
                  
        </div>

         </form>
         
         </div>
         

         
         

         </div>
    </div>
    );
};

export default CreateJob;
