import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";

function RWHome() {
    const { user } = useSelector((state) => state.user);

  return (
    <div className='w-full'>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        
        <div className='className={`${styles.section} w-full 800px:w-[50%]'>
        <h1
          className={`!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          Re<span className="text-[#c89b2a]">Wards</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        We reward creativity and hard work at TotleSoft to improve workers productivity and eagerness to work, which breeds faster and better results every time. Higher Earnings is also guaranteed as a worker get improved.  
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        TotleSoft remain the great platform with lots of opportunities. Checkout our flexible and tranparent earning structure with lots of bonuses to get you up on your feet...
        </p>
        
       {user ? (
          <Link to="/faq" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Get Help
               </span>
          </div>
      </Link>
        ):(
          <Link to="/sign-up" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 Get Started
                 </span>
            </div>
        </Link>
        )}
        <br />
        <br />
        </div>

        <div className='w-full 800px:w-[50%]'>
        <br />
        <img
            src="https://res.cloudinary.com/totlesoft/image/upload/v1712330594/freelancer1_vajsy8.jpg"
            alt=""
            className="w-[90%] p-3"
          />
        <h5 className='text-[14px] ml-2'>"For the things we have to learn before we can do them, we learn by doing them" - Aristotle</h5>
        </div>
        </div>


        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className='className={`${styles.section} w-full 800px:w-[50%]'>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         General Earning Flow/Structure
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500]">Task Earning</span> - Cash earned for completing tasks <br />
        * <span className="font-[500]">Job Earning</span> - Cash earned for completing jobs <br />
        * <span className="font-[500]">Project Earning</span> - Cash earned for participating in projects <br />
        * <span className="font-[500]">Ref Bonus</span> - Token earned for refferers <br />
        * <span className="font-[500]">Affiliate Commision</span> - Cash earned for product sales with your APLink <br />
        * <span className="font-[500]">Creator Earning</span> - Token earned for art works <br />
        * <span className="font-[500]">Rewards</span> - Token earned for reaching certain milestone on TotleSoft Platform<br />
        </p>
        <br />
        </div>
        </div>

    </div>
  )
}

export default RWHome
