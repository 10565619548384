import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const ShippingTerms = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Shipping 
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Totlesoft has both digital and physical products, with vendors scattered across the globe and as such, 
        shipping/delivery time may differ for cities and states. The above delivery timelines are for Orders placed locally before 2pm. Order placement after 2pm is +1 day added to the delivery timelines. This however excludes Totlesoft Express orders placed to local address in same city and state with a cut off time of 8pm. That is, only Totlesoft Express orders placed after 8pm in such locations will have the additional +1 day added to the delivery timelines. For delivery of large items, please use timelines quoted for Other Cities.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        Others as stated in the email sent to you are categorized as Other Cities and as such , have longer delivery timelines: 
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        The timelines quoted are business days - Monday to Friday only, and do not include weekends. Excluding Totlesoft Express and Standard Shipping Orders also to local cities where we have full delivery operations on Saturdays. The delivery timelines vary depending on the delivery option chosen.
        </p><br />

        <h2 className="pt-5 text-[25px] font-[Poppins] font-[600] text-[#000000ba]">DELIVERY METHODS</h2>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">1. Door Delivery: Delivery time starts from the day you place your order to the day one of the delivery associates of the vendor in question makes a first attempt to deliver to you. Delivery will be attempted 2 times over 5 days (7.00 am to 5.30pm ) after which the item will be cancelled.</p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">2. Pickup Stations: Delivery time starts from the day you place your order to when you receive the first SMS to pick up your order from designated pickup station. Please note: Ensure you pickup your item within 5 days, otherwise it will be cancelled.</p>
        <br />

        <h2 className="pt-5 text-[25px] font-[Poppins] font-[600] text-[#000000ba]">SHIPPING SERVICES</h2>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">Totlesoft Express: Products are stored by each vendors and the Totlesoft Marketing Team (TMT) always ensures they are shipped quicker. To find out more about Totlesoft Express send an email to info.totlesoft@gmail.com or use our contact form.</p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">Standard Shipping: Products are held in stock with sellers and shipped once details get to us. The tracking is also updated appropraitely.</p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">Shipping from/to Overseas: Products shipping from/to overseas have special prefferences. To find out more, send us an email at info.totlesoft@gmail.com</p>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">Nigerian Postal Service: Nigerian Postal Service is a new shipping service for Totlesoft Global items, delivered through local postal service providers. To find out more, also send us an email at info.totlesoft@gmail.com</p>
        
        <Link to="/products" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Shop Now
                 </span>
            </div><br /><br />
        </Link>
      </div>
    </div>
  );
};

const ShippingPage = () => {
    return (
      <div>
        <Header activeHeading={5} />
        <ShippingTerms />
        <Footer />
      </div>
    );
  };

export default ShippingPage;


/*

style={{
        backgroundImage:
          "url(https://themes.rslahmed.dev/rafcart/assets/images/banner-2.jpg)",
      }}

      */