import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function Graphics() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

    return (
    <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
      <br />
      <br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-15px`}
        >
          {t('grph_title')}
        </h1>

        <div className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        <p>
        {t('grph_p1')}
        </p>
        <br />
        <p>
        {t('grph_p2')}
        </p>
        <br />
        <p className="font-[600]">{t('grph_p3t')}</p>
        <p>{t('grph_p3')}</p>
          
          <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('grph_Connect1')}
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <p className="font-[600]">{t('grph_p4t')}</p>
        <p>{t('grph_p4')}</p>
          <br />
          <br />

          <div className="block items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://totlesoft.vercel.app/static/media/gp5.4bb3053e3ed21be2f162.png"
            alt=""
            className="w-[90%] mb-[15px]"
          />
          <img
            src="https://totlesoft.vercel.app/static/media/gp7.2ead0ecf451dea2de0e5.png"
            alt=""
            className="w-[90%] mb-[15px]"
          />
          <img
            src="https://totlesoft.vercel.app/static/media/gp4.d6c1c9c43ea129aa7e2d.png"
            alt=""
            className="w-[90%] mb-[15px]"
          />
          <img
            src="https://totlesoft.vercel.app/static/media/gp6.1332077019d36f3188b0.png"
            alt=""
            className="w-[90%] mb-[15px]"
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('grph_p5t')}</p>
        <p>{t('grph_p5')}</p>
        <br />
        <br />

        <p className="font-[600]">{t('grph_p6t')}</p>
        <p>
        {t('grph_p6')}
        </p>
        <br />
        <br />

        <p className="font-[600]">{t('grph_p7t')}</p>
        <p>{t('grph_p7')}</p>
        <br />
        <br />


        <p className="font-[600]">{t('grph_p8t')}</p>
        <p>
        {t('grph_p8')}
        </p>
        <br />
        <br />

        <p className="font-[600]">{t('grph_lc')}</p>


        <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                {t('grph_Connect2')}
                 </span>
            </div>
        </Link>
        <br />
        <br />

        <p className="font-[600]">{t('Have_a_job')}</p>


        <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('submit_job')}
                 </span>
            </div>
        </Link>
        <br />
        <br />


        </div>
        <br />
        <br />
       
      </div>
  )
}

export default Graphics