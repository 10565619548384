import React from 'react'
import UserTransferDashboardHeader from '../components/UserTransferDashboardHeader';
import UserTransferMoney from "../components/UserTransferMoney";
import UserTransferDashboardSideBar from "../components/UserTransferDashboardSideBar";

const UserTransferMoneyPage = () => {
  return (
    <div>
    <UserTransferDashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <UserTransferDashboardSideBar active={2} />
      </div>
       <UserTransferMoney />
    </div>
  </div>
  )
}

export default UserTransferMoneyPage