import React, { useEffect } from "react";
import workerPic from "../Assests/images/workerPic.jpg"
import clientPic from "../Assests/images/clientPic.jpg"
import affiliatePic from "../Assests/images/affiliatePic.jpg"
import codevPic from "../Assests/images/codevPic.jpg"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const HorizontalCardNew = () => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

     
  return (
    <div className="bg-[#000] px-10 py-12 grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] my-12">
        
        <div className="w-full min-h-[600px] bg-[#242424] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <Link to="/profile">
          <img
            src={workerPic}
            alt="worker"
            className="w-full object-contain pt-2 saturate-[1%]"
          />
        </Link>
        <Link to="/profile">
          <h4 className="py-1 px-3 font-[500] text-[#fff] text-[25px]">
           {t('worker_h')}
          </h4>
            <h5 className="px-3 text-[16px] text-[#fff]">
            {t('worker_b')}
           </h5>         
          <div className="py-2 px-3 flex items-center justify-between">
           <span className="font-[400] text-[16px] text-[#ffe135]">
              {t('worker_d')}
            </span>
          </div>
        </Link>
        </div>


        <div className="w-full min-h-[600px] bg-[#242424] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <Link to="/profile">
          <img
            src={clientPic}
            alt="client"
            className="w-full object-contain pt-2 saturate-[1%]"
          />
        </Link>
        <Link to="/dashboard">
          <h4 className="py-1 px-3 font-[500] text-[#fff] text-[25px]">
            {t('client_h')}
          </h4>
            <h5 className="px-3 text-[16px] text-[#fff]">
            {t('client_b')}
           </h5>         
          <div className="py-2 px-3 flex items-center justify-between">
           <span className="font-[400] text-[16px] text-[#ffe135]">
            {t('client_d')}
            </span>
          </div>
        </Link>
        </div>


        <div className="w-full min-h-[600px] bg-[#242424] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <Link to="/profile">
          <img
            src={affiliatePic}
            alt="affiliate"
            className="w-full object-contain pt-2 saturate-[1%]"
          />
        </Link>
        <Link to="/profile">
          <h4 className="py-1 px-3 font-[500] text-[#fff] text-[25px]">
            {t('affiliate_h')}
          </h4>
            <h5 className="px-3 text-[16px] text-[#fff]">
            {t('affiliate_b')}
           </h5>         
          <div className="py-2 px-3 flex items-center justify-between">
           <span className="font-[400] text-[16px] text-[#ffe135]">
            {t('affiliate_d')}
            </span>
          </div>
        </Link>
        </div>



        <div className="w-full min-h-[600px] bg-[#242424] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <Link to="/cvendors-application">
          <img
            src={codevPic}
            alt="code vendor"
            className="w-full object-contain pt-2 saturate-[1%]"
          />
        </Link>
        <Link to="/cvendors-application">
          <h4 className="py-1 px-3 font-[500] text-[#fff] text-[25px]">
           {t('cvendor_h')}
          </h4>
            <h5 className="px-3 text-[16px] text-[#fff]">
           {t('cvendor_b')}
           </h5>         
          <div className="py-2 px-3 flex items-center justify-between">
           <span className="font-[400] text-[16px] text-[#ffe135]">
            {t('cvendor_d')}
            </span>
          </div>
        </Link>
        </div>
        

    </div>
          
    );
};

export default HorizontalCardNew;
