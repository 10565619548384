import React, { useEffect, useState } from "react";
//import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import VideoTaskDetails from "../components/VideoTasks/VideoTaskDetails";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import Spinner from "../components/Spinner/Spinner";


const VideoTaskDetailsPage = () => {
  const [allVideoTasks, setAllVideoTasks] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/videotask/get-all-videotasks`, {withCredentials: true}).then((res) => {
      setAllVideoTasks(res.data.videotasks);
        setIsLoading(false);
    })
  }, []);
  

    const data = allVideoTasks && allVideoTasks.find((i) => i._id === id);
      
   
 
  return (
    <>
    {isLoading ? 
    <Spinner /> : (
        <div>
      <Header />
      {data ? (
        <VideoTaskDetails data={data} />
      ) : 
        <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500]">Ops! Loading taking longer!</h1>
          <p>Please try again later.</p>
        </div>
     }
     
    </div>
    )}
    </>
    
  );
};

export default VideoTaskDetailsPage;
