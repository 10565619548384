import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";

const UserWComm = () => {
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [convertPoint, setConvertPoint] = useState(5);
 const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const commissionBalance = user?.commissionBalance.toFixed(4); 
  const email = user?.email;
  const amount = Number(convertPoint);
  const converterUserId = user?._id;

     
  const comConvertHandler = async (e) => {
    e.preventDefault();
    
   
    if (convertPoint > commissionBalance) {
      return toast.error("Insuficient Points!");
    } else {
       setIsLoading(true);
      await axios
        .post(
          `${server}/convert/convert-request-user`,
          { amount, email, converterUserId, password },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          toast.success("Conversion successful!");
          window.location.reload();
        })
        .catch((error) => {
            setIsLoading(false);
            toast.error("Wrong password!");
          });
    }
  };

   
  return (
    <div className="w-full h-[90vh] p-8">
      {isLoading ? <Spinner /> 
      : (
        <>
        <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
        <div className="800px:w-[30%] 800px:h-[300px] bg-[#F6F6F5] w-[90%] h-[70%] rounded flex items-center justify-center flex-col">
        <h5 className="text-[18px] text-[blue]">
          Commission Points: 
        </h5>
        <h2 className="text-[25px] font-[600] pb-1">
        {commissionBalance}
        </h2>
        <div
          className={`${styles.button} text-white !h-[42px] !rounded`}
          onClick={() => (commissionBalance < 5 ? toast.error("Points too low!") : setOpen(true))}
        >
          Convert
        </div>
        <Link to="/profile">
        <h5 className="text-[18px] text-[blue] pt-1">
         Cancel 
        </h5>
        </Link>
        
       </div>
       </div>
      {open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          
          <div
            className={`w-[95%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}
          >
                   
                
                   <form onSubmit={comConvertHandler}>
                    <div>
                    <br />
                    <h5 className="text-[23px] text-[blue]">Points:</h5>
                    <h4 className="text-[35px] font-[600]">{commissionBalance}</h4>
                    <br />
                    
                    <div className="800px:flex w-full items-center">
                    <label className="text-[16px]">Points:- </label>
                      <input
                        type="number"
                        placeholder="points..."
                        required
                        value={convertPoint}
                        onChange={(e) => setConvertPoint(e.target.value)}
                        className="800px:w-[full] w-[full] border 800px:mr-3 p-1 rounded"
                      />
                      </div>
                      <br />

                      <div className="800px:flex w-full items-center">
                    <label className="text-[16px] text-[blue]">USD Equivalent:- </label>
                      <h5 className="800px:w-[full] w-[full] border 800px:mr-3 p-1 rounded text-[blue]">
                        ${amount}
                      </h5>
                      </div>
                      <br />

                      <div className="800px:flex w-full items-center">
                    <label className="text-[16px]">Email:- </label>
                      <h5 className="800px:w-[full] w-[full] border 800px:mr-3 p-1 rounded">
                        {email}
                      </h5>
                      </div>
                      <br />

                      <div>
                    <label className="block text-sm font-medium text-gray-700">Password:-</label>
                    <div className="mt-1 relative">
                      <input
                        type={visible ? "text" : "password"}
                        placeholder="enter password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
                </div>
                      </div>
                      <br />

                      <button
                        className={`${styles.button} !h-[42px] text-white`}
                        type="submit"
                      >
                        Convert
                      </button>
                    </div>
                    <Link to="/profile">
                      <button
                        className={`${styles.button} !h-[42px] text-white mt-5`}
                       >
                        Go Back
                      </button>
                      </Link>
                  </form>
          </div>
          
        </div>
      )}
        </>
      )}
      
    </div>
  );
};

export default UserWComm;
