import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const djobReducer = createReducer(initialState, {
  djobCreateRequest: (state) => {
    state.isLoading = true;
  },
  djobCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.djob = action.payload;
    state.success = true;
  },
  djobCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //load djob
  LoadDJobRequest: (state) => {
    state.isLoading = true;
  },
  LoadDJobSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.isLoading = false;
    state.djob = action.payload;
  },
  LoadDJobFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // get all djobs
  getAllDJobsRequest: (state) => {
    state.isLoading = true;
  },
  getAllDJobsSuccess: (state, action) => {
    state.isLoading = false;
    state.allDJobs = action.payload;
  },
  getAllDJobsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },

  // get all djobs of shop
  getAllDJobsShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllDJobsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.djobs = action.payload;
  },
  getAllDJobsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete djob of a shop
  deleteDJobRequest: (state) => {
    state.isLoading = true;
  },
  deleteDJobSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteDJobFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },


});

