import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const usedcodeReducer = createReducer(initialState, {
  // get all usedcodes
  getAllUsedCodesUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllUsedCodesUserSuccess: (state, action) => {
    state.isLoading = false;
    state.usedcodes = action.payload;
  },
  getAllUsedCodesUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all newcodes for admin
  adminAllUsedCodesRequest: (state) => {
    state.adminUsedCodesLoading = true;
  },
  adminAllUsedCodesSuccess: (state, action) => {
    state.adminUsedCodesLoading = false;
    state.adminUsedCodes = action.payload;
  },
  adminAllUsedCodesFailed: (state, action) => {
    state.adminUsedCodesLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
