import axios from "axios";
import { server } from "../../server";

// load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadUserRequest",
    });
    const { data } = await axios.get(`${server}/user/getuser`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "LoadUserFail",
      payload: error.response.data.message,
    });
  }
};



// user update information
export const updateUserInformation = (
    name, 
    email, 
    phoneNumber, 
    password, 
    aboutMe,
    spotLight,
    potfolioURL,
    githubURL,
    linkedinURL,
    facebookUserName, 
    instagramUserName, 
    twitterUserName, 
    tiktokUserName, 
    youtubeUserName, 
    telegramUserName,
    trustpilotUserName,
    googleUserName,
    boomplayUserName,
    spotifyUserName,
    soundcloudUserName,
    dateOfBirth,
    gender,
    maritalStatus,
    educationLevel,
    numbersOfChildren,
    language,
    devices,
    operatingSystem,
    deviceBrand,
    deviceRAMSize,
    smartphoneBrand,
    smartphoneOperatingSystem,
    smartphoneOperatingSystemVersion,
    idType,
    idNumber,
    wmPaypal,
    wmCrypto,
    wmBankTransfer,
    wmPayoneer,
    wmGiftCard,
    ) => async (dispatch) => {
    try {
      dispatch({
        type: "updateUserInfoRequest",
      });

      const { data } = await axios.put(
        `${server}/user/update-user-info`,
        {
          email,
          password,
          phoneNumber,
          name,
          aboutMe,
          spotLight,
          potfolioURL,
          githubURL,
          linkedinURL,
          facebookUserName, 
          instagramUserName, 
          twitterUserName, 
          tiktokUserName, 
          youtubeUserName, 
          telegramUserName,
          trustpilotUserName,
          googleUserName,
          boomplayUserName,
          spotifyUserName,
          soundcloudUserName,
          dateOfBirth,
          gender,
          maritalStatus,
          educationLevel,
          numbersOfChildren,
          language,
          devices,
          operatingSystem,
          deviceBrand,
          deviceRAMSize,
          smartphoneBrand,
          smartphoneOperatingSystem,
          smartphoneOperatingSystemVersion,
          idType,
          idNumber,
          wmPaypal,
          wmCrypto,
          wmBankTransfer,
          wmPayoneer,
          wmGiftCard,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      dispatch({
        type: "updateUserInfoSuccess",
        payload: {
          successMessage: "Profile updated!",
          user: data.user,
        },
      });
    } catch (error) {
      dispatch({
        type: "updateUserInfoFailed",
        payload: error.response.data.message,
      });
    }
  };


  // user update tax info
export const updateUserTaxInfo = (
            email,
            legalName,
            taxCountry,
            taxClass,
            dateOfBirth,
            taxPerson,
            taxStreet,
            taxCity,
            taxState,
            taxAddressCountry,
            taxPostalCode,
            taxIN,
            taxCertificateSigner,
            vatID,
            statementOfSource,
  ) => async (dispatch) => {
  try {
    dispatch({
      type: "updateUserTaxInfoRequest",
    });

    const { data } = await axios.put(
      `${server}/user/update-user-tax-info`,
      {
            email,
            legalName,
            taxCountry,
            taxClass,
            dateOfBirth,
            taxPerson,
            taxStreet,
            taxCity,
            taxState,
            taxAddressCountry,
            taxPostalCode,
            taxIN,
            taxCertificateSigner,
            vatID,
            statementOfSource,
      },
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
        },
      }
    );

    dispatch({
      type: "updateUserTaxInfoSuccess",
      payload: {
        successMessage: "Tax Info updated!",
        user: data.user,
      },
    });
  } catch (error) {
    dispatch({
      type: "updateUserTaxInfoFailed",
      payload: error.response.data.message,
    });
  }
};


// update user address
export const updatUserAddress =
  (country, statee, city, address1, address2, zipCode, addressType) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "updateUserAddressRequest",
      });

      const { data } = await axios.put(
        `${server}/user/update-user-addresses`,
        {
          country,
          statee,
          city,
          address1,
          address2,
          zipCode,
          addressType,
        },
        { withCredentials: true }
      );

      dispatch({
        type: "updateUserAddressSuccess",
        payload: {
          successMessage: "User address updated succesfully!",
          user: data.user,
        },
      });
    } catch (error) {
      dispatch({
        type: "updateUserAddressFailed",
        payload: error.response.data.message,
      });
    }
  };

// delete user address
export const deleteUserAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteUserAddressRequest",
    });

    const { data } = await axios.delete(
      `${server}/user/delete-user-address/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: "deleteUserAddressSuccess",
      payload: {
        successMessage: "User deleted successfully!",
        user: data.user,
      },
    });
  } catch (error) {
    dispatch({
      type: "deleteUserAddressFailed",
      payload: error.response.data.message,
    });
  }
};

// get all users --- admin
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersRequest",
    });

    const { data } = await axios.get(`${server}/user/admin-all-users`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFailed",
      payload: error.response.data.message,
    });
  }
};

// get all users --- Open
export const getAllUsersOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersOpenRequest",
    });

    const { data } = await axios.get(`${server}/user/open-all-users`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllUsersOpenSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersOpenFailed",
      payload: error.response.data.message,
    });
  }
};
