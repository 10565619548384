import React from "react"
import Graphics from "../components/Graphics/Graphics";
import Header from "../components/Layout/Header";

const GraphicsPage = () => {
  return (
    <div>
      <Header />
      <Graphics />
      </div>
  );
};


export default GraphicsPage 

