import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { TbReportMoney } from "react-icons/tb";
import { RxPerson } from "react-icons/rx";
import { Link } from "react-router-dom";
import YoutubeEmbed from "../components/YoutubeEmbed";
import "../components/YoutubeEmbed.css";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const FAQPage = () => {
  
  return (
    <div>
      <Header />
      <Faq />
      </div>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  
  
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className={`${styles.section} my-8`}>

      <div className="mx-15">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('faq_hv')}</h2>
      

      

      </div><br /><hr /><br />

      <div className="mx-15">
      <h2 className="text-3xl font-bold text-gray-900">{t('live_c')}</h2>
      <h5 className="text-[18px] font-[500]">{t('kindly_c')}</h5>
      <Link to="https://wa.me/447418375846">
            <button 
            className={`${styles.button} text-[#fff] justify-center items-center text-center`}
            >
               {t('start_c')}
              </button>
            </Link>
      </div><br /><hr /><br />

      <div className="mx-15">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('faq')}</h2>
      </div>

    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('worker')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(2)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('w_is_a_worker')}
            </span>
            {activeTab === 2 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 2 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
               {t('wp1')}
               <br /><br />
               {t('wp2a')} <Link to="/code-vendors"><span className="text-[blue] text-lg font-medium text-900">{t('wp2b')} </span></Link>
               {t('wp2c')} 
              </p>
              <br />
              <p className="text-base text-gray-500">
              {t('wp3a')} <span>  <RxPerson size={20} color="blue" /> </span> {t('wp3b')}
              </p>
              <br />
              <p className="text-base text-gray-500">
              {t('wp4a')} <span>  <TbReportMoney size={20} color="blue" /> </span> {t('wp4b')}
              </p>
              </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(3)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('c_i_reg')}
            </span>
            {activeTab === 3 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 3 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('yes_but')} 
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(4)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('can_i_have')}
            </span>
            {activeTab === 4 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 4 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('no')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start text-start items-start justify-between w-full"
            onClick={() => toggleTab(5)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('got_an_err')}
            </span>
            {activeTab === 5 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 5 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('this_happen')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(6)}
          >
            <span className="text-lg font-medium text-gray-900">
             {t('Can_i_r_do')} 
            </span>
            {activeTab === 6 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 6 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('No!')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(7)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('W_is_acct')}
            </span>
            {activeTab === 7 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 7 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('Perhaps_a')}   
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(8)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('I_cant')}
            </span>
            {activeTab === 8 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 8 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('if_you')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>


    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('Client')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(9)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp1t')}
            </span>
            {activeTab === 9 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 9 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
               {t('cp1')}
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp2')}
              </p> <br />
              <p className="text-base text-gray-500">
                {t('cp3')} 
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp4')}
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp5')}
              </p>
              
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(10)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp6')}
            </span>
            {activeTab === 10 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 10 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp7')}
                <br /><br />
                {t('cp7b')} 
              </p>
            </div>
          )}
        </div>
      </div>
    </div>


    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('Shipping')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(11)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('r_policy')}
            </span>
            {activeTab === 11 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 11 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">{t('if_you_not_satisfy')}</p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(12)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('h_track_order')}
            </span>
            {activeTab === 12 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 12 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('order_tracking')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(13)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp8')}
            </span>
            {activeTab === 13 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 13 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp9')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(14)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp10')}
            </span>
            {activeTab === 14 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 14 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp11')} 
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(15)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp12')}
            </span>
            {activeTab === 15 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 15 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp13')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(16)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp14')}
            </span>
            {activeTab === 16 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 16 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp15')}  
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(17)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp16')}
            </span>
            {activeTab === 17 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 17 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp17')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>


    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('cp18t')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(18)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp18')}
            </span>
            {activeTab === 18 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 18 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
              {t('cp19a')}
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp19b')}
              </p><br /> 
              <p className="text-base text-gray-500">
                {t('cp19c')}
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp19d')}
              </p>
              
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(19)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp20')}
            </span>
            {activeTab === 19 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 19 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp21')} 
              </p>
            </div>
          )}
        </div>
      </div>
    </div>

    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('cp22t')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(20)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp22')}
            </span>
            {activeTab === 20 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 20 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
               {t('cp23')} 
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp24a')} <span className="text-base text-blue-500">{t('cp24b')}</span>
              </p><br /> 
              <p className="text-base text-gray-500">
                {t('cp25')}
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp26')}
              </p>
              
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(21)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp27')}
            </span>
            {activeTab === 21 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 21 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp28a')} <span  className="text-base text-blue-500">{t('cp28b')}</span> {t('cp28c')} <br />
                {t('cp28d1')} <br />
                {t('cp28d2')} <br />
                {t('cp28d3')}<br />
                {t('cp28d4')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>



    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('general')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(22)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp29')}
            </span>
            {activeTab === 22 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 22 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
               {t('cp30a')} <span className="text-base text-blue-500">{t('cp30b')}</span> {t('cp30c')} 
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp301')}<br />
                {t('cp302')}<br />
                {t('cp303')}
              </p><br /> 
              <p className="text-base text-gray-500">
                {t('cp31')}
              </p><br />
              <p className="text-base text-gray-500">
                {t('cp32')}
              </p>
              
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(23)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp33')}
            </span>
            {activeTab === 23 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 23 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp34a')} <span  className="text-base text-blue-500">{t('cp34b')}</span> {t('cp34c')} <br />
                {t('cp341')} <br />
                {t('cp342')} <br />
                {t('cp343')}<br />
                {t('cp344')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(24)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t('cp35')}
            </span>
            {activeTab === 24 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 24 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                {t('cp36a')} <span  className="text-base text-blue-500">{t('cp36b')}</span> {t('cp36c')} <br />
                {t('cp361')} <br />
                {t('cp362')} <br />
                {t('cp363')}<br />
                {t('cp364')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>



    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('digital_products')}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(25)}
          >
            <span className="text-lg font-medium text-gray-900">
            {t('cp37')}
            </span>
            {activeTab === 25 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 25 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
              {t('cp38a')}<br />
              {t('cp38b')} 
               <span className="text-base text-blue-500"> {t('cp38c')}</span> {t('cp38d')}  
              </p><br />
             </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(26)}
          >
            <span className="text-lg font-medium text-gray-900">
            {t('cp39')}
            </span>
            {activeTab === 26 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 26 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
              {t('cp40')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(27)}
          >
            <span className="text-lg font-medium text-gray-900">
            {t('cp41')}
            </span>
            {activeTab === 27 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 27 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
              {t('cp42')}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(28)}
          >
            <span className="text-lg font-medium text-gray-900">
            {t('cp43')}
            </span>
            {activeTab === 28 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 28 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
              {t('cp44a')} <span className="text-base text-blue-500">{t('cp44b')}</span> {t('cp44c')} 
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(29)}
          >
            <span className="text-lg font-medium text-gray-900">
            {t('cp45')}
            </span>
            {activeTab === 29 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 29 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
              {t('cp46')}  
              </p>
              <p className="text-base text-gray-500">
              {t('cp461')}
              </p>
              <p className="text-base text-gray-500">
              {t('cp462')} 
              </p>
              <p className="text-base text-gray-500">
              {t('cp463')} 
              </p>
              <p className="text-base text-gray-500">
              {t('cp46L')} 
              </p>
            </div>
          )}
        </div>
      </div>
    </div>

    </div>
    
  );
};

export default FAQPage;


/*

<h5 className="text-[18px] font-[500]">{t('faq_tv1')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="I3GzncW_hcE" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">{t('faq_tv2')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="iKlxtCigJBE" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">{t('faq_tv3')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="pOXtQJcZe5I" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">{t('faq_tv4')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="Cab01Ke-qWM" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">{t('faq_tv5')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="1FdPrucJqWY" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">{t('faq_tv6')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="u_ERWkqF9HI" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">{t('faq_tv7')}</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="LMRRTq6NS_Q" />
      </div><br /><hr /><br />

      <h5 className="text-[18px] font-[500]">Client Deposit to Payment Balance Guide (TotleSoft-Pay)</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="Gf24fa3UmAU" />
      </div><br />

      */