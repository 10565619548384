import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";
import YoutubeEmbed from "../components/YoutubeEmbed";
import "../components/YoutubeEmbed.css";
import { Link } from "react-router-dom";



const AcademyPage = () => {
  return (
    <div>
        <Header activeHeading={4} />
        <Academy />
        
        <Footer />
    </div>
  )
}

const Academy = () => {
    return (
        <>
    <div className="mt-1 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>

      
        <h1 className={`text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}>
          Totlesoft <br />  <span className="text-[#c89b2a]">Academy</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Take advantage of our free and paid courses bellow to help you land your next 6-figure job or get you started in making your first $1000 online either as a beginner content creator or a professional.
        </p>
        <br />
        
        <div className="w-full 800px:w-[50%]">
        <YoutubeEmbed embedId="c9seyxo2mho" />
        </div><br /><hr /><br />

        <Link to="/academy/courses" className="inline-block">
          <div className={`${styles.buttonRed} !bg-[teal] mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               All Courses
               </span>
          </div>
      </Link>

        </div>
        </div>
        </div>
        </>
    )
}

export default AcademyPage
