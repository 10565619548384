import axios from "axios";
import { server } from "../../server";

// get all usedcodes  
export const getAllUsedCodesOfUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsedCodesUserRequest",
    });

    const { data } = await axios.get(
      `${server}/usedcode/get-all-usedcodes`
    );

    dispatch({
      type: "getAllUsedCodesUserSuccess",
      payload: data.usedcodes,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsedCodesUserFailed",
      payload: error.response.data.message,
    });
  }
};

// get all orders newcodes for Admin
export const getAllUsedCodesOfAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "adminAllUsedCodesRequest",
    });

    const { data } = await axios.get(`${server}/usedcode/get-all-usedcodes-admin`, {
      withCredentials: true,
    });

    dispatch({
      type: "adminAllUsedCodesSuccess",
      payload: data.usedcodes,
    });
  } catch (error) {
    dispatch({
      type: "adminAllUsedCodesFailed",
      payload: error.response.data.message,
    });
  }
};
