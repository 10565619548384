import React from 'react'
import Loader from "../../Assests/images/loader.gif";

function Spinner2() {
  return (
    <div>
        <div className="mx-[50%] items-center justify-center w-full p-3">
          <img
            src={Loader}
            width='80px'
            alt="loading..."
          />
         </div>
    </div>
  )
}

export default Spinner2