import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";
import { DataGrid } from "@mui/x-data-grid";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";

const AllTransfer = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [transferData, setTransferData] = useState();
  const [transferStatus,setTransferStatus] = useState('Processing');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    axios
      .get(`${server}/transfer/get-all-transfer-user`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.transfers);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const columns = [
    { field: "id", 
    headerName: "Transfer Id", 
    minWidth: 150, 
    flex: 0.7 
  },
    {
      field: "sender",
      headerName: "Sender",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "receiver",
      headerName: "Receiver",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      type: "text",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: " ",
      headerName: "Update Status",
      type: "number",
      minWidth: 130,
      flex: 0.6,
      renderCell: (params) => {

        return (
          <BsPencil
            size={20}
            className={`${params.row.status !== "Processing" ? 'hidden' : '' } mr-5 cursor-pointer`}
            onClick={() => setOpen(true) || setTransferData(params.row)}
          />
        );
      },
    },
  ];

  const handleSubmitUser = async () => {
    setIsLoading(true);
    await axios
      .put(`${server}/transfer/update-transfer-request-user/${transferData.id}`,{
        userId: transferData.userId,
      },{withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        toast.success("Transfer request updated!");
        setOpen(false);
        setData(res.data.transfers);
        
      });
  };

  
  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        sender: item.sender,
        receiver: item.receiver,
        amount: "$ " + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

    
  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full flex items-center py-5 px-5 bg-[#ffe135] justify-center">
      <div className="w-[95%] bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
      {open && (
        <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
          <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h1 className="text-[18px] font-Poppins-600 px-3">
              Update Transfer Status
            </h1>
            <br />
            <select
              name="transferStatus"
              id="transferStatus"
              onChange={(e) => setTransferStatus(e.target.value)}
              className="w-[120px] h-[35px] border rounded px-2 mx-2"
            >
              <option value={transferStatus}>{transferData.status}</option>
              <option value={transferStatus}>Approved</option>
            </select>
            <button
              type="submit"
              className={`block ${styles.button} text-white !h-[42px] mt-4 text-[18px]`}
              onClick={handleSubmitUser}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
    )}
    </>
    
  );
};

export default AllTransfer;

/*

const handleSubmitU = async () => {
      setIsLoading(true);
      await axios
        .put(`${server}/transfer/update-transfer-request-user/${transferData.id}`,{
          receiver: transferData.receiver,
          amount: transferData.amount,
          sender: transferData.sender,
          status: transferStatus,
        },{withCredentials: true})
        .then((res) => {
          setIsLoading(false);
          toast.success("Transfer request updated!");
          setData(res.data.transfers);
          setOpen(false);
        });
    };
  
    console.log(transferData.receiver, transferData.amount, transferData.sender, transferStatus, transferData.id);
   

    */
