import axios from"axios";
import { server } from "../../server";

// get all minertasks
export const getAllMinerTasks = () => async (dispatch) => {
    try {
        dispatch({
            type: "getAllMinerTasksRequest",
        });

        const { data } = await axios.get(`${server}/minertask/get-all-minertasks`);
        dispatch({
            type: "getAllMinerTasksSuccess",
            payload: data.minertasks,
        });

    } catch (error) {
        dispatch({
            type: "getAllMinerTasksFailed",
            payload: error.response.data.message,
        });
    }
};

// Load Task
export const loadMinerTask = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${server}/minertask/get-minertask`, {
            withCredentials: true,
        });

        dispatch({
            type: "LoadMinerTaskSuccess",
            payload: data.minertask,
        });
    } catch(error) {
        dispatch({
            type: "LoadMinerTaskFailed",
            payload: error.response.data.message,
        });
    }
}