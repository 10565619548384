import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllTasksShop } from "../../redux/actions/task";
import { deleteTask } from "../../redux/actions/task";
import Spinner from "../Spinner/Spinner";
//import Loader from "../Loader/Loader";

const AllTasks = () => {
  const { tasks, isLoading } = useSelector((state) => state.tasks);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTasksShop(seller._id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteTask(id));
    window.location.reload();
  };

  const columns = [
    { field: "id", headerName: "Task Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Title",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "taskPayment",
      headerName: "Payment",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "taskQuantity",
      headerName: "Task Quantity",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "totalTaskCompleted",
      headerName: "Task Completed",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/task/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  tasks &&
    tasks.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        taskPayment: "$ " + item.taskPayment,
        taskQuantity: item.taskQuantity,
        totalTaskCompleted: item?.totalTaskCompleted,
      });
    });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllTasks;
