import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";

function RCHome() {
    const { user } = useSelector((state) => state.user);

  return (
    <div className='w-full'>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        
        <div className='className={`${styles.section} w-full 800px:w-[50%]'>
        <h1
          className={`!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          Resource <br />  <span className="text-[#c89b2a]">Center</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        It's becoming more clearer that we live in a digital world. The old rules will fail soon. Join the freelance circle and upgrade your relevance. 
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        One great platform with lots of opportunities. Find talents or buy projects as a client. Find tasks or sell project as a worker.
        </p>
        
       {user ? (
          <Link to="/faq" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Get Help
               </span>
          </div>
      </Link>
        ):(
          <Link to="/sign-up" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 Get Started
                 </span>
            </div>
        </Link>
        )}
        <br />
        <br />
        </div>

        <div className='w-full 800px:w-[50%]'>
        <br />
        <img
            src="https://res.cloudinary.com/totlesoft/image/upload/v1712330594/freelancer1_vajsy8.jpg"
            alt=""
            className="w-[90%] p-3"
          />
        <h5 className='text-[14px] ml-2'>"sell the problem you solve not the product you have" -Microsoft</h5>
        </div>
        </div>

        <div className={`${styles.section} w-[90%] 800px:w-[70%] my-4`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          Do it!
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        "It seems like the world is crumbling out there, but it is actually a really great time in your life to get a little crazy, follow your curiosity and be ambitious about it; and when a really great dream shows up, grab it." <br /> -Larry Page (Google) 
        </p>
        <br />
        <br />
        </div>
    </div>
  )
}

export default RCHome