import React, { useState, useEffect } from "react";
import styles from "../styles/styles";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../server";
import axios from "axios";
import Header from "../components/Layout/Header";
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import JobMessageCard from "../components/JobMessages/JobMessageCard";



const ViewJobSamplesPage = () => {
   
   const { id } = useParams();
    const [allDJobs, setAllDJobs] = useState([]);
    const { user } = useSelector((state) => state.user);
    const { seller } = useSelector((state) => state.seller);
    const [clientChatMessage, setClientChatMessage] = useState("");
    const [workerChatMessage, setWorkerChatMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [workStatus, setWorkStatus] = useState('');
    const [workerRating, setWorkerRating] = useState(0)
    const [reason, setReason] = useState("")
    const navigate = useNavigate();
    
    
    useEffect(() => {
   axios.get(`${server}/djob/get-all-djobs`, {withCredentials: true}).then((res) => {
    setAllDJobs(res.data.djobs);
  
  })
}, []);         
       
       const data = allDJobs && allDJobs.find((i) => i._id === id);       
        
        const sampleIsSent = data?.samples;  
        
        const isTrueClient = data?.shopId === seller?._id;
        const isTrueWorker = data?.workers[0].workerId === user?._id;

        const approveHandler = async (e) => {

          const workaEmail = data?.workers[0].workerEmail;

          e.preventDefault();
          await axios
            .put(`${server}/djob/work-on-job-status/${data._id}`,{
              workStatus: workStatus,
              workerRating: workerRating,
              workaEmail: workaEmail,
            },
            {withCredentials: true})
            .then((res) => {
             toast.success("Work Status Updated!");
             navigate('/dashboard-job');
             window.location.reload();
             });
        };


        const rejectHandler = async (e) => {

          const workaEmail = data?.workers[0].workerEmail;

          e.preventDefault();
          await axios
            .put(`${server}/djob/work-on-job-status-rejected/${data._id}`,{
              workStatus: workStatus,
              workerRating: 2,
              workaEmail: workaEmail,
              reason: reason,
            },
            {withCredentials: true})
            .then((res) => {
             toast.success("Work Status Updated!");
             navigate('/dashboard-job');
             window.location.reload();
             });
        };


        const RL1 = data?.resourceLink1;
        const RL2 = data?.resourceLink2;
        const RL3 = data?.resourceLink3;            
              

        const userMHandler = async (e) => {

         e.preventDefault();

         const userMessageData = {
          senderName : "Worker",
          message: workerChatMessage,
        }

          await axios
            .put(`${server}/djob/message-sending-worker/${data._id}`,
              userMessageData,
            {withCredentials: true})
            .then((res) => {
             setWorkerChatMessage("")
             toast.success("message sent!");
            });
        };

        const clientMHandler = async (e) => {

          e.preventDefault();

          const clientMessageData = {
            senderName : "Client",
            message: clientChatMessage,
          }

           await axios
             .put(`${server}/djob/message-sending-client/${data._id}`,
               clientMessageData,
              {withCredentials: true})
             .then((res) => {
              setClientChatMessage("")
              toast.success("message sent!");
             });
         };

        const dataforjobMessages = data && data.jobChatMessages;                        
        
      return (
        <div>
        {data?.workStatus === "Not Started" && (<>
          <div className="w-full py-5 px-10">
            <h1 className="text-[30px] text-[500]">Job Not Yet Accepted by a Freelancer</h1>
            <p>As a client, you will receive an email alert when a freelancer accept the job. Kindly check your email to confirm if a worker has accepted the job and return again later.</p>
          </div>
        </>)}

        {data?.workStatus === "Started" && (<>
          {isTrueWorker || isTrueClient ? (
          <div className="w-full py-5 px-10 justify-center items-center">
          <Header />
         {sampleIsSent !== "" ? 
          <div className="w-full py-5 px-10">
          <h1 className="text-[20px] text-[500]">Job Communication Page</h1><br />
  
          <div className="w-full 800px:flex justify-between">
          <div className="block p-2 w-[95%] 800px:w-[80%]">
            <div className="block 800px:flex 800px:justify-between">
            <div className='mt-2 flex'>
            <img className='mr-2 w-[50px] h-[50px] rounded-full object-cover border-[3px] border-[#fff]' alt='Article cover' src={data?.shop.avatar.url}></img>
            <div>
            <h5 className='text-[16px] text-[blue]'>{data?.shop.name}</h5>
            <div className='800px:flex block mb-3 text-[14px]'>
            <h5 className='mr-2'>Total Spent: ${data?.shop.totalSpent}</h5>
            {data?.shop.isPaymentVerified === "Yes" ? <span className="text-[green] font-[600]">Payment Verified</span> : <span className="text-[red] font-[600]">Payment Unverified</span>}
            </div>
            </div>
            </div>

            <div className='mt-2 flex'>
            <img className='mr-2 w-[50px] h-[50px] rounded-full object-cover border-[3px] border-[#fff]' alt='Article cover' src={data?.workers[0].workerAvatar}></img>
            <div>
            <h5 className='text-[16px] text-[blue]'>{data?.workers[0].workerName}</h5>
            <div className='block 800px:flex mb-3 text-[14px]'>
            <h5 className='mr-2'>Location: {data?.workers[0].workerLocation}</h5>
            <span className="text-[green] font-[600]">online</span>
            </div>
            </div>
            </div>
            </div>
            
            <h4 className="font-[600] text-[16px] bg-[#000] text-[#fff] rounded mb-3 mt-3 w-[270px] px-3 py-1">Refresh to get latest messages</h4>
            <div className="w-full h-[300px] bg-[green] text-[16px] p-4 text-[#fff] font-[400]  overflow-y-scroll">
              
              {dataforjobMessages && dataforjobMessages.length === 0 ? <h1>no message yet...</h1> : (
                <ul>
                {dataforjobMessages && dataforjobMessages.map((it) => <li key={it._id}>
                  <JobMessageCard 
                  senderName={it.senderName}
                  message={it.message}
                  time={it.time}
                  date={it.date}
                  />
                </li>)}
              </ul>
              )}
            
            </div>
            
           
              <>
              <br />
              {isTrueClient && (
                <div>
                <label className="pb-2">
                client chatting...
                </label>
                <textarea
                  cols="5"
                  rows="4"
                  type="text"
                  name="clientMessage"
                  value={clientChatMessage}
                  className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setClientChatMessage(e.target.value)}
                  placeholder="Enter live message..."
                  ></textarea>
                  </div>
              )}
                          
    
                <div className="block 800px:flex 800px:w-[40%] w-full 800px:justify-between">
                {isTrueClient && (
                  <div className={`${styles.button} !w-[80px] mt-5 cursor-pointer`}
                  onClick={clientMHandler}
                  >
                  <span className="text-[#fff] font-[Poppins] !text-[14px]">
                  Send
                  </span>
                </div>
                )}
    
                {isTrueClient && (
                  <div className={`${styles.button} !w-[80px] !bg-[green] mt-5 cursor-pointer`}
                  onClick={() => setOpen(true)}
                  >
                       <span className="text-[#fff] font-[Poppins] !text-[14px]">
                       Approve
                       </span>
                  </div>
                )}
    
                {isTrueClient && (
                  <div className={`${styles.button} !w-[80px] !bg-[red] mt-5 cursor-pointer`}
                  onClick={() => setOpen2(true)}
                  >
                       <span className="text-[#fff] font-[Poppins] !text-[14px]">
                       Reject
                       </span>
                  </div>
                )}
                </div>
                </>

                {isTrueWorker && (
                <div>
                <label className="pb-2">
                worker chatting...
                </label>
                <textarea
                  cols="5"
                  rows="4"
                  type="text"
                  name="clientMessage"
                  value={workerChatMessage}
                  className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setWorkerChatMessage(e.target.value)}
                  placeholder="Enter live message..."
                  ></textarea>
                  </div>
              )}

                <div className="block 800px:flex 800px:w-[40%] w-full 800px:justify-between">
                {isTrueWorker && (
                  <div className={`${styles.button} !w-[80px] mt-5 cursor-pointer`}
                  onClick={userMHandler}
                  >
                  <span className="text-[#fff] font-[Poppins] !text-[14px]">
                  Send
                  </span>
                </div>
                )}

                {isTrueWorker && (
                  <Link to={`/djob/send-samples-worker/${data._id}`}>
                  <div className={`${styles.button} !bg-[]e !w-[150px] mt-5 cursor-pointer`}>
                  <span className="text-[#fff] font-[Poppins] !text-[14px]">
                  Send New Sample
                  </span>
                </div>
                </Link>
                )}
                </div>
          

            {isTrueClient && (
              <>
              {open && (
                <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
                <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
                <div className="flex justify-end w-full">
                  <RxCross1 size={25} onClick={() => setOpen(false)} />
                </div>
                 <h1 className="text-[18px] font-Poppins-600 px-3">
                    Update Job Status
                  </h1>
                  <br />
                  <select
                    name="workStatus"
                    id="workStatus"
                    required
                    onChange={(e) => setWorkStatus(e.target.value)}
                    className="w-[120px] h-[35px] border rounded px-2 mx-2 mb-2"
                  >
                    <option value="-">-</option>
                    <option value="Completed">Approved</option>
                    </select>
    
                    <h1 className="text-[18px] font-Poppins-600 px-3">
                    Rate Worker
                  </h1>
                  <br />
                  <select
                    name="workerRating"
                    id="workerRating"
                    required
                    onChange={(e) => setWorkerRating(e.target.value)}
                    className="w-[120px] h-[35px] border rounded px-2 mx-2"
                  >
                    <option value="-">-</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    </select>
                  <button
                    type="submit"
                    className={`block ${styles.button} text-white !h-[42px] mt-4 text-[18px]`}
                    onClick={approveHandler}
                  >
                    Confirm
                  </button>
                </div>
              </div>
              )}
              </>
            )}

          {isTrueClient && (
            <>
            {open2 && (
            <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
            <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen2(false)} />
            </div>
             <h1 className="text-[18px] font-Poppins-600 px-3">
                Update Job Status
              </h1>
              <br />
              <select
                name="workStatus"
                id="workStatus"
                required
                onChange={(e) => setWorkStatus(e.target.value)}
                className="w-[120px] h-[35px] !bg-[#F6F6F5] border rounded px-2 mx-2 mb-2"
              >
                <option value="-">-</option>
                <option value="Not Started">Reject</option>
                </select>

                <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Reason:</label>
                  <input
                    type="text"
                    className={`${styles.input} !bg-[#F6F6F5] !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
               </div>
               <br />
              <button
                type="submit"
                className={`block ${styles.button} !bg-[red] text-white !h-[42px] mt-4 text-[18px]`}
                onClick={rejectHandler}
              >
                Confirm
              </button>
            </div>
          </div>
          )}
            </>
          )}

            </div>
          </div>

          <br />
          <hr />
          <br />
  
          {isTrueClient && (
            <p className="mt-9">Please follow the link provided by the worker bellow to see job samples and update job status thereafter: <br /> 
            <span className="text-[16px] font-[600]">Job Title: {data?.name}</span><br />
            <span className="text-[16px] font-[600]">Job ID: {data?._id}</span><br /><br />
            <span className="text-[16px] text-[red]">Remember to save this link in other to be able to access the sample folder when the job has been completed and closed.</span>
            </p>
          )}

          {isTrueClient && (
            <div className={`${styles.button} mt-5 cursor-pointer`}>
            <Link to={data?.samples} target="_blank">
            <span className="text-[#fff] font-[Poppins] text-[18px]">
             View
            </span>
            </Link>
          </div>
          )}

            <br />
            <br />
            <br />
          </div>
         : 
          <div className="w-full py-5 px-10">
            {isTrueClient && (
              <h1 className="text-[30px] text-[500]">No Sample(s) submitted yet!</h1>
            )}
            
            {isTrueClient && (
              <p>The worker is currently working on the job and will upload samples soon.</p>
            )}
            <br />
            <br />
            
            {isTrueWorker && (
            <>
            <h1 className="text-[30px] text-[500] mb-3">Job Resource Link(s)</h1>

{RL1 !== "" ? (
  <Link to={data?.resourceLink1} target="_blank" className="inline-block">
  <div className={`${styles.button} mt-5 mr-2`}>
  <span className="text-[#fff] font-[Poppins] text-[18px]">
  Link 1
  </span>
  </div>
  <br />
  </Link>
  ) : null}

{RL2 !== "" ? (
  <Link to={data?.resourceLink2} target="_blank" className="inline-block">
  <div className={`${styles.button} mt-5 mr-2`}>
  <span className="text-[#fff] font-[Poppins] text-[18px]">
  Link 2
  </span>
  </div>
  <br />
  </Link>
  ) : null}

{RL3 !== "" ? (
  <Link to={data?.resourceLink3} target="_blank" className="inline-block">
  <div className={`${styles.button} mt-5 mr-2`}>
  <span className="text-[#fff] font-[Poppins] text-[18px]">
  Link 3
  </span>
  </div>
  <br />
  </Link>
  ) : null}
              </>
            )}
          </div>
       }
       </div>
        ) : null}
        </>)}

        {data?.workStatus === "Completed" && (<>
          <div className="w-full py-5 px-10">
            <h1 className="text-[30px] text-[500]">Job Approved and Closed</h1>
            <p>This job is now completed and clossed by the client.</p>
          </div>
        </>)}
        
        </div>
      );

     }

export default ViewJobSamplesPage;
