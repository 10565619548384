import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSTask } from "../../redux/actions/stask";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import styles from '../../styles/styles';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Country } from "country-state-city";

const CreateSTask = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.stasks);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const showImageButton = false;
  const [name, setName] = useState("");
  const [btdescription, setBTDescription] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [taskPayment, setTaskPayment] = useState(0);
  const [paymentMode, setPaymentMode] = useState("");
  const [taskDuration, setTaskDuration] = useState(0);
  const [durationMode, setDurationMode] = useState("");
  const [tools, setTools] = useState("");
  const [requiredSkills, setRequiredSkills] = useState("");
  const [taskEnvironment, setTaskEnvironment] = useState("");
  const [resourceLink1, setResourceLink1] = useState("");
  const [resourceLink2, setResourceLink2] = useState("");
  const [resourceLink3, setResourceLink3] = useState("");
  const [taskQuantity, setTaskQuantity] = useState(0);
  const [targetedCountry, setTargetedCountry] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Task created successfully!");
      navigate("/dashboard-s-tasks");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

    const handleSubmit = (e) => {
    e.preventDefault();
    
    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("btdescription", btdescription);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("taskPayment", taskPayment);
    newForm.append("paymentMode", paymentMode);
    newForm.append("taskDuration", taskDuration);
    newForm.append("durationMode", durationMode);
    newForm.append("tools", tools);
    newForm.append("requiredSkills", requiredSkills);
    newForm.append("taskEnvironment", taskEnvironment);
    newForm.append("resourceLink1", resourceLink1);
    newForm.append("resourceLink2", resourceLink2);
    newForm.append("resourceLink3", resourceLink3);
    newForm.append("taskQuantity", taskQuantity);
    newForm.append("targetedCountry", targetedCountry);
    newForm.append("shopId", seller._id);
    dispatch(
      createSTask({
        name,
        btdescription,
        description,
        category,
        tags,
        taskPayment,
        paymentMode,
        taskDuration,
        durationMode,
        tools,
        requiredSkills,
        taskEnvironment,
        resourceLink1,
        resourceLink2,
        resourceLink3,
        taskQuantity,
        targetedCountry,
        shopId: seller._id,
        images
      })
    );
  };

  return (
    <div className="w-full flex flex-col items-center py-5 px-5">
      <h5 className="text-[30px] font-Poppins text-center">Create Project</h5>
      
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
      {/* create task form */}
      <div className="w-full 800px:w-[65%]">
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Project Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="enter task tiltle..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
          Brief Project Description (All Applicants will see this) <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="4"
            type="text"
            name="description"
            value={btdescription}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setBTDescription(e.target.value)}
            placeholder="Enter your task description..."
          ></textarea>
        </div>
        
        <br />
        <div>
          <label className="pb-2">
            Full Project Description (Only assigned freelancers can see this) <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your task description..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Project Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose project category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2 font-[400]">Tags</label>
          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTags(e.target.value)}
            placeholder="Enter your task tags..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Payment USD: <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={taskPayment}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskPayment(e.target.value)}
            placeholder="Enter task payment..."
          />
        </div>
        <br />

        <div>
              <label
                htmlFor="paymentMode"
              >
                <span className="text-[16px] text-[#000]">Payment Mode</span> <span className="text-red-500">*</span>
              </label>
                              <div className="mt-1">
                              <select
                              name="paymentMode"
                              id="paymentMode"
                              onChange={(e) => setPaymentMode(e.target.value)}
                              className="w-[200px] h-[35px] border rounded"
                              >
                              <option value="-">-</option>
                              <option value="Hour">Hour</option>
                              <option value="Day">Day</option>
                              <option value="Week">Week</option>
                              <option value="Month">Month</option>
                              <option value="Year">Year</option>
                              <option value="Full">Full</option>
                              </select>
                              </div>
             </div>
             <br />

        <div className="flex">
        <div>
          <label className="pb-2">Project Duration <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="taskDuration"
            value={taskDuration}
            className="mt-2 appearance-none block w-[100px] px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskDuration(e.target.value)}
            placeholder="Enter task duration..."
          />
          <div className="mt-1">
                              <select
                              name="durationMode"
                              id="durationMode"
                              onChange={(e) => setDurationMode(e.target.value)}
                              className="w-[100px] h-[35px] border rounded"
                              >
                              <option value="-">-</option>
                              <option value="Hours">Hours</option>
                              <option value="Days">Days</option>
                              <option value="Weeks">Weeks</option>
                              <option value="Months">Months</option>
                              <option value="Years">Years</option>
                              </select>
                              </div>
        </div>
       </div>

             
        
        <br />
        <div>
          <label className="pb-2">Tools </label>
          <input
            type="text"
            name="tools"
            value={tools}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTools(e.target.value)}
            placeholder="Enter tools..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2">Required Skills (enter skills separated by a commer) <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="requiredSkills"
            value={requiredSkills}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setRequiredSkills(e.target.value)}
            placeholder="Enter required skills..."
          />
        </div>
        <br />

        <br />
        <div>
              <label
                htmlFor="taskEnvironment"
              >
                <span className="text-[16px] text-[#000]">Project Environment</span> <span className="text-red-500">*</span>
              </label>
                              <div className="mt-1">
                              <select
                              name="taskEnvironment"
                              id="taskEnvironment"
                              onChange={(e) => setTaskEnvironment(e.target.value)}
                              className="w-[200px] h-[35px] border rounded"
                              >
                              <option value="-">-</option>
                              <option value="Online">Online</option>
                              <option value="Client's Home">Client's Home</option>
                              <option value="Onsite">Onsite</option>
                              <option value="Online and Onsite">Online and Onsite</option>
                              </select>
                              </div>
             </div>
        <br />

        <h2 className="text-[16px] font-[500]">Links to the Resource/Materials that will be used for the job</h2>
        <p>Only Assigned freelancers can see this</p>
        <br />
        <div>
          <label className="pb-2"> Resource Link 1
          </label>
          <input
            type=" resourceLink1"
            name=" resourceLink1"
            value={resourceLink1}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setResourceLink1(e.target.value)}
            placeholder="Enter link..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2"> Resource Link 2
          </label>
          <input
            type=" resourceLink2"
            name=" resourceLink2"
            value={resourceLink2}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setResourceLink2(e.target.value)}
            placeholder="Enter link..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2"> Resource Link 3
          </label>
          <input
            type=" resourceLink3"
            name=" resourceLink3"
            value={resourceLink3}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setResourceLink3(e.target.value)}
            placeholder="Enter link..."
          />
        </div>
        <br />

        <br />
        <div>
          <label className="pb-2">Max Applicants (Maximum Numbers of Applications)
          </label>
          <input
            type="number"
            name="price"
            value={taskQuantity}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskQuantity(e.target.value)}
            placeholder="Enter task quantity..."
          />
        </div>
        <br />

        
        <div>
              <label
                htmlFor="targetedCountry"
              >
                <span className="text-[16px] text-[#000]">Targeted Country</span> (Choose "Worldwide" if you wnat your project to be open to all countries") <span className="text-red-500">*</span>
              </label>
                  <div className="mt-1">
                  <select
                      name=""
                      id=""
                      value={targetedCountry}
                      onChange={(e) => setTargetedCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                       Choose Country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                        <option value="Worldwide">Worldwide</option>
                    </select>
                  </div>
              <h6
               className="block text-sm font-medium text-gray-500"
              >
                Country Not Listed and you want us to add it? send an email to us at <span className="text-blue-500">news.totlesoft@gmail.com</span>
              </h6>
            </div>

        <div>
          {showImageButton && 
          <div>
            <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
            </div>}
          

          <br />
          
            <div>
                <button
                onClick={handleSubmit}
                className={`${styles.button} !h-[42px] text-white`}
                >Create Project</button>
            </div>
                  
        </div>

         </form>
         
         </div>
         

         
         

         </div>
    </div>
    );
};

export default CreateSTask;


/*
<div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
         {hasNotPaid &&
         
         <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
           <form className="space-y-6" >
           <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 USD/Naira Rate:
               </label>
               {showdata && 
               <div className="mt-1">
                 <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                   $1USD = N{seller && dollar_rate} Naira
                 </h4>
                </div>
                 }
             </div>
 
             <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 Full Name:
               </label>
               {showdata && 
               <div className="mt-1">
                 <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                   {seller && seller?.name}
                 </h4>
                </div>
                 }
             </div>
 
             <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 Email address:
               </label>
               {showdata && 
               <div className="mt-1">
                 <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                   {seller && seller?.email}
                 </h4>
                </div>
                }
             </div>
 
             <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 Task Budget: NGN
               </label>
               {showdata && 
               <div className="mt-1">
                 <div>
                <h5
                name="taskbudget"
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >{paymentInNaira}</h5>
                </div>
                </div>
                }
             </div>
           </form>
 
           {showdata ? (
             <PaystackButton {...componentProps}
             className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
             type="submit"
             
             />
           ) : (
             <div>
               <br />
             <button 
             className={`!bg-[#0fa311] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] pl-[10px] pr-[10px]`} 
            
             >Payment is Completed. Create Task</button>
             <br />
             </div>
           )}
         <div>
           <br />
         <img
       src="https://marketplace.abantecart.com/image/thumbnails/1a/ad/main_image_png-109265-750x400.png" 
       width='70px'
       alt=''
       />
       <br />
         </div>       
 
        
         </div>
       
         }
         </div>
*/