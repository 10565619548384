import { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import birthdayImg from "../../Assests/images/birthdayiv.png";
import 'animate.css';
import './ivlist.css';
import TrackVisibility from 'react-on-screen';
import emailjs from '@emailjs/browser';

  const IVInvites = () => {

  const form = useRef();

  const [delMessage, setDelMessage] = useState('')
  
   
  const sendEmail = (e) => {
    e.preventDefault();
   
    emailjs.sendForm('service_qwcgg2o', 'template_sijmion', form.current, 'iKVEhg_f3Fkx-lPGi')
    .then((result) => {
      console.log(result.text);
      setDelMessage('Reservation Recieved! Thanks a million.')
  }, (error) => {
      console.log(error.text);
      setDelMessage('Message sending failed! Please try again later.')
  });

    e.target.reset();
  };

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6} className="cont-left">
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={birthdayImg} alt="BirthdayIV"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6} className="cont-right">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                <h2 className="mb-6 text-[25px] font-[600] text-[#fff] text-center">Erhuvwu Wealth Okokoro's Golden Jubilee Birthday Celebration: 26th October, 2024. Reservation Form:</h2>
                
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" required='true' placeholder="First Name" name="first_name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="Last Name" name="last_name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" required='true' placeholder="Email Address" name="email"  />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" required='true' placeholder="Phone No." name="phone" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" required='true' placeholder="Attending? (Yes, No)." name="attending" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" required='true' placeholder="Location (UK, US)." name="location" />
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" required='true' placeholder="Birthday Wish" name="message" ></textarea>
                    <Col>
                    <p className="text-[#fff]">{delMessage}</p>
                    </Col>
                      <button type="submit"><span>Submit</span></button>
                    </Col>
                    
                  </Row>
                  
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default IVInvites;