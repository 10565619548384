import React, { useEffect } from "react";
import tPic1 from "../Assests/images/tPic1.jpg"
import tPic2 from "../Assests/images/tPic2.jpg"
import tPic3 from "../Assests/images/tPic3.jpg"
import tPic4 from "../Assests/images/tPic4.jpg"
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const HorizontalCardNew2 = () => {

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


     
  return (
    <div className="bg-[#fff] px-10 py-12 grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] my-12">
        
        <div className="w-full min-h-[450px] bg-[#F6F6F5] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic1}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
          {t('sCard1')}
        </h5>         
        </div>


        <div className="w-full min-h-[450px] bg-[#F6F6F5] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic2}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
          {t('sCard2')}
        </h5>         
        </div>


        <div className="w-full min-h-[450px] bg-[#F6F6F5] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic3}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
          {t('sCard3')}
        </h5>         
       </div>


        <div className="w-full min-h-[450px] bg-[#F6F6F5] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic4}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
          {t('sCard4')}
           </h5>         
        </div>
        </div>
          
    );
};

export default HorizontalCardNew2;


/*

          <img
            src={tPic2}
            alt="worker"
            className="w-full object-contain pt-2"
          />

          */