import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const DJobDetails = () => {
    const { id } = useParams();
    const [allDJobs, setAllDJobs] = useState([]);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    

    useEffect(() => {
        axios.get(`${server}/djob/get-all-djobs`, {withCredentials: true}).then((res) => {
        setAllDJobs(res.data.djobs);
       
       })
     }, []); 
   
       

        const data = allDJobs && allDJobs.find((i) => i._id === id);

        const jobIsAccepted = data && data.workers.length > 0 && data.workers.length < 2;
       
                  
        const worker = {
            workerName: user?.name,
            workerEmail: user?.email,
            workerId: user?._id,
            workerAvatar: user?.avatar.url,
            workerLocation: user?.country,
            workerLanguage: user?.language,
            jobID: data?._id,
            clientID: data?.shopId,
            jobTitle: data?.name,
            }
          
          const jobAcceptanceHandler = async () => {

            if (jobIsAccepted){
              return toast.error("Job already accepted!");
            } else {
              await axios
                    .put(
                    `${server}/djob/accept-job/${id}`,
                    {
                        worker: worker,
                        clientId: data.shopId,    
                        clientEmail: data.shop.email,                    
                    },
                    { withCredentials: true }
                  )
                  .then((res) => {
                      toast.success("Job accepted!");
                      navigate('/profile');
                      window.location.reload();
                      })
                   .catch((error) => {
                    toast.error(error.response.data.message);
              });
            }
         }

      
      
      return (
        <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  
                  <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-3 font-[600] text-[18px]">
                            {data.name}
                            </h4>

                            <h4 className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
                            {data.btdescription}
                            </h4>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Payment:</small> ${data.taskPayment} 
                           </h5>
                            </div>

                            <div className="flex">
                             <h5 className="text-[14px] font-[400]">
                            <small className="text-[15px] text-[blue]">Required Skills:</small> {data.requiredSkills}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Project Duration:</small> {data.taskDuration}
                             <small className="text-[13px]"> {data.durationMode}</small> 
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Location:</small> {data.taskEnvironment}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Opening:</small> {data.targetedCountry}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Listed On:</small> {data?.createdAt.slice(0, 10)}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[red]">Deadline:</small> {data?.deadLine}
                            </h5>
                            </div>

                           <br />

                            {user && user?.role === "Worker" && (
                              <div>
                                <div className="800px:w-[50%] text-[16px] text-[blue]">
                                <h5>Worker: {user?.name}</h5>
                                </div> 
                                <br />
                                      
                                <button
                                className={`${styles.button} !h-[42px] text-white`}
                                onClick={jobAcceptanceHandler}
                               >
                                Accept Job
                                </button>
                              </div>
                            )}

                            
                           </div>
                        </div>
                      
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[30px] text-[500]">Loading Job Details...!</h1>
                <p>It may take few minutes</p>
                </div>}
                </div>
           );

        }

export default DJobDetails;
