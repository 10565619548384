import React from 'react'
import UserExchangeDashboardHeader from '../components/UserExchangeDashboardHeader';
import UserExchange from "../components/UserExchange";
import UserExchangeDashboardSideBar from "../components/UserExchangeDashboardSideBar";

const UserExchangePage = () => {
  return (
    <div>
    <UserExchangeDashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <UserExchangeDashboardSideBar active={2} />
      </div>
       <UserExchange />
    </div>
  </div>
  )
}

export default UserExchangePage;