import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TaskTnCDetails from "../components/TaskTnC/TaskTnCDetails";


const TaskTnCPage = () => {
 
  
  return (
    <div>
      <Header />
        <TaskTnCDetails />
      <Footer />
    </div>
  );
};

export default TaskTnCPage;
