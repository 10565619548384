import React from "react"
import WnMAppDev from "../components/WnMAppDev/WnMAppDev";
import Header from "../components/Layout/Header";

const WnMAppDevPage = () => {
  return (
    <div>
      <Header />
      <WnMAppDev />
      </div>
  );
};


export default WnMAppDevPage 

