import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

const HistoryInfo = ({
    transferData,
    convertData,
    withdrawUserUserData,
  }) => {
    const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

    const [active, setActive] = useState(1);

    useEffect(() => {
      console.log('Setting page stuff')
      document.body.dir = currentLanguage.dir || 'ltr'
      document.title = t('app_title')
    }, [currentLanguage, t])
    

      const columns = [
        { field: "id", 
        headerName: "Withdraw Id", 
        minWidth: 100, 
        flex: 1, 
      },
        {
          field: "name",
          headerName: "User Name",
          minWidth: 100,
          flex: 0.7,
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 80,
          flex: 0.6,
        },
        {
          field: "status",
          headerName: "status",
          type: "text",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Request given at",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const row = [];

    withdrawUserUserData &&
    withdrawUserUserData.forEach((item) => {
      row.push({
        id: item._id,
        name: item.user.name,
        amount: "$ " + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });


    // for Transfer

    const transfercolumns = [
        { field: "id", 
        headerName: "Transfer Id", 
        minWidth: 100, 
        flex: 0.7 
      },
        {
          field: "sender",
          headerName: "Sender",
          minWidth: 100,
          flex: 1.4,
        },
        {
          field: "receiver",
          headerName: "Receiver",
          minWidth: 100,
          flex: 1.4,
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 100,
          flex: 0.6,
        },
        {
          field: "status",
          headerName: "Status",
          type: "text",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Date",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const transferrow = [];

    transferData &&
    transferData.forEach((item) => {
        transferrow.push({
        id: item._id,
        sender: item.sender,
        receiver: item.receiver,
        amount: "$ " + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });


    // for Converts

    const convertscolumns = [
        { field: "id", 
        headerName: "Convert Id", 
        minWidth: 100, 
        flex: 1, 
      },
        {
          field: "point",
          headerName: "Points",
          minWidth: 80,
          flex: 0.6,
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 100,
          flex: 0.6,
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Date",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const convertsrow = [];

    convertData &&
    convertData.forEach((item) => {
        convertsrow.push({
        id: item._id,
        point: item.point,
        amount: "$ " + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });
  
    return (
      <div className="bg-[#f5f6fb] px-3 800px:px-5 py-2 rounded">
        <div className="w-full flex justify-between pt-10 pb-2">
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(1)}
            >
              {t('Withdrawals')}
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(2)}
            >
              {t('Transfers')}
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(3)}
            >
              {t('Conversions')} 
            </h5>
            {active === 3 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
        </div>
        
        
        {active === 1 ? (
        <div className="w-full bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
        </div>
        ) : null}
  
        {active === 2 ? (
          <div className="w-full bg-white">
          <DataGrid
            rows={transferrow}
            columns={transfercolumns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          </div>
        ) : null}
  
        {active === 3 && (
          <div className="w-full bg-white">
          <DataGrid
            rows={convertsrow}
            columns={convertscolumns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          </div>
        )}
      </div>
    );
  };

  export default HistoryInfo;