import React, {useState, useEffect} from "react";
import styles from "../../styles/styles";
import { FiShoppingBag } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import {GrWorkshop} from "react-icons/gr";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../server";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { PaystackButton } from "react-paystack";
import { getAllNewCodesOfUser } from "../../redux/actions/newcode";
import { getAllUsedCodesOfUser } from "../../redux/actions/usedcode";
import { DataGrid } from "@mui/x-data-grid";

import logo0 from "../../Assests/images/complete.png"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { MdOutlineFolderCopy } from "react-icons/md";


const CodeVendorDashboardMain = () => {
    const { user } = useSelector((state) => state.user);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openPaypal, setOpenPaypal] = useState(false);
    const [openCrypto, setOpenCrypto] = useState(false);
    const dollarRate = 1000

  const [createCPayInvoice, setCreateCPayInvoice] = useState(true);
  const [showPayCPButton, setShowPayCPButton] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceOrderId, setInvoiceOrderId] = useState("");
  const [pDtoUse, setPDtoUse] = useState([]);

    const [hasPaid, setHasPaid] = useState(false);
    const [hasNotPaid, setHasNotPaid] = useState(true);
    const [showdata, setShowData] = useState(true)

    const isWithdrawalTime = true;
    const availableBalance = user?.availableBalance.toFixed(4);

    const { newcodes } = useSelector(
      (state) => state.newcode
    );

    const { usedcodes } = useSelector(
      (state) => state.usedcode
    );

    useEffect(() => {
      
      dispatch(getAllNewCodesOfUser(user._id));
      dispatch(getAllUsedCodesOfUser());

    }, []);

    

    const totalNewCodes = newcodes && newcodes.length;
    const totalUsedCodes = usedcodes && usedcodes.length;

    const publicKey = "pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964";
    const dollar_rate = dollarRate;
    const paymentInNaira = Math.round(5 * dollar_rate)
       
  const componentProps = {
    email: user?.email,
    amount: Math.round(5 * 100 * dollar_rate),
    publicKey,
    referenceID: +Math.floor((Math.random() * 1000000000) + 1),
    text: "Naira",
    onSuccess: () => paywithPaystack(),
    onClose: function() {
      alert('Transaction was not completed, window closed.');
    },
   
  }

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Code Generation Fee",
            amount: {
              currency_code: "USD",
              value: 5,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler();
      }
    });
  };

  const paypalPaymentHandler = async () => {

          toast.success("Payment Completed!");
          paywithPaystack();
                     
  };

      
  const paywithPaystack = () => {
    setShowData(false)
    setHasPaid(true)
    setHasNotPaid(false)
    setShowPayCPButton(false)
    
  }


    const generateCodeHandler = async () => {

        setIsLoading(true);
        const codeCreator = user?.username;
        const codeCreatorId = user?._id;
        const codeCreatoremail = user?.email;

        await axios
    
          .post(
            `${server}/newcode/create-newcode`,
            {
              codeCreator: codeCreator,
              codeCreatorId: codeCreatorId,
              codeCreatoremail: codeCreatoremail,
            },
            { withCredentials: true }
          )
          .then((res) => {
              setIsLoading(false);
              toast.success("Code Generated Successfully!");
              navigate('/codevendor/dashboard');
              window.location.reload();
              })
           .catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.message);
          });
  }


  const invData = {
    amount: "5",
    currency: "USD",
    additional_data: user?.email,
}


const creatCPInvoice = async () => {
setIsLoading(true);

try {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { data } = await axios.post(
    `${server}/payment/crypto-checkout`,
    invData,
    config
  );

  const url = data.url;
  const order_id = data.order_id;
  
  if(url !== null && order_id !== null){
    setInvoiceUrl(url);
    setInvoiceOrderId(order_id);
    setCreateCPayInvoice(false);
    setShowPayCPButton(true);
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
};  


const confirmCPaymentInfo = async () => {
setIsLoading(true);

try {

const order_id = invoiceOrderId;

     
const { data } = await axios.get(
    `${server}/payment/crypto-payment-info/${order_id}`);
    setPDtoUse(data);

    if(!data){
      toast.error("Payment not found with this order Id")
    }

  const status = pDtoUse.cryptopayments[0].status;
  const is_final = pDtoUse.cryptopayments[0].is_final;

  if(status === "paid" && is_final === true){
           
    paywithPaystack();
    setIsLoading(false);
     
  }else{   

    toast.error("Payment is still processsing");
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
}; 

const orderID = invoiceOrderId;

  const writeTextToClipboard = (text) => {
    text = orderID;
    navigator.clipboard.writeText(text);
    toast.success("Id Copied!");
  };

  const columns = [
    { field: "id", headerName: "Code ID", minWidth: 130, flex: 0.8 },
    {
      field: "code",
      headerName: "Code",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: "codeCreator",
      headerName: "Creator",
      minWidth: 80,
      flex: 0.6,
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 80,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 80,
      flex: 0.8,
    },
  ];

  const row = [];

  newcodes &&
    newcodes.forEach((item) => {
      row.push({
        id: item._id,
        code: item.code,
        codeCreator: item.codeCreator,
        time: item.createdAt.slice(11, 16),
        createdAt: item.createdAt.slice(0, 10),
      });
    });

    console.log(newcodes);
 

  return (
    <>
    {isLoading ? 
    <Spinner /> : (
        <div className="w-full p-4 mt-2 mb-2">
        <h3 className="text-[22px] font-Poppins pb-2">Code Vendor Dashboard</h3>
        <div className="w-full p-4 block 800px:flex items-center justify-between">
        <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              Earning Balance
              <span className="text-[16px]">(with 3% vat)</span>
            </h3>
          </div>
          <h5 className="text-center pt-2 pl-[36px] text-[22px] font-[500]">${availableBalance}</h5>
          {isWithdrawalTime === true ? (
            <Link to="/dashboard-withdraw-money-user">
            <h5 className="text-center pt-4 pl-[2] text-[#077f9c]">Withdraw Money</h5>
          </Link>
          ) : (
            <Link to="#">
              <h5 className="text-center pt-4 pl-[2] text-[#000]">Withdraw Money</h5>
           </Link>
          )}
          
        </div>

          <div className="w-full mb-4 800px:w-[22%] h-[30vh] bg-white shadow rounded px-2 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Generate Code
              </h3><br />
            </div>

            <div>
            <h5 className="pt-4 pl-2">
            Pay Code Generation Fee $5, to activate 
              <span className="pt-4 pl-2 text-[#077f9c]">
              "Generate"
              </span> Button</h5>
              <p className="pl-2 text-[#077f9c]">Choose payment option:</p>
            <div className="flex justify-between w-full">
            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center`}
            onClick={() => setOpen(true)}
            >
                Naira
            </button>

            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center !px-3`}
            onClick={() => setOpenPaypal(true)}
            >
                USD/PayPal
            </button>

            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center`}
            onClick={() => setOpenCrypto(true)}
            >
                Crypto
            </button>
            </div>
            </div>

            {open && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div
            className={`w-[90%] 800px:w-[50%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}
          >
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
         {hasNotPaid &&
         
         <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
           <form className="space-y-6" >
           <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 USD/Naira Rate:
               </label>
               {showdata && 
               <div className="mt-1">
                 <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                   $1USD = N{user && dollar_rate} Naira
                 </h4>
                </div>
                 }
             </div>
 
             <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 Code Creator:
               </label>
               {showdata && 
               <div className="mt-1">
                 <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                   {user && user?.username}
                 </h4>
                </div>
                 }
             </div>
 
             <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 Email address:
               </label>
               {showdata && 
               <div className="mt-1">
                 <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                   {user && user?.email}
                 </h4>
                </div>
                }
             </div>
 
             <div>
               <label
                 htmlFor="email"
                 className="block text-sm font-medium text-gray-700">
                 Amount: NGN
               </label>
               {showdata && 
               <div className="mt-1">
                 <div>
                <h5
                name="taskbudget"
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >{paymentInNaira}</h5>
                </div>
                </div>
                }
             </div>

             <br />
          
           </form>
 
           {showdata ? (
             <PaystackButton {...componentProps}
             className={`${styles.button} text-[#fff] !h-[42px] rounded`} 
             type="submit"
            />
           ) : null}
         <div>
         </div>       
        </div>
       }
       </div>

       {hasPaid && 
          <div>
            <br />
            <div className="pb-2">
            <Link to="/">
              <img
                src={logo0}
                width='30px'
                alt=""
              />
            </Link>
          </div>
            <p className="text-[25px] font-[600] mb-2 text-[green]">Payment completed!</p>
            <h5 className="text-[16px] font-[600] text-[red]">Code Generation Agreement</h5>
            <p className="text-[16px]">By clicking the Generate Code button you agreed that<br />* It is one code to one user.<br />* You should never send a fake or used code to a user.<br />* You're sure that you've copied the latest generated code before sending to the user.<br />* Confirmed negative report may lead to terminating of your account and freezing of funds.</p><br />
              <button
                onClick={generateCodeHandler}
                className={`${styles.button} !h-[42px] text-white`}
                >Generate Code
            </button>
                
          </div>
         }
      </div>
    </div>
    )}


        {openPaypal && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div
            className={`w-[90%] 800px:w-[50%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}
          >
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenPaypal(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
         {hasNotPaid &&
         
         <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
           <br />

          <PayPalScriptProvider
            options={{
            "client-id":
            "AWhLs3ILRhUxqvgpcKjd6rvoU47rg_l7Yyc_GW-InHPkuLbpCouEE29HniCePvK8L1QxbneMxJezAzUq",
            }}
          >
          <PayPalButtons
          style={{ layout: "vertical" }}
          onApprove={onApprove}
          createOrder={createOrder}
          />
        </PayPalScriptProvider>       
        </div>
       }
       </div>

       {hasPaid && 
          <div>
            <br />
            <div className="pb-2">
            <Link to="/">
              <img
                src={logo0}
                width='30px'
                alt=""
              />
            </Link>
          </div>
            <p className="text-[25px] font-[600] mb-2 text-[green]">Payment completed!</p>
            <h5 className="text-[16px] font-[600] text-[red]">Code Generation Agreement</h5>
            <p className="text-[16px]">By clicking the Generate Code button you agreed that<br />* It is one code to one user.<br />* You should never send a fake or used code to a user.<br />* You're sure that you've copied the latest generated code before sending to the user.<br />* Confirmed negative report may lead to terminating of your account and freezing of funds.</p><br />
              <button
                onClick={generateCodeHandler}
                className={`${styles.button} !h-[42px] text-white`}
                >Generate Code
            </button>
                
          </div>
         }
      </div>
    </div>
    )}


        {openCrypto && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
              <div className={`w-[90%] 800px:w-[30%] bg-white shadow rounded h-[70%] min-h-[50vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCrypto(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
         {createCPayInvoice &&
          <>
          <p>Click button bellow to generate crypto payment invoice</p> 
          <div>
           <br />
            <button
                className={`${styles.button} !h-[42px] text-white`}
                onClick={creatCPInvoice}
              >
                Generate
              </button>
            </div>

        </>      
       }
       </div>

       {showPayCPButton && 
            <>
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p className="text-[green] font-[600]">Invoice generated successfully</p> 
        <h5 className="text-[16px] font-[600]">Order Id: {invoiceOrderId} 
        <MdOutlineFolderCopy 
        onClick={writeTextToClipboard}
        size={20}
        className="cursor-pointer mb-2"
        />
        </h5> 
        <h5 className="text-[red] text-[14px] font-[500]">Please save this order_id, it will be requested if there's an error with this order.</h5><br />

        <Link to={`${invoiceUrl}`} target="_blank">
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}>
         Pay 
        </h5>
        </Link>
        </div>
        <br />
        <h5>Wait atleast a minute after the payment has marked 'Payment is paid' before clicking the Completed button.</h5>
        <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
        onClick={confirmCPaymentInfo}
        >
         Completed 
        </h5>
        </>
            }

       {hasPaid && 
          <div>
            <br />
            <div className="pb-2">
            <Link to="/">
              <img
                src={logo0}
                width='30px'
                alt=""
              />
            </Link>
          </div>
            <p className="text-[25px] font-[600] mb-2 text-[green]">Payment completed!</p>
            <h5 className="text-[16px] font-[600] text-[red]">Code Generation Agreement</h5>
            <p className="text-[16px]">By clicking the Generate Code button you agreed that<br />* It is one code to one user.<br />* You should never send a fake or used code to a user.<br />* You're sure that you've copied the latest generated code before sending to the user.<br />* Confirmed negative report may lead to terminating of your account and freezing of funds.</p><br />
              <button
                onClick={generateCodeHandler}
                className={`${styles.button} !h-[42px] text-white`}
                >Generate Code
            </button>
                
          </div>
         }
      </div>
    </div>
    )}

</div>


  
          <div className="w-full mb-4 800px:w-[22%] h-[30vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
              <GrWorkshop size={30} className="mr-2" fill="#00000085" />
              <h3
                className={`${styles.productTitle} !text-[18px] leading-5 !font-[600] text-[#00000085]`}
              >
                New Codes
              </h3>
            </div>
            <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{totalNewCodes}</h5>
            <Link to="/codevendor-newcodes">
              <h5 className="pt-4 pl-2 text-[#077f9c]">View</h5>
            </Link>
          </div>
  
          <div className="w-full mb-4 800px:w-[22%] h-[30vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
              <FiShoppingBag
                size={30}
                className="mr-2"
                fill="#00000085"
              />
              <h3
                className={`${styles.productTitle} !text-[18px] leading-5 !font-[600] text-[#00000085]`}
              >
                Used Codes
              </h3>
            </div>
            <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{totalUsedCodes}</h5>
            <Link to="/codevendor-usedcodes">
              <h5 className="pt-4 pl-2 text-[#077f9c]">View</h5>
            </Link>
          </div>
        </div>
  
        <br />
        <h3 className="text-[22px] font-Poppins pb-2">New Codes</h3>
        <div className="w-[95%] bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
      </div>
    )}
    </>
        
    );
};

export default CodeVendorDashboardMain;
