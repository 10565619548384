import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    isLoading: true,
};

export const minertaskReducer = createReducer(initialState, {
    LoadminertaskRequest: (state) => {
        state.isLoading = true;
    },

    LoadminertaskSuccess: (state, action) => {
        state.isLoading = false;
        state.minertask = action.payload;
    },

    LoadminertaskFail: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },

    // load all minertasks
    getAllMinerTasksRequest: (state) => {
        state.isLoading = true;
    },

    getAllMinerTasksSuccess: (state, action) => {
        state.isLoading = false;
        state.minertasks = action.payload;
    },

    getAllMinerTasksFailed: (state, action) => {
        state.isLoading = true;
        state.error = action.payload;
    },

    clearErrors: (state) => {
        state.error = null;
    },

})