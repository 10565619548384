import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import DJobDetails from "../components/DJobs/DJobDetails";
import { useParams } from "react-router-dom";
import { server } from "../server";
import axios from "axios";


const DJobDetailsPage = () => {
  const { id } = useParams();
  const [allDJobs, setAllDJobs] = useState([]);
  
  useEffect(() => {
 axios.get(`${server}/djob/get-all-djobs`, {withCredentials: true}).then((res) => {
  setAllDJobs(res.data.djobs);
})
}, []); 
         
     
     const data = allDJobs && allDJobs.find((i) => i._id === id); 
  
  return (
    <div>
      <Header />
      {data ? (
        <DJobDetails data={data} />
      ) : 
        <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500]">Ops! Slow Network!</h1>
          <p>Please try again later.</p>
        </div>
     }
      </div>
  );
};

export default DJobDetailsPage;
