import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const talentReducer = createReducer(initialState, {
  talentCreateRequest: (state) => {
    state.isLoading = true;
  },
  talentCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.talent = action.payload;
    state.success = true;
  },
  talentCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //load talent
  LoadTalentRequest: (state) => {
    state.isLoading = true;
  },
  LoadTalentSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.isLoading = false;
    state.talent = action.payload;
  },
  LoadTalentFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // get all talents
  getAllTalentsRequest: (state) => {
    state.isLoading = true;
  },
  getAllTalentsSuccess: (state, action) => {
    state.isLoading = false;
    state.allTalents = action.payload;
  },
  getAllTalentsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },

  // get all talents gigs created by a user
  getAllTalentsUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllTalentsUserSuccess: (state, action) => {
    state.isLoading = false;
    state.talents = action.payload;
  },
  getAllTalentsUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete talent package of a user
  deleteTalentRequest: (state) => {
    state.isLoading = true;
  },
  deleteTalentSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteTalentFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  });

