import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TalentCardGigs from "../components/Route/TalentCard/TalentCardGigs";
import styles from "../styles/styles";
import Spinner from "../components/Spinner/Spinner";
import axios from "axios";
import { server } from "../server";
import { useParams } from "react-router-dom";


const UserGigsPage = () => {

  const [dataToUse, setDataToUse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/talent/get-all-talents`, {
        withCredentials: true,
      })
      .then((res) => {
        setDataToUse(res.data.talents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setIsLoading(false);
      });
  }, []);

  const data = dataToUse && dataToUse.filter((item) => item.userId === id);

  console.log(data);
  
   
  return (
    <div>
      <Header />
      <br />
      <br />
      {isLoading ? <Spinner /> : (
        <div className={`${styles.section}`}>
            
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <TalentCardGigs data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            Loading...!
          </h1>
        ) : null}
      </div>
      )}
      <br />
        <Footer />
    </div>
    )
};

export default UserGigsPage;
