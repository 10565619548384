import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateUserTaxInfo } from "../../redux/actions/user";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";

const TaxInfo = () => {

    const { user, error, successMessage } = useSelector((state) => state.user);

    const [legalName, setLegalName] = useState("");
    const [taxCountry, setTaxCountry] = useState("");
    const [taxClass, setTaxClass] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [taxPerson, setTaxPerson] = useState("");
    
    const [taxStreet, setTaxStreet] = useState("");
    const [taxCity, setTaxCity] = useState("");
    const [taxState, setTaxState] = useState("");
    const [taxAddressCountry, setTaxAddressCountry] = useState("");
    const [taxPostalCode, setTaxPostalCode] = useState(0);

    const [taxIN, setTaxIN] = useState(0);
    const [taxCertificateSigner, setTaxCertificateSigner] = useState("");
    const [vatID, setVatID] = useState(0);
    const [statementOfSource, setStatementOfSource] = useState("");

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);

    const email = user?.email;

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        if (error) {
          toast.error(error);
          dispatch({ type: "clearErrors" });
        }
        if (successMessage) {
          toast.success(successMessage);
          dispatch({ type: "clearMessages" });
          navigate('/profile');
          window.location.reload();
        }
      }, [error, successMessage]);

    

    const handleTaxInfoUpdate = (e) => {
        e.preventDefault();
        dispatch(updateUserTaxInfo(
            email,
            legalName,
            taxCountry,
            taxClass,
            dateOfBirth,
            taxPerson,
            taxStreet,
            taxCity,
            taxState,
            taxAddressCountry,
            taxPostalCode,
            taxIN,
            taxCertificateSigner,
            vatID,
            statementOfSource,
        ));
    };


  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <br /><br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Tax Infomation 
        </h1> 
        <h2 className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Totlesoft is required to comply with the tax laws of the countries where our businesses are done, including collecting taxes and remitting them — which means to send the money we collect from you to the tax authority of the government that requires the tax. This page is to help you understand everything you need to know about Vat/Tax as you're required to complete your tax infomation to avoid delay in your withdrawals.
        </h2>
        <br />
        <hr />
        <br />
        
       
        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#F6F6F5] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Forms <span className="text-[15px] text-[green] ml-3 font-[400]">required</span>
              </h3><br />
            </div>
            <div>
            <h5 className="pt-4 pl-2">
            Totlesoft will be opperating majorly from the United States and United Kingdom hence, all freelancers on Totlesoft would need to complete one of the following forms to comply with U.S, UK as well as other countries tax laws:
            </h5>
            <Link to="#"><h5 className="pt-1 pl-2 text-[25px] text-[green]" onClick={() => setOpen1(true)}>Form W-8BEN or W-8BEN-E <span className="text-[15px] text-[black]">- for non-U.S. freelancers</span></h5></Link>
            <Link to="#"><h5 className="pl-2 text-[25px] text-[green]" onClick={() => setOpen2(true)}>Form W-9 <span className="text-[15px] text-[black]">- for U.S. freelancers</span></h5></Link>
            <Link to="#"><h5 className="pl-2 text-[25px] text-[green]" onClick={() => setOpen3(true)}>IR35 — Off-Payroll Working Rules <span className="text-[15px] text-[black]">- for UK freelancers</span></h5></Link>
            <Link to="#"><h5 className="pl-2 text-[25px] text-[green]" onClick={() => setOpen4(true)}>Countries Vat for Freelancers <span className="text-[15px] text-[black]">- General</span></h5></Link>
            </div>
            <h5 className="pt-4 pl-2">
            Some countries requires us to collect value-added tax, or VAT, and remit the tax to the government of that country. VAT is a tax on goods or services, including Totlesoft’s services to you, and remit means to send money for a payment. Example includes Membership fees, Connects purchases, and freelancer service fees.
            And when we charge tax, it’s because your government requires us to, which means this tax may change or apply to additional services in the future.
            </h5>
        </div>
        </div>

        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#F6F6F5] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Vat & Location Changes
              </h3><br />
            </div>
            <div>
                <h5 className="pt-4 pl-2">
                Countries' VAT applies to services we provide to both freelancers and clients if they reside, have a permanent address, or are established in that country.
                </h5>
            <h5 className="pt-4 pl-2">
            In some instance, you may not be required to provide a VAT ID, nevertheless, providing a valid VAT ID does not exempt you from paying the tax. However, if you choose to provide your VAT ID, we will display it on your invoices. 
            As regard location change, If you change locations and update this information in your account profile, we will automatically adjust your VAT to correspond with the rates in your new country of residence or stop collecting if there is no VAT, or a similar tax, in your new country.
            </h5>
            </div>
        </div>
        </div>


        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#F6F6F5] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Tax Advice 
              </h3><br />
            </div>
            <div>
           <h5 className="pt-4 pl-2">
           You may be liable for taxes in your country even when we are not required to collect them on Totlesoft, so it’s important that you stay informed about your local tax laws and requirements. Consult with your local tax authority or tax advisor about any questions you have.
            </h5><br />
            </div>
        </div>
        </div>
        {open1 && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e] cursor-pointer">
          <div className={`w-[95%] 800px:w-[70%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
      <div className="w-full flex justify-end">
       <RxCross1
         size={25}
         onClick={() => setOpen1(false)}
         className="cursor-pointer"
       />
     </div>
     <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
      <h1>Form W-8BEN or W-8BEN-E</h1>
     </div>
     </div>
     </div>
        )}

      {open2 && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e] cursor-pointer">
          <div className={`w-[95%] 800px:w-[70%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
      <div className="w-full flex justify-end">
       <RxCross1
         size={25}
         onClick={() => setOpen2(false)}
         className="cursor-pointer"
       />
     </div>
     <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
      <h1>Form W-9</h1>
     </div>
     </div>
     </div>
        )}


      {open3 && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e] cursor-pointer">
          <div className={`w-[95%] 800px:w-[70%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
      <div className="w-full flex justify-end">
       <RxCross1
         size={25}
         onClick={() => setOpen3(false)}
         className="cursor-pointer"
       />
     </div>
     <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
      <h1>IR35 — Off-Payroll Working Rules</h1>
     </div>
     </div>
     </div>
        )}

    {open4 && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e] cursor-pointer">
          <div className={`w-[95%] 800px:w-[70%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
      <div className="w-full flex justify-end">
       <RxCross1
         size={25}
         onClick={() => setOpen4(false)}
         className="cursor-pointer"
       />
     </div>
     <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
      <h1>Countries Vat for Freelancers</h1>
     </div>
     </div>
     </div>
        )}


        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-4`}
        >
          Complete Tax Info: <span className="text-[green] text-[15px]">{user?.name}</span>
        </h1> 
        <h2 className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Enter your tax information in order to confirm if you are a U.S./UK or non-U.S./non-UK taxpayer and whether or not Totlesoft is required to withhold taxes from your earnings. Add your tax information now to avoid delays in getting paid.
        </h2>
        <br />

        {user?.taxInfo.status === "Completed" && (
            <div className="w-full justify-center items-center">
                <h2 className="text-[20px] font-[500] text-[green]">Great!</h2>
                <h5 className="font-[500]">Tax Record Up to date</h5>
                <p>To Update your already submited records after you've changed location, please send an email with subject: <br />"Tax Record Updating" to <span className="text-[blue]">info@totlesoft.com</span> <br />with the following info:<br />
                * Your Email<br />
                * Your Date of Birth<br />
                * Your Current Full Address<br />
                * A Scanned copy of your ID Card (Back & Front)<br />
                * A proof a new address</p>
            </div>
        )} 
        
        {user?.taxInfo.status === "Not Completed" && (
            <form onSubmit={handleTaxInfoUpdate}>
            <div className="w-full p-4 block 800px:flex">
              <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
                <div className="flex">
                 <h3
                    className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085] mb-4`}
                  >
                    Tax Payer Identification: 
                  </h3><br />
                </div>
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Legal Name:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={legalName}
                        onChange={(e) => setLegalName(e.target.value)}
                      />
                    </div>
                    </div>
                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Tax Classification:</label>
                      <div className="mt-1">
                        <select
                        name="taxPerson"
                        onChange={(e) => setTaxClass(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="Individual">Individual</option>
                        <option value="Coporate">Coporate</option>
                        <option value="Individual and Coporate">Individual and Coporate</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />
    
                  <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Country of Citizenship:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={taxCountry}
                        onChange={(e) => setTaxCountry(e.target.value)}
                      />
                    </div>
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Date of Birth: (MM-DD-YYYY)</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                      />
                    </div>
                  </div>
    
                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Tax Identifyer</label>
                      <div className="mt-1">
                        <select
                        name="taxPerson"
                        onChange={(e) => setTaxPerson(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="I am a US person">I am a US person</option>
                        <option value="I am a UK person">I am a UK person</option>
                        <option value="I am niether a U.S. or UK person">I am niether a U.S. or UK person</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />
    
                  <div className="flex">
                  <h3
                    className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085] mb-4`}
                  >
                    Tax Residence: 
                  </h3><br />
                  </div>
                
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Street Address:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={taxStreet}
                        onChange={(e) => setTaxStreet(e.target.value)}
                      />
                    </div>
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">City:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                        value={taxCity}
                        onChange={(e) => setTaxCity(e.target.value)}
                      />
                    </div>
                  </div>
    
                  <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">State:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={taxState}
                        onChange={(e) => setTaxState(e.target.value)}
                      />
                    </div>
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Postal Code:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                        value={taxPostalCode}
                        onChange={(e) => setTaxPostalCode(e.target.value)}
                      />
                    </div>
                  </div>
    
                  <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Country:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={taxAddressCountry}
                        onChange={(e) => setTaxAddressCountry(e.target.value)}
                      />
                    </div>
                   </div>
                   <br />
    
    
                   <div className="flex">
                  <h3
                    className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085] mb-4`}
                  >
                    Tax Identification Number: 
                  </h3><br />
                  </div>
                
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">TIN:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={taxIN}
                        onChange={(e) => setTaxIN(e.target.value)}
                      />
                    </div>
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Signer of Certificate:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                        value={taxCertificateSigner}
                        onChange={(e) => setTaxCertificateSigner(e.target.value)}
                      />
                    </div>
                  </div>
                  <br />
    
    
                  <div className="flex">
                  <h3
                    className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085] mb-4`}
                  >
                    VAT & Statement of source: 
                  </h3><br />
                  </div>
                
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">VAT Id: (enter 0 if you don't have a vat ID)</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={vatID}
                        onChange={(e) => setVatID(e.target.value)}
                      />
                    </div>                
                  </div>
                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Statement of source</label>
                      <div className="mt-1">
                        <select
                        name="statementOfSource"
                        onChange={(e) => setStatementOfSource(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="I certified that my services are being physically performed outside the U.S. & UK">I certified that my services are being physically performed outside the U.S. & UK</option>
                        <option value="I certified that my services are being physically performed in the U.S.">I certified that my services are being physically performed in the U.S.</option>
                        <option value="I certified that my services are being physically performed in the UK">I certified that my services are being physically performed in the UK</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />
                
    
                </div>
              </div>
              <br />
                <button 
                className={`${styles.button} text-[#fff] justify-center items-center text-center`}
                type="submit"
                >
                   Update
                </button>
               <br />
            </form>
        )}
        
       
        
       

        
            <br />
            <br />
      </div>
    </div>
  );
};

const TaxInfoPage = () => {
    return (
      <div>
        <Header />
        <TaxInfo />
        <Footer />
      </div>
    );
  };

export default TaxInfoPage;

