import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import YoutubeEmbedAutoPlay from "../../components/YoutubeEmbedAutoPlay";
import "../../components/YoutubeEmbed.css";


const VideoTaskDetails = () => {
    const [allVideoTasks, setAllVideoTasks] = useState([]);
    //const [data, setData] = useState([]);
    const { id } = useParams();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [isTaskFinished, setIsTaskFinished] = useState(false);
    const [checkUserIdInTaskdone, setCheckUserIdInTaskdone] = useState(false);
    const [countdown, setCountdown] = useState(70);
    const [isClaimTime, setIsClaimTime] = useState(false);

    
    useEffect(() => {
      axios.get(`${server}/videotask/get-all-videotasks`, {withCredentials: true}).then((res) => {
        setAllVideoTasks(res.data.videotasks);
      
      })

      }, []);
      

      const data = allVideoTasks && allVideoTasks.find((i) => i._id === id);

      
      useEffect(() => {
        const timer =
        countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        return () => clearInterval(timer);
       
        }, [countdown]);

      useEffect(() => {
        if(countdown === 0){
          setTimeout(() => {
            setIsClaimTime(true);
          }, 2000);
        }
      }, [countdown]);
      
               
      const worker = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment:data?.taskPayment,
        requiredWatchTime: data?.watchTime,
        }
      
        
      const taskUpdateHandler = async (e) => {
        e.preventDefault();

        const userDoingTask = user?._id;

        const isDoneTaskAlready =  data.workers.some((item) => item['workerId'] === userDoingTask );

        if(isDoneTaskAlready){
          setCheckUserIdInTaskdone(true);
          return toast.error("You've done this task already!");
        }

        const tQ = data?.taskQuantity;
        const tC = data?.totalTaskCompleted;

      if(tQ === tC){
        setIsTaskFinished(true);
        return toast.error("This task is completed!");
      }

        const taskStatus = data?.taskStatus;
        const oldTC = data?.totalTaskCompleted;
        const watchTime = data?.watchTime;
    
        const newTC = oldTC + 1;
        
        await axios
        
              .put(
                `${server}/videotask/update-videotask-status/${id}`,
                {
                    taskStatus: taskStatus,
                    totalTaskCompleted: newTC,
                    watchTime: watchTime,
                    worker: worker,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler = async () => {

        const userId = user?._id;

        console.log(userId);

        const oldBalance = user && user?.availableBalance;
        const newPayment = data && data?.taskPayment;
        const newBalance = Number(oldBalance) + Number(newPayment);

        const oldTE = user && user?.totalEarning;
        const newTE = Number(oldTE) + Number(newPayment);
               
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              newTE: newTE,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/videotasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }

        
      return (
         <>
        {isTaskFinished ? 
        <div className="w-full py-5 px-10">
        <h1 className="text-[30px] text-[500]">All Tasks has been completed!!!</h1>
        <p>Please check for other available tasks by clicking the button bellow:</p>
        <Link to="/videotasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] text-[18px]">
               Video Tasks
               </span>
          </div>
        </Link>
        </div>
        : (
          <>
          {checkUserIdInTaskdone ? 
        <div className="w-full py-5 px-10">
        <h1 className="text-[30px] text-[500]">You have done this task already!</h1>
        <p>Please check for other available tasks by clicking the button bellow:</p>
        <Link to="/videotasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] text-[18px]">
               Video Tasks
               </span>
          </div>
        </Link>
        </div>
         : (
          <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  <form onSubmit={taskUpdateHandler}>
                    <div className="block w-full 800px:flex">
                        <div className="w-full pt-5">
                            <div className="w-full 800px:w-[80%]">
                            <YoutubeEmbedAutoPlay embedId={data?.videoID} />
                            </div><br />                            

                            <div className="py-2 flex 800px:w-[70%] items-center justify-between">
                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice}`}>
                            <small className="font-[400] text-[13px] text-[green]">Earning:</small> ${data.taskPayment}
                            </h5>
              
                            </div>
                            <span className="font-[400] text-[15px] text-[green]">
                            {data.totalTaskCompleted}/{data.taskQuantity} tasks
                            </span>
                            </div>
                            
                            {user && user?.role === "Worker" && (
                              <div>
                                <div className="block 800px:flex w-full 800px:w-[400px]">
                                <div>
                                <h5
                                className={`${styles.button} !bg-[teal] !w-[200px] text-center !h-[42px] text-white 800px:ml-2 `}
                                >
                                  {countdown} sec
                                </h5>
                                </div>

                              {isClaimTime ? (
                              <button
                              className={`${styles.button} !h-[42px] text-white 800px:ml-2`}
                              type="submit"
                             >
                              ${data?.taskPayment} Claim!
                              </button>
                              ) : 
                              (
                                <input
                                className={`${styles.button} !bg-[grey] text-center !h-[42px] text-white 800px:ml-2 `}
                                value="Wait!"
                               />
                                )
                              }
                                </div>

                                <h6 className="text-[14px] pb-4">
                                By clicking the "Claim" button you've agreed to our<span> - </span> 
                                <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[14px] text-[red] pb-4">
                                terms and condition</Link> on task submission.
                                </h6> 
                              </div>
                            )}

                            
                           </div>
                        </div>
                      </form>
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[30px] text-[500]">Loading Video...!</h1>
                <p>It may take few minutes</p>
                </div>}
            
                </div>
        )}
          </>
        )
        }
        
        </>
        
        
        );

        }

export default VideoTaskDetails;

