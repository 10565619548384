import React, { useState } from "react";
import styles from "../../styles/styles";
import { DataGrid } from "@mui/x-data-grid";


const ClientHistoryInfo = ({
    withdrawData,
    depositData,
    paymentData,
  }) => {
  
  
    const [active, setActive] = useState(1);

    const columns = [
        { field: "id", 
        headerName: "Withdraw Id", 
        minWidth: 100, 
        flex: 0.8, 
      },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 80,
          flex: 0.4,
        },
        {
          field: "status",
          headerName: "status",
          type: "text",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Request given at",
          type: "number",
          minWidth: 100,
          flex: 0.5,
        },
    ];

    const row = [];

    withdrawData &&
    withdrawData.forEach((item) => {
      row.push({
        id: item._id,
        amount: "$ " + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });


    // for depositfbs

    const depositfbcolumns = [
        { field: "id", 
        headerName: "Deposit Id", 
        minWidth: 100, 
        flex: 0.8 
      },
      {
          field: "amount",
          headerName: "Amount",
          minWidth: 100,
          flex: 0.4,
        },
        {
          field: "depositType",
          headerName: "Type",
          minWidth: 100,
          flex: 0.5,
        },
        {
          field: "status",
          headerName: "Status",
          type: "text",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Date",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const depositfbrow = [];

    depositData &&
    depositData.forEach((item) => {
        depositfbrow.push({
        id: item._id,
        amount: "$ " + item.amount,
        depositType: item.depositType,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });


    // for Payments

    const paymentscolumns = [
        { field: "id", 
        headerName: "Payment Id", 
        minWidth: 100, 
        flex: 0.8, 
      },
        {
          field: "jobName",
          headerName: "Job",
          minWidth: 80,
          flex: 0.8,
        },
        {
          field: "jobAmount",
          headerName: "Amount",
          minWidth: 100,
          flex: 0.4,
        },
        {
          field: "paymentDate",
          headerName: "Date",
          type: "number",
          minWidth: 100,
          flex: 0.5,
        },
    ];

    const paymentsrow = [];

    paymentData &&
    paymentData.forEach((item) => {
        paymentsrow.push({
        id: item._id,
        jobName: item.jobName,
        jobAmount: "$ " + item.jobAmount,
        paymentDate: item.paymentDate.slice(0, 10),
      });
    });
  
    return (
      <div className="bg-[#f5f6fb] px-3 800px:px-5 py-2 rounded">
        <div className="w-full flex justify-between pt-10 pb-2">
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(1)}
            >
              Withdrawals
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(2)}
            >
              Deposits
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(3)}
            >
              Payments 
            </h5>
            {active === 3 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
        </div>
        
        
        {active === 1 ? (
        <div className="w-full bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
        </div>
        ) : null}
  
        {active === 2 ? (
          <div className="w-full bg-white">
          <DataGrid
            rows={depositfbrow}
            columns={depositfbcolumns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          </div>
        ) : null}
  
        {active === 3 && (
          <div className="w-full bg-white">
          <DataGrid
            rows={paymentsrow}
            columns={paymentscolumns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          </div>
        )}
      </div>
    );
  };

  export default ClientHistoryInfo;