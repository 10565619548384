import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllVideoTasksShop } from "../../redux/actions/videotask";
import { deleteVideoTask } from "../../redux/actions/videotask";
import Spinner from "../Spinner/Spinner";
//import Loader from "../Loader/Loader";

const AllVideoTasks = () => {
  const { videotasks, isLoading } = useSelector((state) => state.videotasks);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVideoTasksShop(seller._id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteVideoTask(id));
    window.location.reload();
  };

  const columns = [
    { field: "id", headerName: "Task Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Title",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "taskPayment",
      headerName: "Payment",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "taskQuantity",
      headerName: "Task Quantity",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "totalTaskCompleted",
      headerName: "Task Completed",
      minWidth: 130,
      flex: 0.6,
    },
    {
        field: "totalWatchTime",
        headerName: "Total Watch Time",
        minWidth: 130,
        flex: 0.6,
      },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/videotask/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  videotasks &&
    videotasks.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        taskPayment: "$ " + item.taskPayment,
        taskQuantity: item.taskQuantity,
        totalTaskCompleted: item?.totalTaskCompleted,
        totalWatchTime: item?.totalWatchTime,
      });
    });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllVideoTasks;
