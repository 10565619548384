import React from "react";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";


const ArticleDashboardSideBar = ({ active }) => {
  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/profile" className="w-full flex items-center">
          <RxDashboard
            size={30}
            color={`${active === 1 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            User Profile
          </h5>
        </Link>
      </div>

      
      <div className="w-full flex items-center p-4">
        <Link to="#" className="w-full flex items-center">
          <CiSettings
            size={30}
            color={`${active === 3 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default ArticleDashboardSideBar;
