import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";

const AllTasks = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/task/admin-all-tasks`, {withCredentials: true}).then((res) => {
        setData(res.data.tasks);
        setIsLoading(false);
    })
  }, []);

  const columns = [
    { field: "id", headerName: "Task Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
        field: "taskStatus",
        headerName: "Task Status",
        minWidth: 180,
        flex: 1.4,
      },
    {
      field: "taskPayment",
      headerName: "Payment",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "taskQuantity",
      headerName: "Quantity",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "totalTaskCompleted",
      headerName: "Total Task Completed",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/task/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  data &&
  data.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        taskStatus: item.taskStatus,
        taskPayment: "US$ " + item.taskPayment,
        taskQuantity: item.taskQuantity,
        totalTaskCompleted: item?.totalTaskCompleted,
      });
    });

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
    )}
        
    </>
  );
};

export default AllTasks;
