import axios from "axios";
import { server } from "../../server";

// Post Job
export const createDJob =
  (
    name,
    btdescription,
    description,
    category,
    taskPayment,
    taskDuration,
    durationMode,
    deadLine,
    tools,
    requiredSkill,
    taskEnvironment,
    resourceLink1,
    resourceLink2,
    resourceLink3,
    targetedCountry,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "djobCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/djob/create-job`,
    name,
    btdescription,
    description,
    category,
    taskPayment,
    taskDuration,
    durationMode,
    deadLine,
    tools,
    requiredSkill,
    taskEnvironment,
    resourceLink1,
    resourceLink2,
    resourceLink3,
    targetedCountry,
    shopId,
    images
      );
      dispatch({
        type: "djobCreateSuccess",
        payload: data.djob,
      });
    } catch (error) {
      dispatch({
        type: "djobCreateFail",
        payload: error.response.data.message,
      });
    }
  };


  // get all direct jobs
  export const getAllDJobs = () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllDJobsRequest",
      });
  
      const { data } = await axios.get(`${server}/djob/get-all-djobs`);
      dispatch({
        type: "getAllDJobsSuccess",
        payload: data.djobs,
      });
    } catch (error) {
      dispatch({
        type: "getAllDJobsFailed",
        payload: error.response.data.message,
      });
    }
  };


  // get All Direct Jobs of a shop
export const getAllDJobsShop = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "getAllDJobsShopRequest",
      });
  
      const { data } = await axios.get(
        `${server}/djob/get-all-djobs-shop/${id}`
      );
      dispatch({
        type: "getAllDJobsShopSuccess",
        payload: data.djobs,
      });
    } catch (error) {
      dispatch({
        type: "getAllDJobsShopFailed",
        payload: error.response.data.message,
      });
    }
  };


  // load djob
export const loadDJob = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadDJobRequest",
    });
    const { data } = await axios.get(`${server}/djob/get-djob`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadDJobSuccess",
      payload: data.djob,
    });
  } catch (error) {
    dispatch({
      type: "LoadDJobFail",
      payload: error.response.data.message,
    });
  }
};

  // delete stask of a shop
export const deleteDJob = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteDJobRequest",
      });
  
      const { data } = await axios.delete(
        `${server}/djob/delete-shop-djob/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteDJobSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteDJobFailed",
        payload: error.response.data.message,
      });
    }
  };

  