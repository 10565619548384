import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { createArticle } from "../../redux/actions/article";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import styles from "../../styles/styles";
import Spinner from "../Spinner/Spinner";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const CreateArticle = () => {
  const { user } = useSelector((state) => state.user);
  const { success, error } = useSelector((state) => state.articles);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  const [descValue, setDescValue] = useState("");
  const [minReads, setMinReads] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      ["link", "image", "video"],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ],
  };

  const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size", "font",
  ];

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Article created successfully!");
      navigate("/profile");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const description = descValue;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("minReads", minReads);
    newForm.append("date", date);
    newForm.append("category", category);
    newForm.append("userId", user._id);
    dispatch(
        createArticle({
        name,
        description,
        minReads,
        date,
        category,
        userId: user._id,
        images,
      })
    );
    setIsLoading(false);
  };

  return (
    <>
    {user && user?.staffRole === "Content Writer" ? (
      <>
      {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Post Article</h5>
      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Article Title..."
            maxLength={100}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <ReactQuill
           theme="snow"
           value={descValue}
           className="mt-2 h-[500px] overflow-scroll appearance-none block w-full pt-2 px-3 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
           onChange={setDescValue}
           modules={modules} 
           formats={formats}
           placeholder="write your content ...."
          />
        </div>
       
        <br />
        <div>
          <label className="pb-2">
           Reading Time: (e.g: 10 Min Reads)
          </label>
          <input
            type="text"
            name="date"
            value={minReads}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setMinReads(e.target.value)}
            placeholder="Enter Reading length..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Date: (April 5th, 2023)
          </label>
          <input
            type="text"
            name="date"
            value={date}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDate(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>
       
        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose a category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
       
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
          <br />
          <div>
            <input
              type="submit"
              value="Create"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <br />

          <div className={`${styles.noramlFlex} w-full`}>
              <h4>Having error <span className="text-[red]">'entity too large'?</span> please use Totlesoft Image Compressor to compress your picture to less than 40kb before re-uploading it</h4>
              </div>
              <div>
                <Link to="/image-compressor">
                <button
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Compress Image
              </button>
                </Link>
            </div>
        </div>
      </form>
    </div>
    )}
      </>
    ) : (
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Content Writers can access this route.
      </h1>
    )}
    
    </>
    
  );
};

export default CreateArticle;
