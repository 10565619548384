import react from "react";

const JobMessageCard = ({ senderName, message, time, date }) => {
    return(
        <>
        <div className="w-[90%] px-3 py-2 mb-4 bg-[#fff] text-[#000] shadow-lg rounded">
            <h4 className={`text-[16px] text-[#fff] px-2 py-1 ${senderName === "Client" ? "bg-[green]" : "bg-[#000]" } font-[600] rounded w-[80px]`}>{senderName}</h4>
            <h5 className="text-[16px] font-[500] whitespace-pre-line">{message}</h5>
            <div className="flex">
                <h5 className="text-[12px] mr-3">{time.slice(11, 16)}</h5>
                <h5 className="text-[12px]">{date.slice(0, 10)}</h5>
            </div>
        </div>
        </>
    )
}

export default JobMessageCard;