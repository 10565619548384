import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsedCodesOfAdmin } from "../../redux/actions/usedcode";

const AllUsedCodes = () => {
 
  const dispatch = useDispatch();
  
  const { adminUsedCodes, adminUsedCodesLoading } = useSelector(
    (state) => state.usedcode
  );

  useEffect(() => {
    dispatch(getAllUsedCodesOfAdmin());
  }, []);

  const columns = [
    { field: "id", headerName: "Code ID", minWidth: 150, flex: 0.7 },

    {
      field: "code",
      headerName: "Code",
      minWidth: 130,
      flex: 0.7,
      cellClassName: "greenColor" 
    },
    {
      field: "codeUser",
      headerName: "Code User",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to="#">
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  adminUsedCodes &&
  adminUsedCodes.forEach((item) => {
      row.push({
        id: item._id,
        code: item.code,
        codeUser: item.codeUser,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <>
    {adminUsedCodesLoading ? (
      <Spinner />
    ) : (
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
    )}
        
    </>
  );
};

export default AllUsedCodes;
