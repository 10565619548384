import React, { useEffect } from 'react'
import styles from "../styles/styles";
import logoo1 from "../Assests/logos/totlesoftPay.png";
import logoo2 from "../Assests/logos/appen.png";
import logoo2b from "../Assests/logos/prolific.png";
import logoo3 from "../Assests/logos/fiverr.png";
import logoo4 from "../Assests/logos/freelance.png";
import logoo5 from "../Assests/logos/oneformer.png";
import logoo6 from "../Assests/logos/remotasks.png";
import logoo7 from "../Assests/logos/upwork.png";
import "./companies.css";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function CompaniesScroll() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()


  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <div>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] py-8`}>
        <div className={`${styles.heading} py-8`}>
          <h1>{t('Counterparts')}</h1>
         <section className="companies marquee">
          <div className="marquee__inner" aria-hidden="true">
          <img src={logoo1} alt="totlesoft-pay" className="companies__logo" />
          <img src={logoo2} alt="appen" className="companies__logo" />
          <img src={logoo2b} alt="prolific" className="companies__logo" />
          <img src={logoo3} alt="fiverr" className="companies__logo" />
          <img src={logoo4} alt="freelance" className="companies__logo" />
          <img src={logoo5} alt="oneformer" className="companies__logo" />
          <img src={logoo6} alt="remotasks" className="companies__logo" />
          <img src={logoo7} alt="upwork" className="companies__logo" />
          
          <img src={logoo1} alt="totlesoft-pay" className="companies__logo" />
          <img src={logoo2} alt="appen" className="companies__logo" />
          <img src={logoo2b} alt="prolific" className="companies__logo" />
          <img src={logoo3} alt="fiverr" className="companies__logo" />
          <img src={logoo4} alt="freelance" className="companies__logo" />
          <img src={logoo5} alt="oneformer" className="companies__logo" />
          <img src={logoo6} alt="remotasks" className="companies__logo" />
          <img src={logoo7} alt="upwork" className="companies__logo" />
          </div>
          </section>
          </div>
        </div>
    </div>
  )
}

export default CompaniesScroll