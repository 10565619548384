import React from 'react';
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Hero2 from "../components/Route/Hero/Hero2";

const TranscriptionPage = () => {
    return (
        <div>
            <Header />
            <Hero2 />
            <Footer />
        </div>
    );
};

export default TranscriptionPage;
