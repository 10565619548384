import React from "react"
import OPS from "../components/OPS/OPS";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const OPSPage = () => {
  return (
    <div>
      <Header />
      <OPS />
      <Footer />
    </div>
  );
};


export default OPSPage 

