import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllDJobsShop, deleteDJob } from "../../redux/actions/djob";
import Spinner from "../Spinner/Spinner";

const AllDJobs = () => {
  const { djobs, isLoading } = useSelector((state) => state.djobs);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDJobsShop(seller._id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteDJob(id));
    window.location.reload();
  };

  console.log("djobs are:");
  console.log(djobs);

  const columns = [
    { field: "id", headerName: "Job Id", minWidth: 150, flex: 1 },
    {
      field: "name",
      headerName: "Title",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "taskPayment",
      headerName: "Payment",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "acceptedBy",
      headerName: "Worker",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "samples",
      headerName: "Samples",
      minWidth: 80,
      flex: 0.3,
    },
    {
        field: "workStatus",
        headerName: "Job Status",
        minWidth: 80,
        flex: 0.3,
      },
      {
        field: "deadLine",
        headerName: "Deadline",
        minWidth: 80,
        flex: 0.3,
      },
    {
      field: "date",
      headerName: "Listed On",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/djob/view-samples/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  djobs &&
    djobs.forEach((item) => {
      row.push({
        id: item?._id,
        name: item?.name,
        taskPayment: "$ " + item.taskPayment,
        acceptedBy: item?.acceptedBy,
        samples: item?.samples,
        workStatus: item?.workStatus,
        deadLine: item?.deadLine,
        date: item?.createdAt.slice(0, 10),
      });
    });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllDJobs;
