import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function Branding() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

   return (
    <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
      <br />
      <br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-15px`}
        >
          {t('b_title')}
        </h1>

        <div className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
        <p>
        {t('brdp_p1')}
        </p>
        <br />
        <p>
        {t('brd_p2')}
        </p>
        <br />
        <p className="font-[600]">{t('brd_p2bt')}</p>
        <p>{t('brd_p2b')}</p>
          
          <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('brd_Connect1')}
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <p className="font-[600]">{t('brd_p3t')}</p>
        <p>{t('brd_p31')}<br />
          {t('brd_p32')}</p>
          <br />
          <br />

          <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://totlesoft.vercel.app/static/media/designImg5.5e55aa50aeb91822d267.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('brd_p4t')}✅</p>
        <p>
        {t('brd_p4')}
        </p>
        <br />
        <br />

        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/jJwkpLx/design-Img7b.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/b5FYkwM/design-Img7.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/WcvMsm9/design-Img5b.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/QvQBkGV/design-Img3.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/PNhMvBN/design-Img3b.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('brd_p5t')}✅</p>
        <p>
        {t('brd_p5')}
        </p>
        <br />
        <br />

        <p className="font-[600]">{t('brd_p6t')}✅</p>
        <p>
        {t('brd_p6')}
        </p>
        <br />
        <br />


        <div className="sm:block flex items-center justify-center w-[80%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://totlesoft.vercel.app/static/media/designImg1.f0f9153df2a2e0dc844e.jpg"
            alt=""
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('brd_p7t')}✅</p>
        <p>
        {t('brd_p7')}
        </p>
        <br />
        <br />


        <p className="font-[600]">{t('brd_p8t')}✅</p>
        <p>
        {t('brd_p8')}
        </p>
        <br />
        <br />

        <p className="font-[600]">{t('brd_lc')}</p>


        <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('brd_Connect2')}
                 </span>
            </div>
        </Link>
        <br />
        <br />


        </div>
        <br />
        <br />
       
      </div>
  )
}

export default Branding

/*
<img src="https://i.ibb.co/QvQBkGV/design-Img3.jpg" alt="design-Img3" border="0">
<img src="https://i.ibb.co/PNhMvBN/design-Img3b.jpg" alt="design-Img3b" border="0">
<img src="https://i.ibb.co/WcvMsm9/design-Img5b.jpg" alt="design-Img5b" border="0">
<img src="https://i.ibb.co/b5FYkwM/design-Img7.jpg" alt="design-Img7" border="0">
<img src="https://i.ibb.co/jJwkpLx/design-Img7b.jpg" alt="design-Img7b" border="0">
*/