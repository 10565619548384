import React, { useState, useEffect } from 'react';
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import axios from "axios";
import MostRead from "../../pages/ResourceCenter/MostRead";
import Spinner from "../../components/Spinner/Spinner";


const ArticleDetails = () => {
    const [allArt, setAllAt] = useState([]);
    const [dataIsLoading, setDataIsLoading] = useState(false);
    
    const { id } = useParams();
   
    useEffect(() => {
        setDataIsLoading(true);
        axios.get(`${server}/article/get-all-articles`, {withCredentials: true}).then((res) => {
            setAllAt(res.data.articles);
            setDataIsLoading(false);
        })
      }, []);

      const data = allArt && allArt.find((itt) => itt._id === id);


    return (
        <div className='w-full'>
        {dataIsLoading ? <Spinner /> : (
          <>
          <Header />
        <br />
        
        {data ? (<>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] my-4`}>
        <h1
          className={`!text-[35px] leading-[1] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          {data?.name}
        </h1>
        <div className='mt-2 flex'>
        <img className='mr-2 w-[50px] h-[50px] rounded-full object-cover border-[3px] border-[#fff]' alt='Article cover' src={data?.user.avatar.url}></img>
        <div>
        <h5 className='text-[13px] text-[blue]'>By: {data?.user.name}</h5>
        <div className='flex mb-3 text-[12px]'>
            <h5 className='mr-2'>{data?.date}</h5>
            <span>{data?.minReads}</span>
        </div>
        </div>
        </div>
        <p className="pt-5 text-[16px] text-[#000000ba] leading whitespace-pre-line" dangerouslySetInnerHTML={{ __html: data?.description }}>
        </p>
        <br />
        <hr />
        <br />
       
        <div>
        <div className='mt-2 flex'>
        <img className='mr-2 w-[80px] h-[80px] rounded-full object-cover border-[3px] border-[#fff]' alt='Article cover' src={data?.user.avatar.url}></img>
        <div>
        <h5 className='text-[20px] font-[500]'>Author Spotlight</h5>
        <h5 className='text-[15px] text-[blue]'>{data?.user.name}</h5>
        <div className='flex mb-3 text-[15px]'>
            <h5 className='mr-2'>Content Writer</h5>
        </div>
        </div>
        </div>
        <p className='flex mb-3 text-[15px]'>
        {data?.user.spotLight}
        </p>
        </div>
        </div>
        <div>
      <br />
      
      <div className={`${styles.section} bg-[#fff] py-1 px-2`}>
      <h2 className='text-[25px] font-[600] pl-4 mb-1'>Most Read</h2>
        <MostRead />
       </div>
      </div>
        </>) : null}
          </>
        )}
    </div>
    )
}

export default ArticleDetails;

