import React from "react"
import IVInvites from "../../components/Contact/IVInvites";

const IVInvitesPage = () => {
  return (
    <div>
      <IVInvites />
      </div>
  );
};


export default IVInvitesPage 

