import React from 'react'
import UserWithdrawDashboardHeader from '../components/UserWithdrawDashboardHeader';
import UserWithdrawMoney from "../components/UserWithdrawMoney";
import UserWithdrawDashboardSideBar from "../components/UserWithdrawDashboardSideBar";

const UserWithDrawMoneyPage = () => {
  return (
    <div>
    <UserWithdrawDashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <UserWithdrawDashboardSideBar active={2} />
      </div>
       <UserWithdrawMoney />
    </div>
  </div>
  )
}

export default UserWithDrawMoneyPage