import axios from "axios";
import { server } from "../../server";

// create videotask
export const createVideoTask =
  (
    name,
    description,
    watchTime,
    category,
    taskPayment,
    paymentRef,
    taskBudget,
    taskQuantity,
    videoID,
    targetedCountry,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "videotaskCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/videotask/create-videotask`,
        name,
        description,
        watchTime,
        category,
        taskPayment,
        paymentRef,
        taskBudget,
        taskQuantity,
        videoID,
        targetedCountry,
        shopId,
        images
      );
      dispatch({
        type: "videotaskCreateSuccess",
        payload: data.videotask,
      });
    } catch (error) {
      dispatch({
        type: "videotaskCreateFail",
        payload: error.response.data.message,
      });
    }
  };


  // get all videotasks
  export const getAllVideoTasks = () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllVideoTasksRequest",
      });
  
      const { data } = await axios.get(`${server}/videotask/get-all-videotasks`);
      dispatch({
        type: "getAllVideoTasksSuccess",
        payload: data.videotasks,
      });
    } catch (error) {
      dispatch({
        type: "getAllVideoTasksFailed",
        payload: error.response.data.message,
      });
    }
  };


  // get All VideoTasks of a shop
export const getAllVideoTasksShop = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "getAllVideoTasksShopRequest",
      });
  
      const { data } = await axios.get(
        `${server}/videotask/get-all-videotasks-shop/${id}`
      );
      dispatch({
        type: "getAllVideoTasksShopSuccess",
        payload: data.videotasks,
      });
    } catch (error) {
      dispatch({
        type: "getAllVideoTasksShopFailed",
        payload: error.response.data.message,
      });
    }
  };


  // load task
export const loadVideoTask = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadVideoTaskRequest",
    });
    const { data } = await axios.get(`${server}/videotask/get-videotask`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadVideoTaskSuccess",
      payload: data.videotask,
    });
  } catch (error) {
    dispatch({
      type: "LoadVideoTaskFail",
      payload: error.response.data.message,
    });
  }
};

  // delete videotask of a shop
export const deleteVideoTask = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteVideoTaskRequest",
      });
  
      const { data } = await axios.delete(
        `${server}/videotask/delete-shop-videotask/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteVideoTaskSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteVideoTaskFailed",
        payload: error.response.data.message,
      });
    }
  };

  