import React from 'react';
import { Link } from "react-router-dom";

const DJobCard = ({data, postAt, filterFunction}) => {
    return (
        
        <div className={`max-w-5xl md:block shadow-lg mx-auto rounded md:flex md:justify-between md:tems-center bg-white p-6 m-6 mb-16 sm:mb-0 ${data?.taskStatus === "Open" && 'border-l-4 border-[#000]'}`}>
            <div className='md:flex md:items-center'>
                <div>   
                    <span className='text-cyan font-bold text-sm m-2'>{data?.shop.name}</span>
                    {data?.taskStatus === "Open" && <span className='bg-[#c89b2a] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl'>Open !</span>}
                    {data?.taskStatus === "Closed" && <span className='bg-[black] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl mx-2'>Closed</span>}
                    <h1 className='text-black font-medium md:text-xl m-2'>{data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}</h1>
                    <div>
                        <span className='text-dark_gray_cyan text-sm m-2'>{data?.createdAt.slice(0, 10)}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>${data?.taskPayment}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{data?.targetedCountry}</span>
                       <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        {data?.acceptedBy === "Not Yet Accepted" ? <Link to={`/djob/${data._id}`}><span className='text-[blue] text-sm m-2 cursor-pointer'>Accept Job</span></Link> : <span className='text-dark_gray_cyan text-sm m-2'>Accepted</span>}
                        <span className='bg-[red] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl'>deadline: {data?.deadLine}</span>
                    </div>
                </div>
            </div>
            <div className='w-full border-t my-2 border-[gray] md:hidden'></div>
            <div className='my-auto'>
                <button onClick={filterFunction} className ='bg-[#d9dddc] m-2 text-sm p-2 text-cyan focus:outline-none cursor-pointer hover:bg-[black] hover:text-white font-semibold rounded'>{data?.category}</button> 
                {data?.acceptedBy !== "Not Yet Accepted" ? null : <Link to={`/djob/${data._id}`}><button className ='bg-[#F6F6F5] text-sm focus:outline-none m-2 p-2 cursor-pointer text-cyan hover:bg-[#c89b2a] hover:text-white font-semibold rounded'>See More Details</button></Link>}
            </div>
        </div> 
       )
}

export default DJobCard;

