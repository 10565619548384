import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createEventAd } from "../../redux/actions/eventAd";

const CreateEventAd = () => {
  const { seller } = useSelector((state) => state.seller);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [name, setName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [eventCoverAddress, setEventCoverAddress] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventBy, setEventBy] = useState("");
  const [poweredBy, setPoweredBy] = useState("");
  const [supportedBy, setSupportedBy] = useState("");
  const [speaker1, setSpeaker1] = useState("");
  const [speaker2, setSpeaker2] = useState("");
  const [speaker3, setSpeaker3] = useState("");
  const [speaker4, setSpeaker4] = useState("");
  const [speaker5, setSpeaker5] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

 
    const handleStartDateChange = (e) => {
    const startDate = new Date(e.target.value);
    const minEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    setStartDate(startDate);
    setEndDate(null);
    document.getElementById("end-date").min = minEndDate.toISOString.slice(
      0,
      10
    );
  };

  const handleEndDateChange = (e) => {
    const endDate = new Date(e.target.value);
    setEndDate(endDate);
  };

  const today = new Date().toISOString().slice(0, 10);

  const minEndDate = startDate
    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10)
    : "";

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newForm = new FormData();

    newForm.append("name", name);
    newForm.append("eventDate", eventDate);
    newForm.append("eventTime", eventTime);
    newForm.append("eventVenue", eventVenue);
    newForm.append("eventCoverAddress", eventCoverAddress);
    newForm.append("eventLink", eventLink);
    newForm.append("eventBy", eventBy);
    newForm.append("poweredBy", poweredBy);
    newForm.append("supportedBy", supportedBy);
    newForm.append("speaker1", speaker1);
    newForm.append("speaker2", speaker2);
    newForm.append("speaker3", speaker3);
    newForm.append("speaker4", speaker4);
    newForm.append("speaker5", speaker5);
    newForm.append("shopId", seller._id);
    newForm.append("shopEmail", seller.email);
    newForm.append("start_Date", startDate?.toISOString());
    newForm.append("finish_Date", endDate?.toISOString());
    dispatch(
        createEventAd({
      name,
      eventDate,
      eventTime,
      eventVenue,
      eventCoverAddress,
      eventLink,
      eventBy,
      poweredBy,
      supportedBy,
      speaker1,
      speaker2,
      speaker3,
      speaker4,
      speaker5,
      shopId: seller._id,
      shopEmail: seller.email,
      start_Date: startDate?.toISOString(),
      finish_Date: endDate?.toISOString(),
        })
    );
    toast.success("Ad created successfully!");
    navigate("/dashboard");
    window.location.reload();
};


  return (
    <>
    {seller && seller?.role === "ProSeller" ? (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Event Ad</h5>
      {/* create eventAd form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your event title..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Date <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventDate"
            value={eventDate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEventDate(e.target.value)}
            placeholder="Enter event date..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Time <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventTime"
            value={eventTime}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEventTime(e.target.value)}
            placeholder="Enter event time..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Venue <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventVenue"
            value={eventVenue}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEventVenue(e.target.value)}
            placeholder="Enter event venue..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Event By <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventBy"
            value={eventBy}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEventBy(e.target.value)}
            placeholder="Enter event organizer..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
         Cover (Image) Address <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventCoverAddress"
            value={eventCoverAddress}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEventCoverAddress(e.target.value)}
            placeholder="Enter event image address..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
         Event Link <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventLink"
            value={eventLink}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEventLink(e.target.value)}
            placeholder="Enter event link..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Powered By <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="poweredBy"
            value={poweredBy}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setPoweredBy(e.target.value)}
            placeholder="sponsorer..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Supported By <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="supportedBy"
            value={supportedBy}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSupportedBy(e.target.value)}
            placeholder="supporter..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Speaker 1 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="speaker1"
            value={speaker1}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpeaker1(e.target.value)}
            placeholder="speaker..."
          />
        </div>
        <br />


        <div>
          <label className="pb-2">
          Speaker 2 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="speaker2"
            value={speaker2}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpeaker2(e.target.value)}
            placeholder="speaker..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Speaker 3 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="speaker3"
            value={speaker3}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpeaker3(e.target.value)}
            placeholder="speaker..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Speaker 4 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="speaker4"
            value={speaker4}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpeaker4(e.target.value)}
            placeholder="speaker..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Speaker 5 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="speaker5"
            value={speaker5}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpeaker5(e.target.value)}
            placeholder="speaker..."
          />
        </div>
        <br />
        
       
        
        <div>
          <label className="pb-2">
            Event Start Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="price"
            id="start-date"
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleStartDateChange}
            min={today}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Event End Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="price"
            id="start-date"
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleEndDateChange}
            min={minEndDate}
            placeholder="Enter your event product stock..."
          />
        </div>
        
        <div>
        <br />
          <div>
            <input
              type="submit"
              value="Create Ad"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </form>
    </div>
    ):(
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Only ProSellers Can Create Events Ad.
      </h1>
    )}
    </>
    
  );
};

export default CreateEventAd;
