import axios from "axios";
import { server } from "../../server";

// create Gig
export const createTalent =
  (
        name,
        description,
        charge,
        subscriptionAmount,
        access1URL,
        access2URL,
        access3URL,
        access4URL,
        access5URL,
        category,
        userId,
        userEmail,
        images,
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "talentCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/talent/create-talent-package`,
        name,
        description,
        charge,
        subscriptionAmount,
        access1URL,
        access2URL,
        access3URL,
        access4URL,
        access5URL,
        category,
        userId,
        userEmail,
        images,
      );
      dispatch({
        type: "talentCreateSuccess",
        payload: data.talent,
      });
    } catch (error) {
      dispatch({
        type: "talentCreateFail",
        payload: error.response.data.message,
      });
    }
  };


  // get all talent
  export const getAllTalents = () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllTalentsRequest",
      });
  
      const { data } = await axios.get(`${server}/talent/get-all-talents`);
      dispatch({
        type: "getAllTalentsSuccess",
        payload: data.talents,
      });
    } catch (error) {
      dispatch({
        type: "getAllTalentsFailed",
        payload: error.response.data.message,
      });
    }
  };


  // get All Talents Gigs Created by a user
export const getAllTalentsUser = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "getAllTalentsUserRequest",
      });
  
      const { data } = await axios.get(
        `${server}/talent/get-all-talents-user/${id}`
      );
      dispatch({
        type: "getAllTalentsUserSuccess",
        payload: data.talents,
      });
    } catch (error) {
      dispatch({
        type: "getAllTalentsUserFailed",
        payload: error.response.data.message,
      });
    }
  };


  // load talent
export const loadTalent = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadTalentRequest",
    });
    const { data } = await axios.get(`${server}/talent/get-talent`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadTalentSuccess",
      payload: data.talent,
    });
  } catch (error) {
    dispatch({
      type: "LoadTalentFail",
      payload: error.response.data.message,
    });
  }
};

  // delete talent package of a user
export const deleteTalent = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteTalentRequest",
      });
  
      const { data } = await axios.delete(
        `${server}/talent/delete-user-talent-package/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteTalentSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteTalentFailed",
        payload: error.response.data.message,
      });
    }
  };

  