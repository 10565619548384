import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";


const TestsPage = () => {
   
  return (
    <div>
        <Header />
        <Tests />
        
        <Footer />
    </div>
  )
}

const Tests = () => {
    const { user } = useSelector((state) => state.user);


    const testMessage = () => {
        toast.error("Test List Empty!")
    }

    return (
        <>
    <div className="mt-1 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>

      
        <h1 className={`text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}>
          Test<span className="text-[#c89b2a]">Center</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Complete any of the test bellow to qualify for job on that category:
        </p>
        <br />

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
                <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Audio/Video Transcription Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer"><Link to="/tests/audio-transcription-tests">View</Link></h5>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Typying Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>
                                         
              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Content Writing Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">English (UK) Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">English (US) Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>
              
              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Programming Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Graphics Design Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Annotation Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Categorization Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Rating Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Tagging Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Crypto Trading Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Translation Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <br />
              <br />

                     
        
        </div>
        </div>
        </div>
        </>
    )
}

export default TestsPage
