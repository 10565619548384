import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const eventAdReducer = createReducer(initialState, {
  eventAdCreateRequest: (state) => {
    state.isLoading = true;
  },
  eventAdCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.eventAd = action.payload;
    state.success = true;
  },
  eventAdCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // delete eventAd of a shop
  deleteEventAdRequest: (state) => {
    state.isLoading = true;
  },
  deleteEventAdSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteEventAdFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  
});

