import React from "react";
import styles from "../../../styles/styles";




const Intro = () => {

  
  return (
   <div className="!bg-[#fff] w-full">
      <div className={`${styles.section} bg-[#fff] p-4`}>
        <h2 className="mb-3 mt-6 text-center text-[25px]">Direct access to High Paying Remote Tasks/Jobs - Make $40 - $1000+ monthly working from home!</h2>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-6">


        <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-5 py-3 relative cursor-pointer">
          <h4 className="bg-[#c89b2a] py-1 px-1 text-[30px] font-[600] text-[#fff] text-center rounded-xl">
           STEP 1 
          </h4>
          <h5 className='text-center text-[15px] text-[#fff] mt-2'>Create account</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-5 py-3 relative cursor-pointer">
          <h4 className="bg-[#c89b2a] py-1 px-1 text-[30px] font-[600] text-[#fff] text-center rounded-xl">
           STEP 2 
          </h4>
          <h5 className='text-center text-[15px] text-[#fff] mt-2'>Take a test</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-5 py-3 relative cursor-pointer">
          <h4 className="bg-[#c89b2a] py-1 px-1 text-[30px] font-[600] text-[#fff] text-center rounded-xl">
           STEP 3 
          </h4>
          <h5 className='text-center text-[15px] text-[#fff] mt-2'>Start completing tasks</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-5 py-3 relative cursor-pointer">
          <h4 className="bg-[#c89b2a] py-1 px-1 text-[30px] font-[600] text-[#fff] text-center rounded-xl">
           STEP 4 
          </h4>
          <h5 className='text-center text-[15px] text-[#fff] mt-2'>Receive payment</h5>
          </div>

          <div className="w-full h-[110px] bg-[#012] rounded-lg shadow-lg px-5 py-3 relative cursor-pointer">
          <h4 className="bg-[#c89b2a] py-1 px-1 text-[30px] font-[600] text-[#fff] text-center rounded-xl">
           STEP 5 
          </h4>
          <h5 className='text-center text-[15px] text-[#fff] mt-2'>Cash out</h5>
          </div>

          
        </div>

        <h2 className="mb-3 text-center text-[18px]">flexible withdrawal methods: Bank Transfer, Crypto, PayPal, Payoneer, Gift Cards</h2>
        </div>
    </div>
    );
};

export default Intro;