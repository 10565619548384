import axios from "axios";
import { server } from "../../server";

// Post an Article
export const createArticle =
  (
    name,
    description,
    minReads,
    date,
    category,
    userId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "articleCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/article/post-article`,
    name,
    description,
    minReads,
    date,
    category,
    userId,
    images
      );
      dispatch({
        type: "articleCreateSuccess",
        payload: data.article,
      });
    } catch (error) {
      dispatch({
        type: "articleCreateFail",
        payload: error.response.data.message,
      });
    }
  };

// get All Articles of a user
export const getAllArticlesUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllArticlesUserRequest",
    });

    const { data } = await axios.get(
      `${server}/article/get-all-articles-user/${id}`
    );
    dispatch({
      type: "getAllArticlesUserSuccess",
      payload: data.articles,
    });
  } catch (error) {
    dispatch({
      type: "getAllArticlesUserFailed",
      payload: error.response.data.message,
    });
  }
};

// get all articles
export const getAllArticles = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllArticlesRequest",
    });

    const { data } = await axios.get(`${server}/article/get-all-articles`);
    dispatch({
      type: "getAllArticlesSuccess",
      payload: data.articles,
    });
  } catch (error) {
    dispatch({
      type: "getAllArticlesFailed",
      payload: error.response.data.message,
    });
  }
};
