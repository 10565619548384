import React from 'react';
import { Link } from "react-router-dom";
import styles from "../../styles/styles";

function RCHomeFoot() {
   
  return (
    <div className='w-full'>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className='w-full 800px:w-[50%] mb-2'>
        <br />
        <img
            src="https://res.cloudinary.com/totlesoft/image/upload/v1712330595/freelancer2_gu8rfx.jpg"
            alt=""
            className="w-[90%] p-3"
          />
       </div>
        
        <div className='className={`${styles.section} w-full 800px:w-[50%] 800px:mt-8'>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          Take Charge 
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Either as a freelancer or client it is important you understand how to take advantage of the Totlesoft Workplace to earn or pay. Our team of tech professionals are ready to get you started.  
        </p>
        
        
      <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 Book Demo
                 </span>
            </div>
        </Link>
        <br />
        <br />
        </div>

        </div>

        <div className={`${styles.section} w-[90%] 800px:w-[70%] my-4`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          Work Remotely
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Remote work is becoming a choice for many small businesses and enterprises alike. It is even becoming more aceptable that employees don’t have to be physically present in an office to get results. In the ever-evolving quest to remain competitive through agility, freelancing is shaping up to be the agile talent-sourcing solution of the future and Totlesoft is proud to identify with this trend. 
        </p>
        <br />
        <br />
        </div>
    </div>
  )
}

export default RCHomeFoot