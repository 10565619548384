import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const CVendors = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <br /><br />
        <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Certified Code Vendors 
        </h1> 
        <h2 className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
            To upgrade your account, you will need a coupon code. Kindly contact any of our certified vendors base on your location and currency. 
        </h2>
        <h2 className="text-[20px] text-[green] font-[400]">Current Upgrade Fee = $5</h2>
        <br />
        <hr />
        <br />
        
       
        
        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#fffdd0] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Totlesoft DMP <span className="text-[15px] text-[green] ml-3 font-[400]">USA/UK/Other Countries</span>
              </h3><br />
            </div>
            <div>
            <h5 className="pt-4 pl-2">
            Contact us now and you will receive your requested code on your registered user email.</h5>
            <Link to="https://wa.me/p/7171100256335499/447418375846">
            <button 
            className={`${styles.button} text-[#fff] justify-center items-center text-center`}
            >
               Contact
              </button>
            </Link>
            </div>
            <h5 className="pl-2 text-[14px]">TVN00015AC</h5>
        </div>
        </div>
        <br />


        <div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Dannyz Media <span className="text-[15px] text-[green] ml-3 font-[400]">Nigeria</span>
              </h3><br />
            </div>
            <div>
            <h5 className="pt-4 pl-2">
            I am always active and respond to request swiftly. Contact me now for fast delivery.</h5>
            <Link to="https://wa.me/2348073770514">
            <button 
            className={`${styles.button} text-[#fff] justify-center items-center text-center`}
            >
               Contact
              </button>
            </Link>
            </div>
            <h5 className="pl-2 text-[14px]">TVN00020AC</h5>
        </div>
        </div>
        <br />

     

        <br />
        <h2 className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
            Interested in becoming a code vendor in your location? please click the button bellow to see if you qualify; 
            or send an email to us at <span className="text-[blue]">info@totlesoft.com</span>:
        </h2>
        <Link to="/cvendors-application">
            <button 
            className={`${styles.button} text-[#fff] justify-center items-center text-center`}
            >
               Apply
              </button>
            </Link>
            <br />
            <br />
            <br />
      </div>
    </div>
  );
};

const CVendorsPage = () => {
    return (
      <div>
        <Header />
        <CVendors />
        <Footer />
      </div>
    );
  };

export default CVendorsPage;

/*
<div className="w-full p-4 block 800px:flex">
          <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#d8ffb1] shadow rounded px-3 py-5">
            <div className="flex">
             <h3
                className={`${styles.productTitle} !text-[20px] leading-5 !font-[600] text-[#00000085]`}
              >
                Omarlay Jacob <span className="text-[15px] text-[green] ml-3 font-[400]">USA</span>
              </h3><br />
            </div>
            <div>
            <h5 className="pt-4 pl-2">
            If you are not ready to purchase your activation code please don't clink the "Contact" button. Thanks. Go ahead! I am online.</h5>
            <Link to="#">
            <button 
            className={`${styles.button} text-[#fff] justify-center items-center text-center`}
            >
               Contact
              </button>
            </Link>
            </div>
            <h5 className="pl-2 text-[14px]">TVN00010AC</h5>
        </div>
        </div>
        */