import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

function Hero2() {
    const { user } = useSelector((state) => state.user);
    const { seller } = useSelector((state) => state.seller);

  return (
    <div className='w-full'>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        
        <div className={`${styles.section} w-full 800px:w-[50%]`}>
        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          100% Human-<span className="text-[#c89b2a]">Made</span><br /> Transcription Services
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        By creating the right campaign or using TotleSoft Direct Hire, you are guaranteed a bunch of qualified transcribers that specializes in providing top-tier transcription services, transcribing both audio and video content. With a focus on delivering the best transcription services online, TotleSoft will ensure that every video transcript and audio transcription are meticulously handled by only the professional transcribers. Whether you're looking for a detailed video transcription online or require professional assistance in transcribing complex audio files, our platform is your go-to solution. Get your files transcribed today!  
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Taste the experience of the synergy of human precision and automated efficiency with TotleSoft transcription solutions for continuous, accurate transcription and captioning.
        </p>
        
       {user || seller ? (
          <Link to="#" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Order Now
               </span>
          </div>
      </Link>
        ):(
          <Link to="/sign-up" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 Get Started
                 </span>
            </div>
        </Link>
        )}
        <br />
        <br />
        </div>

        <div className='px-6 w-full 800px:w-[50%]'>
        <br />
        <img
            src="https://res.cloudinary.com/totlesoft/image/upload/v1712330594/freelancer1_vajsy8.jpg"
            alt=""
            className="w-[90%] p-3"
          />
        
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         How it works?
        </h1>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-2">Upload Files</span> - Via Web or Link <br />
        * <span className="font-[500] mb-2">Pay Online</span> - With various option PayPal, Card, Crypto <br />
        * <span className="font-[500] mb-4">Receive Your Files</span> - Delivered via email or WhatsApp file <br />
        </p>
        <div className="sm:block flex items-center justify-center w-full" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://res.cloudinary.com/totlesoft/image/upload/v1708516034/a_logos_small_ixkzlz.png"
            alt=""
          />
        </div>
        <h5 className='text-[16px] ml-2 mt-4'>Unmatched expertise at affordable rates tailored for your needs.</h5>
        <br />
        </div>
        </div>


        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className={`${styles.section} w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         Difficulty?
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Yes, we handle even difficult audio 
       </p>
        <br />

        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         From $0.82/min
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Best Industry price you can find. 
       </p>
        <br />

        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         99%+ Accuracy
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
       Quality transcription is guaranteed. 
       </p>
        <br />
        </div>

        <div className={`${styles.section} w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         Pricing
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Transcription</span> - From $0.82 per minute <br />
        * <span className="font-[500] mb-3">Captions</span> - From $1.21 per minute <br />
        * <span className="font-[500] mb-3">Automated Transcripts</span> - From $0.20 per minute <br />
        * <span className="font-[500] mb-3">Foreign Subtitles</span> - From $11.70 per minute <br />
        * <span className="font-[500]">Audio Translation</span> - From $8.80 per minute <br /></p>
        <br />
        
        </div>
        </div>

    </div>
  )
}

export default Hero2
