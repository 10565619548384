import React from "react";
import CodeVendorHeader from "../components/Layout/CodeVendorHeader";
import CodeVendorSideBar from "../components/CodeVendor/Layout/CodeVendorSideBar";
import CodeVendorDashboardMain from "../components/CodeVendor/CodeVendorDashboardMain";

const CodeVendorDashboardPage = () => {
  return (
    <div>
      <CodeVendorHeader />
      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[80px] 800px:w-[330px]">
            <CodeVendorSideBar active={1} />
          </div>
          <CodeVendorDashboardMain />
        </div>
      </div>
    </div>
  );
};

export default CodeVendorDashboardPage;
