import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { getAllOrdersOfShop } from "../../redux/actions/order";


const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 1 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.6,
      
    },
    {
      field: "user",
      headerName: "Placed By",
      minWidth: 130,
      flex: 0.8,
      
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 130,
      flex: 0.8,
      
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.5,
    },

    {
      field: "date",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.5,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

  ];

  
  const row = [];

  orders && orders.forEach((item) => {
    row.push({
        id: item._id,
        itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
        total: "$ " + item.totalPrice,
        user: item.user.name,
        location: item.user.country,
        status: item.status,
        date: item.createdAt.slice(0, 10),
      });
  });

  return (
    <>
    {seller && seller?.role === "ProSeller" ? (
      <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
      </>
    ):(
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Only ProSellers Can Access this Route.
      </h1>
    )}
      
    </>
  );
};

export default AllOrders;
