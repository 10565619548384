import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { getAllNewCodesOfUser } from "../../redux/actions/newcode";

const NewCodes = () => {
  
 const dispatch = useDispatch();
 const { user } = useSelector((state) => state.user);
 
  const { newcodes, isLoading } = useSelector(
    (state) => state.newcode
  );

  useEffect(() => {
    dispatch(getAllNewCodesOfUser(user._id));
  }, []);

  
  const columns = [
    { field: "id", headerName: "Code ID", minWidth: 130, flex: 0.7 },
    {
      field: "code",
      headerName: "Code",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "codeCreator",
      headerName: "Creator",
      minWidth: 80,
      flex: 0.8,
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 80,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 80,
      flex: 0.8,
    },
  ];

  const row = [];

  newcodes &&
    newcodes.forEach((item) => {
      row.push({
        id: item._id,
        status: item.status,
        code: item.code,
        codeCreator: item.codeCreator,
        time: item.createdAt.slice(11, 16),
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default NewCodes;

