import React, {useState} from 'react';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

/*Javascript module to be run in the web browser for image compression.*/
import imageCompression from "browser-image-compression";

/*Including css file*/
import './Compressor.css'

/*Importing Upload and Download Image Icon*/
import Download from '../../Assests/images/Download.png';
import Upload from '../../Assests/images/Upload.png';

/*Importing NavBar Component to create a Beautiful Navbar and Card Component from Bootstrap */
import {Card} from "react-bootstrap";


//This function will be exported to App.js
function Compressor(){


  const [compressedLink, setCompressedLink ] = useState("");
  const [originalImage, setOriginalImage ] = useState("");
  const [originalLink, setOriginalLink ] = useState("");
  const [clicked, setClicked ] = useState(false);
  const [uploadImage, setUploadImage ] = useState(false);
  const [outputFileName, setOutputFileName ] = useState("");

  //It will handle upload part of image
  function uploadLink(event){
    //To get the image file which user had uploaded in the input field
    const imageFile = event.target.files[0];
    // Create a DOMString containing a URL that represents the imageFile
    setOriginalLink(URL.createObjectURL(imageFile));
    //Now set the original image link, name of output file and upload image state
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
    setUploadImage(true);
  }

  //This will be called when user clicks on Compress Button
  function click(e){
    e.preventDefault();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 200,
      useWebWorker: true
    };

    if (options.maxSizeMB >= originalImage.size / 1024) {
      alert("Bring a bigger image");
      return 0;
    }

    //this code will compress the original image
    let output;
    imageCompression(originalImage, options).then(x => {
      output = x;

      const downloadLink = URL.createObjectURL(output);
      setCompressedLink(downloadLink);
    });

    setClicked(true);
    return 1;
  };

  return(
    <div className="mainContainer">
       
      
        <div className="mt-5">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            {uploadImage ? (
              <img
              src={originalLink}
              alt=""
              className="w-full h-[300px] object-contain px-8 py-4"
            />
            ) : (
              <img
              src={Upload}
              alt=""
              className="w-full h-[300px] object-contain px-8 py-4"
            />
            )}
            <div className="d-flex justify-content-center upload-btn-wrapper mt-5">
            <button className="btn btn-dark cursor-pointer">Upload Image</button>
              <input
                type="file"
                accept="image/*"
                className="mt-2 btn btn-dark w-75"
                onChange={event => uploadLink(event)}
              />
          </div>
          </div>
          
          <div className="col-xl-4 col-lg-4 col-md-12 mb-5 mt-4 col-sm-12 d-flex justify-content-center align-items-baseline">
            <br />
            {outputFileName ? (
              <button
                type="button"
                className=" btn btn-dark cursor-pointer"
                onClick={e => click(e)}
              >
                Compress Image
              </button>
            ) : (
              <></>
            )}
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-3">
            <Card.Img className="image" variant="top" src={compressedLink}></Card.Img>
            {clicked ? (
              <div className="d-flex justify-content-center mt-5">
                <a
                  href={compressedLink}
                  download={outputFileName}
                  className="mt-2 btn btn-dark w-75 cursor-pointer"
                >
                  Download Image
                </a>
                <p className='text-[16px] mt-5'>Resize again and again till you hit the size required.</p>
              </div>
            ) : (
              <img
              src={Download}
              alt=""
              className="w-full h-[300px] object-contain px-8 py-4"
            />
            )}
          </div>
        </div>
      </div>
  )
}


const CompressorPage = () => {
    return (
      <div>
        <Header activeHeading={5} />
        <Compressor />
        <Footer />
      </div>
    );
  };

export default CompressorPage;

