import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const staskReducer = createReducer(initialState, {
  staskCreateRequest: (state) => {
    state.isLoading = true;
  },
  staskCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.stask = action.payload;
    state.success = true;
  },
  staskCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //load stask
  LoadSTaskRequest: (state) => {
    state.isLoading = true;
  },
  LoadSTaskSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.isLoading = false;
    state.stask = action.payload;
  },
  LoadSTaskFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // get all stasks
  getAllSTasksRequest: (state) => {
    state.isLoading = true;
  },
  getAllSTasksSuccess: (state, action) => {
    state.isLoading = false;
    state.allSTasks = action.payload;
  },
  getAllSTasksFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },

  // get all stasks of shop
  getAllSTasksShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllSTasksShopSuccess: (state, action) => {
    state.isLoading = false;
    state.stasks = action.payload;
  },
  getAllSTasksShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete stask of a shop
  deleteSTaskRequest: (state) => {
    state.isLoading = true;
  },
  deleteSTaskSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteSTaskFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all stasks --- for Open use
  getAllSTasksOpenRequest: (state) => {
    state.stasksLoading = true;
  },
  getAllSTasksOpenSuccess: (state,action) => {
    state.stasksLoading = false;
    state.stasks = action.payload;
  },
  getAllSTasksOpenFailed: (state,action) => {
    state.stasksLoading = false;
    state.error = action.payload;
  },

});

