import React from 'react';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import RCHome from './RCHome';
import RCHomeFoot from "./RCHomeFoot"
import Articles from './Articles';

function RCHomePage() {
  return (
    <div>
        <Header activeHeading={6} />
        <RCHome />
        <Articles />
        <RCHomeFoot />
        <Footer />
    </div>
  )
}

export default RCHomePage