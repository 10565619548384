import React from 'react'
import UserWCommDashboardHeader from '../components/UserWCommDashboardHeader';
import UserWComm from "../components/UserWComm";
import UserWCommDashboardSideBar from "../components/UserWCommDashboardSideBar";

const UserWCommPage = () => {
  return (
    <div>
    <UserWCommDashboardHeader />
    <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <UserWCommDashboardSideBar active={2} />
      </div>
       <UserWComm />
    </div>
  </div>
  )
}

export default UserWCommPage