import React from 'react';

const DJobCardHeader = () =>{
    return (
        <div className='w-full h-[100px] mb-10 relative z-1 bg-[#F6F6F5] items-center justify-center text-center pt-8 px-8'>
            <h1 className='text-[30px] font-[500]'>Direct Jobs</h1>
            <p>Click on a job category to Search Jobs and see all jobs of that Category.</p>
        </div>
    )
}

export default DJobCardHeader;