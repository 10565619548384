import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { sellerReducer } from "./reducers/seller";
import { productReducer } from "./reducers/product";
import { articleReducer } from "./reducers/article";
import { taskReducer } from "./reducers/task";
import { videotaskReducer } from "./reducers/videotask";
import { staskReducer } from "./reducers/stask";
import { djobReducer } from "./reducers/djob";
import { eventReducer } from "./reducers/event";
import { cartReducer } from "./reducers/cart";
import { wishlistReducer } from "./reducers/wishlist";
import { orderReducer } from "./reducers/order";
import { newcodeReducer } from "./reducers/newcode";
import { usedcodeReducer } from "./reducers/usedcode";
import { talentReducer } from "./reducers/talent";
import { eventAdReducer } from "./reducers/eventAd";
import { minertaskReducer } from "./reducers/minertask";
import { minerReducer } from "./reducers/miner";



const Store = configureStore({
  reducer: {
    user: userReducer,
    seller: sellerReducer,
    products: productReducer,
    articles: articleReducer,
    tasks: taskReducer,
    videotasks: videotaskReducer,
    stasks: staskReducer,
    djobs: djobReducer,
    events: eventReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    order: orderReducer,
    newcode: newcodeReducer,
    usedcode: usedcodeReducer,
    talent: talentReducer,
    eventAd: eventAdReducer,
    minertask: minertaskReducer,
    miner: minerReducer,
  },
});

export default Store;
