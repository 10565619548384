import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import { useSearchParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import Spinner from "../components/Spinner/Spinner";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import { categoriesData } from "../static/data";
//import { Country } from "country-state-city";
import EventAdCard from "../components/Route/EventAdCard/EventAdCard";
import { server } from "../server";
import axios from "axios";

const ProductsPage = () => {
  const {allProducts, isLoading} = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [categorry, setCategorry] = useState("");
  //const [targetedCountry, setTargetedCountry] = useState("");
  const [active, setActive] = useState(1);
  const [evdata, setEvData] = useState([]);
  
  useEffect(() => {
    axios.get(`${server}/eventAd/get-all-eventAds`, {withCredentials: true}).then((res) => {
     setEvData(res.data.eventAds);
  })
 }, []); 

  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category !== "Course");
      setData(d);
     //    window.scrollTo(0,0);
  }, [allProducts]);

  console.log(data);

  const searchbyCategoryHandler = () => {
    const sbyC = data && data.filter((i) => i.category === categorry);
    setData(sbyC);
  }

  

  return (
  <>
  {
    isLoading ? (
      <Spinner />
    ) : (
      <div>
      <Header activeHeading={2} />
      <br />
      <div className="w-full flex px-9 pt-2 pb-2">
      <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(1)}
            >
              Internal Products
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>

          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] ml-4 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              //onClick={() => setActive(2)}
            >
              External Products
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
      </div>

      <br />
      <br />

      {active === 2 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
           // onClick={searchbyCategoryHandler}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>
          
  
          
            </div>
  
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {evdata && evdata.map((i, index) => <EventAdCard data={i} key={index} />)}
          </div>
          {evdata && evdata.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No products Found!
            </h1>
          ) : null}
        </div>
      ) : null};

      {active === 1 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
            onClick={searchbyCategoryHandler}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>
          
  
          
            </div>
  
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {data && data.map((i, index) => <ProductCard data={i} key={index} user={user} />)}
          </div>
          {data && data.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No products Found!
            </h1>
          ) : null}
        </div>
      ) : null};
 
      </div>
    )
  }
  </>
  );
};

export default ProductsPage;
