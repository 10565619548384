import React, { useEffect, useState} from 'react'
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import { server } from "../server";
//import { toast } from "react-toastify";
import axios from "axios";

const ProjectWorkPage = () => {
  return (
    <div>
    <div className="w-full flex">
      <div className="flex items-start justify-bet ween w-full">
        <BeginWork />
      </div>
    </div>
  </div>
  )
}

const BeginWork = () => {
  const { user } = useSelector((state) => state.user);
  const { id } = useParams();
  const [staskTU, setSTaskTU] = useState(null);

  const userId = user?._id;

  
  useEffect(() => {
    axios
      .get(`${server}/stask/user-current-projects/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setSTaskTU(res.data.stasks);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);
   
  
 
 const staskData = staskTU && staskTU.find((item) => item._id === id);

 //const staskId = staskData?._id;

 const RL1 = staskData?.resourceLink1;
 const RL2 = staskData?.resourceLink2;
 const RL3 = staskData?.resourceLink3;

 const userIsQualify = staskData && staskData.workers.some((it) => it.workerId === userId);
 console.log(userIsQualify);
    

    return (
      <>
      {userIsQualify ? (
        <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
        
      >
        <div className={`${styles.section} w-[90%] 800px:w-[90%]`}>
          <br />
          <br />
        <h1
            className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
          >
            Project Execution Page
          </h1> 
          <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
            Welcome {user?.name} to project: <br /><span className='text-[red]'>{staskData?.name}</span>
          </h2>
          <p className='text-[16px] font-[Poppins] font-[400] text-[#000000ba]'>
            ProjectId: {staskData?._id}
          </p>
          <br />
          <hr />
          <br />
  
          {staskData?.workStatus === "Not Started" && (
            <>
            <h3
            className={`text-[16px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
          >
            Project Status: <br /><span className='text-[15px] text-[red]'> - Not Started</span>
          </h3>
          <br />
            </>
          )}
          
  
          {staskData?.workStatus === "Started" && (
            <>
            <h3
            className={`text-[16px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
          >
            Project Status: <br /><span className='text-[15px] text-[green]'> - Started</span>
          </h3>
          <br />
            </>
          )}
          
  
          {staskData?.workStatus === "Completed" && (
            <>
            <h3
            className={`text-[16px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
          >
            Project Status: <br /><span className='text-[15px]'> - Completed</span>
          </h3>
          <br />
            </>
          )}
          
  
          {staskData?.workStatus === "Not Started" && (
            <>
            <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
            WHAT NEXT? <br />You don't have anything to do for now till the project begins. You will receive details on your email when the project begins. However, it is advisable you check this project link/page always to know when the project actually begins. Goodluck!
          </h2> 
          <br />
            </>
          )}
          
                  
          {staskData?.workStatus === "Started" && (
            <>
            <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
            BEGIN WORK: <br />Please find bellow the full details of this project and the link(s) to the project resource materials.
          </h2> 
          <br />
            </>
          )}
          
           
  
          {staskData?.workStatus === "Completed" && (
            <>
            <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
            PROJECT COMPLETED: <br />This project has been completed successfully. 
          </h2> 
          <br />
            </>
          )}
          
          
  
          {staskData?.workStatus === "Started" && (
            <>
            <h2 className="pt-1 mb-1 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
            Project Full Details:
          </h2>  
          <h4 className="pt-2 text-[16px] font-[400] pb-3 whitespace-pre-line text-[#000000ba]">
            {staskData?.description}
          </h4> 

          <h2 className="pt-1 mb-1 text-[18px] font-[Poppins] font-[600] text-[#000000ba]">
            Download Project Guideline and other resource bellow:
          </h2>
  
          {RL1 !== "" ? (
          <Link to={staskData?.resourceLink1} target="_blank" className="inline-block">
          <div className={`${styles.button} mt-5 mr-2`}>
           <span className="text-[#fff] font-[Poppins] !text-[16px]">
          Link 1
          </span>
          </div>
          <br />
          </Link>
          ) : null}
  
        {RL2 !== "" ? (
          <Link to={staskData?.resourceLink2} target="_blank" className="inline-block">
          <div className={`${styles.button} mt-5 mr-2`}>
           <span className="text-[#fff] font-[Poppins] !text-[16px]">
          Link 2
          </span>
          </div>
          <br />
          </Link>
          ) : null}
  
        {RL3 !== "" ? (
          <Link to={staskData?.resourceLink3} target="_blank" className="inline-block">
          <div className={`${styles.button} mt-5 mr-2`}>
           <span className="text-[#fff] font-[Poppins] !text-[16px]">
          Link 3
          </span>
          </div>
          <br />
          </Link>
          ) : null}
  
          <br />
            
  
          <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
            HAVING ANY ISSUE?: <br />Kindly contact the Project Manager via e-mail: {staskData?.shop.email} 
          </h2> 
          <br />
          <br />
          <br />
            </>
          )}
                
          
        </div>
      </div>
      ): null}
      </>
      
      
  )
}

export default ProjectWorkPage

