import React, { useEffect } from 'react'
import styles from "../../styles/styles";
import logoo1 from "../../Assests/logos/totlesoftPay.png";
import logoo2 from "../../Assests/logos/unicef.png";
import logoo3 from "../../Assests/logos/larosy.png";
import logoo4 from "../../Assests/logos/shell.png";
import logoo5 from "../../Assests/logos/deepJoy.png";
import logoo6 from "../../Assests/logos/blendTech.png";
import logoo7 from "../../Assests/logos/dangote.png";
import "./sponsors.css";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function SponsorsLogo() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()


  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <div>
        <div className={`${styles.section} w-[90%] 800px:w-[100%] py-6`}>
        <div className={`${styles.heading} py-8`}>
          <h1>{t('Supporters')}</h1>
         <section className="companies marquee">
          <div className="marquee__inner" aria-hidden="true">
          <img src={logoo1} alt="logo" className="companies__logo" />
          <img src={logoo2} alt="logo" className="companies__logo" />
          <img src={logoo3} alt="logo" className="companies__logo" />
          <img src={logoo4} alt="logo" className="companies__logo" />
          <img src={logoo5} alt="logo" className="companies__logo" />
          <img src={logoo6} alt="logo" className="companies__logo" />
          <img src={logoo7} alt="logo" className="companies__logo" />
          
          <img src={logoo1} alt="logo" className="companies__logo" />
          <img src={logoo2} alt="logo" className="companies__logo" />
          <img src={logoo3} alt="logo" className="companies__logo" />
          <img src={logoo4} alt="logo" className="companies__logo" />
          <img src={logoo5} alt="logo" className="companies__logo" />
          <img src={logoo6} alt="logo" className="companies__logo" />
          <img src={logoo7} alt="logo" className="companies__logo" />
          </div>
          </section>
          </div>
        </div>
    </div>
  )
}

export default SponsorsLogo