import React from "react";
import { DataGrid } from "@mui/x-data-grid";


const EhistoryInfo = ({
    eHistoryData
  }) => {
        

      const columns = [
        { field: "id", 
        headerName: "Id", 
        minWidth: 100, 
        flex: 0.7, 
      },
        {
          field: "earning",
          headerName: "Earning",
          minWidth: 100,
          flex: 0.7,
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 100,
          flex: 0.6,
        },
        {
          field: "date",
          headerName: "Date",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const row = [];

    eHistoryData &&
    eHistoryData.forEach((item) => {
      row.push({
        id: item._id,
        earning: item.earnName,
        amount: "$ " + item.earnAmount,
        date: item.earnDate.slice(0, 10),
      });
    });
  
    return (
      <div className="bg-[#f5f6fb] px-3 800px:px-5 py-2 rounded">
       <div className="w-full bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
        </div>          
      </div>
    );
  };

  export default EhistoryInfo;