import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTalent } from "../../redux/actions/talent";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const CreateTalent = () => {
  const { user } = useSelector((state) => state.user);
  const { success, error } = useSelector((state) => state.articles);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [descValue, setDescValue] = useState("");
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [charge, setCharge] = useState(0);
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [access1URL, setAccess1URL] = useState("");
  const [access2URL, setAccess2URL] = useState("");
  const [access3URL, setAccess3URL] = useState("");
  const [access4URL, setAccess4URL] = useState("");
  const [access5URL, setAccess5URL] = useState("");
  

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      ["link", "image", "video"],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ],
  };

  const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size", "font",
  ];

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const description = descValue;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("charge", charge);
    newForm.append("subscriptionAmount", subscriptionAmount);
    newForm.append("access1URL", access1URL);
    newForm.append("access2URL", access2URL);
    newForm.append("access3URL", access3URL);
    newForm.append("access4URL", access4URL);
    newForm.append("access5URL", access5URL);
    newForm.append("category", category);
    newForm.append("userId", user._id);
    newForm.append("userEmail", user.email);
    dispatch(
        createTalent({
        name,
        description,
        charge,
        subscriptionAmount,
        access1URL,
        access2URL,
        access3URL,
        access4URL,
        access5URL,
        category,
        userId: user._id,
        userEmail: user.email,
        images,
      })
    );
    setIsLoading(false);
    toast.success("Gig created successfully!");
    navigate("/profile");
    window.location.reload();
  };

  const hide = false;

  return (
   <>
      {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Gig</h5>
      {/* create Talent form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Gig Title..."
            maxLength={100}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <ReactQuill
           theme="snow"
           value={descValue}
           className="mt-2 h-[500px] overflow-scroll appearance-none block w-full pt-2 px-3 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
           onChange={setDescValue}
           modules={modules} 
           formats={formats}
           placeholder="Enter description ...."
          />
        </div>
       
        <br />
        <div>
          <label className="pb-2">
           Subscription Amount (USD) <br />
           <small className="text-[14px] text-[blue]">kindly leave it at '0' if it s not open to subscription</small>
          </label>
          <input
            type="number"
            name="date"
            value={subscriptionAmount}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSubscriptionAmount(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Amount you Charge to do this job for a client (USD) <br />
           <small className="text-[14px] text-[blue]"> kindly leave it at '0' if it s not open to outright purchase</small>
          </label>
          <input
            type="number"
            name="date"
            value={charge}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setCharge(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>

        
        <br />
        <div>
          <label className="pb-2">
           Access 1 URL (Link to access files/resources after purchase/subscribing to your package)
          </label>
          <input
            type="text"
            name="date"
            value={access1URL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAccess1URL(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Access 2 URL (Link to access files/resources after purchase/subscribing to your package)
          </label>
          <input
            type="text"
            name="date"
            value={access2URL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAccess2URL(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Access 3 URL (Link to access files/resources after purchase/subscribing to your package)
          </label>
          <input
            type="text"
            name="date"
            value={access3URL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAccess3URL(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Access 4 URL (Link to access files/resources after purchase/subscribing to your package)
          </label>
          <input
            type="text"
            name="date"
            value={access4URL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAccess4URL(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>

        <br />
        <div>
          <label className="pb-2">
           Access 5 URL (Link to access files/resources after purchase/subscribing to your package)
          </label>
          <input
            type="text"
            name="date"
            value={access5URL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAccess5URL(e.target.value)}
            placeholder="Enter date with above format..."
          />
        </div>
       
        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category">Choose a category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
       
        <br />
        <div>
          {hide && (
            <>
            <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
          </>
          )}
          <br />
          <div>
            <input
              type="submit"
              value="Create"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] rounded-[3px] bg-[black] text-[#fff] sm:text-sm"
            />
          </div>
          <br />

        </div>
      </form>
    </div>
    )}
      </>
   
    
  );
};

export default CreateTalent;
