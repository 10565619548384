import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import {GiTeamUpgrade} from "react-icons/gi";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import Spinner from "../Spinner/Spinner";
import { getAllSellers } from "../../redux/actions/sellers";
import { getAllUsers } from "../../redux/actions/user";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();

  const { adminOrders } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);
  const { users, user } = useSelector((state) => state.user);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [taskReward, setTaskReward] = useState(0);
  const [taskDetails, setTaskDetails] = useState("");
  const [taskClass, setTaskClass] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [taskDuration, setTaskDuration] = useState(60);
  const [taskURL, setTaskURL] = useState("");
  const [codeTC, setCodeTC] = useState("");
  const [taskSubDetails, setTaskSubDetails] = useState("");
  const [taskTitle, setTaskTitle] = useState("");

  const [isPaymentDay, setIsPaymentDay] = useState("");
  const [isBonusTime, setIsBonusTime] = useState("");
  const [isArtTime, setIsArtTime] = useState("");

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
    dispatch(getAllUsers());
  }, []);


  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  

  const mcDataToUse = mycontrolData && mycontrolData.find((iti) => iti.controlPin === "114455chris");
  console.log(mcDataToUse);
 
 const controlPin = "114455chris";

   
  const handleMyControlUpdate = async () => {
    setIsLoading(true);
    await axios
      .put(`${server}/mycontrol/update-mycontrol/${controlPin}`,{
        updatorEmail: user?.email,
        isPaymentDay: isPaymentDay,
        isBonusTime: isBonusTime,
        isArtTime: isArtTime,
      },{withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        toast.success("Event updated!");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });
  };

   const adminEarning = adminOrders && adminOrders.reduce((acc, item) => acc + item.totalPrice * .10, 0);


   const adminBalance = adminEarning?.toFixed(2);


   const handleCreateTask = async () => {

    setIsLoading(true);

    await axios
      .post(`${server}/minertask/create-task`,{
        taskReward,
        taskDetails,
        taskSubDetails,
        taskTitle,
        taskClass,
        buttonText,
        taskDuration,
        taskURL,
        codeTC,
      
      },{withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        toast.success("Task Added!");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });

   };

   

  const columns = [
    { field: "id", 
    headerName: "Order ID", 
    minWidth: 150, 
    flex: 0.7 
  },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
     
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
  ];

  const row = [];
  adminOrders &&
  adminOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: item?.totalPrice + " $",
        status: item?.status,
        createdAt: item?.createdAt.slice(0,10),
      });
    });

  return (
   <>
    {
      isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full py-4 px-6">
        <h3 className="text-[30px] font-Poppins-700 pb-2">Admin Dashboard</h3>
        <div className="w-full block 800px:flex items-center justify-between">
          <div className="w-full mb-4 800px:w-[32%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
              <AiOutlineMoneyCollect
                size={25}
                className="mr-2"
                fill="#00000085"
              />
              <h3
                className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
              >
                Total Earning
              </h3>
            </div>
            <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">$ {adminBalance}</h5>
          </div>
  
          <div className="w-full mb-4 800px:w-[32%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
              <GiTeamUpgrade size={25} className="mr-2" fill="#00000085" />
              <h3
                className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
              >
                All Clients
              </h3>
            </div>
            <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{sellers && sellers.length}</h5>
            <Link to="/admin-sellers">
              <h5 className="pt-4 pl-2 text-[#077f9c]">View Clients</h5>
            </Link>
          </div>
  
          <div className="w-full mb-4 800px:w-[32%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
              <HiOutlineUserGroup
                size={25}
                className="mr-2"
                fill="#00000085"
              />
              <h3
                className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
              >
                All Users
              </h3>
            </div>
            <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{users && users.length}</h5>
            <Link to="/admin-users">
              <h5 className="pt-4 pl-2 text-[#077f9c]">View Users</h5>
            </Link>
          </div>
        </div>
        <br />

       <section>
            <div className="w-full p-4 block 800px:flex">
              <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
                <div className="flex">
                <h3 className="text-[30px] font-Poppins-700 pb-2">Event Control Pannel</h3>
                 </div><br />
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Event Name:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={!mcDataToUse ? "No Name" : mcDataToUse.name}
                      />
                    </div>
                    </div>


                    <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Authorizer's Email:</label>
                      <input
                        type="text"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        value={user?.email}
                      />
                    </div>
                    </div>
                  
                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">It's Pay Day</label>
                      <div className="mt-1">
                        <select
                        name="isPaymentDay"
                        onChange={(e) => setIsPaymentDay(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />

                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">It's Bonus Time</label>
                      <div className="mt-1">
                        <select
                        name="isBonusTime"
                        onChange={(e) => setIsBonusTime(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />


                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">It's Art Time</label>
                      <div className="mt-1">
                        <select
                        name="isArtTime"
                        onChange={(e) => setIsArtTime(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />


                  <br />
                <button 
                className={`${styles.button} text-[#fff] justify-center items-center text-center`}
                onClick={handleMyControlUpdate}
                >
                   Update
                </button>
               <br />                 
                
    
                </div>
              </div>
              
            </section>
            <br />
            <br />


            <section>
            <div className="w-full p-4 block 800px:flex">
              <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
                <div className="flex">
                <h3 className="text-[30px] font-Poppins-700 pb-2">Create Tasks for Miners</h3>
                 </div><br />
               
                 <div className="mb-3">
              <label
                htmlFor="taskReward"
                className="block text-sm font-medium"
              >
                Task Reward:
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  id="taskReward"
                  name="taskReward"
                  required
                  value={taskReward}
                  onChange={(e) => setTaskReward(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />

            <div className="mb-3">
              <label
                htmlFor="taskTitle"
                className="block text-sm font-medium"
              >
                Task Title:
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="taskTitle"
                  name="taskTitle"
                  required
                  value={taskTitle}
                  onChange={(e) => setTaskTitle(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />


              <div className="mb-3">
              <label
                htmlFor="taskDetails"
                className="block text-sm font-medium"
              >
                Task Details:
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="taskDetails"
                  name="taskDetails"
                  required
                  value={taskDetails}
                  onChange={(e) => setTaskDetails(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />

            <div className="mb-3">
              <label
                htmlFor="taskSubDetails"
                className="block text-sm font-medium"
              >
                Task Sub Details:
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="taskSubDetails"
                  name="taskSubDetails"
                  required
                  value={taskSubDetails}
                  onChange={(e) => setTaskSubDetails(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />
            

            <div className="mb-3">
              <label
                htmlFor="taskDetails"
                className="block text-sm font-medium"
              >
                Code To Use:
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="codeTC"
                  name="codeTC"
                  required
                  value={codeTC}
                  onChange={(e) => setCodeTC(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />

            <div className="mb-3">
              <label
                htmlFor="taskDetails"
                className="block text-sm font-medium"
              >
                Task Duration:
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  id="taskDuration"
                  name="taskDuration"
                  required
                  value={taskDuration}
                  onChange={(e) => setTaskDuration(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />


            <div className="mb-3">
              <label
                htmlFor="taskDetails"
                className="block text-sm font-medium"
              >
                Task URL:
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="taskURL"
                  name="taskURL"
                  required
                  value={taskURL}
                  onChange={(e) => setTaskURL(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <br />
                  
                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Task Class:</label>
                      <div className="mt-1">
                        <select
                        name="taskClass"
                        onChange={(e) => setTaskClass(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="Nocode">Nocode</option>
                        <option value="Findcode">Findcode</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />


                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Button Text</label>
                      <div className="mt-1">
                        <select
                        name="buttonText"
                        onChange={(e) => setButtonText(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="Check">Check</option>
                        <option value="Enter Code">Enter Code</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  


                  <br />
                <button 
                className={`${styles.button} text-[#fff] justify-center items-center text-center`}
                onClick={handleCreateTask}
                >
                   Create
                </button>
               <br />                 
                
    
                </div>
              </div>
              
            </section>
       

  
        <br />
        <h3 className="text-[22px] font-Poppins pb-2">Latest Orders</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={4}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      </div>
      )
    }
   </>
  );
};

export default AdminDashboardMain;

/*

cellClassName: function(params){
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },


      value={legalName}
                        onChange={(e) => setLegalName(e.target.value)}

      */