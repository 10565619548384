import React, { useState, useEffect } from "react";
import styles from "../styles/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { server } from "../server";
import axios from "axios";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";


const AssignWorkerPage = () => {
    const { allSTasks } = useSelector((state) => state.stasks);
   const { id, workaId } = useParams();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
           
       
        useEffect(() => {
          const data = allSTasks && allSTasks.find((i) => i._id === id);
        setData(data);
        
        }, [allSTasks, id]);

        let appliData = [];
        
        appliData = data && data?.applicants.find((i) => i.applicantId === workaId);
                 
        
        const worker = {
          workerName: appliData?.applicantName,
          workerEmail: appliData?.applicantEmail,
          workerId: appliData?.applicantId,
          workerAvatar: appliData?.applicantAvatar,
          workerLocation: appliData?.applicantLocation,
          workerLanguage: appliData?.applicantLanguage,
          projectID: data?._id,
          projectTitle: data?.name,
          }
        
        const assignHandler = async () => {
    
          
          const workerEmail = appliData?.applicantEmail;
      
                  await axios
                  .put(
                  `${server}/stask/add-worker-to-project/${id}`,
                  {
                      worker: worker,
                      workerEmail: workerEmail,
                  },
                  { withCredentials: true }
                )
                .then((res) => {
                    toast.success("Applicant added to project successful!");
                    navigate('/dashboard-s-tasks');
                    window.location.reload();
                    })
                 .catch((error) => {
                  toast.error(error.response.data.message);
            });
        }
        
        
      return (
        <div>
          <Header />
         {data ? 
          <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500]">Project Assigning</h1><br />
  
          <img
            src={`${appliData?.applicantAvatar?.url}`}
            className="w-[70px] h-[70px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
            alt=""
                />
            <h4 className="pb-3 font-[400] text-[15px]">
            Name: {appliData.applicantName}
            </h4>
  
            <h4 className="pb-3 font-[400] text-[15px]">
            Location: {appliData.applicantLocation}
            </h4>
            
            <h4 className="pb-3 font-[400]">
            E-mail: {appliData.applicantEmail}
            </h4>
            <br />
            <hr />
            <br />
  
          <p>By Clicking the "Assign" button you will be adding {appliData?.applicantName} to the project: <br /> 
          <span className="text-[25px] font-[600]">{data?.name}</span>
          </p>
          <div className={`${styles.button} mt-5 cursor-pointer`}
            onClick={assignHandler}
            >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                 Assign
                 </span>
            </div>
          </div>
         : 
          <div className="w-full py-5 px-10">
            <h1 className="text-[30px] text-[500]">Ops! Data Loading failed!</h1>
            <p>Please try again later.</p>
          </div>
       }
       <Footer />
        </div>
      );

     }

export default AssignWorkerPage;
