import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import { useSearchParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import Spinner from "../components/Spinner/Spinner";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";

const CoursePage = () => {
  const {allProducts, isLoading} = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
      const d =
      allProducts && allProducts.filter((i) => i.category === "Course");
      setData(d);
   
    //    window.scrollTo(0,0);
  }, [allProducts]);

  console.log(data);

  return (
  <>
  {
    isLoading ? (
      <Spinner />
    ) : (
      <div>
      <Header />
      <br />
      <br />
      <div className={`${styles.section} bg-[#fff] p-4`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No Course Found!
          </h1>
        ) : null}
      </div>
      </div>
    )
  }
  </>
  );
};

export default CoursePage;
