import React from "react"
import Branding from "../components/Branding/Branding";
import Header from "../components/Layout/Header";

const BrandingPage = () => {
  return (
    <div>
      <Header />
      <Branding />
      </div>
  );
};


export default BrandingPage 

