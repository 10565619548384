import axios from "axios";
import { server } from "../../server";

// create task
export const createTask =
  (
    name,
    description,
    category,
    tags,
    taskPayment,
    paymentRef,
    taskBudget,
    taskQuantity,
    targetedURL,
    facebookURL,
    twitterURL,
    instagramURL, 
    youtubeURL, 
    linkedinURL, 
    tiktokURL, 
    whatsAppGroupURL,
    spotifyURL,
    boomPlayURL,
    soundCloudURL,
    telegramURL,
    targetedCountry,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "taskCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/task/create-task`,
        name,
        description,
        category,
        tags,
        taskPayment,
        paymentRef,
        taskBudget,
        taskQuantity,
        targetedURL,
        facebookURL,
        twitterURL,
        instagramURL, 
        youtubeURL, 
        linkedinURL, 
        tiktokURL, 
        whatsAppGroupURL,
        spotifyURL,
        boomPlayURL,
        soundCloudURL,
        telegramURL,
        targetedCountry,
        shopId,
        images
      );
      dispatch({
        type: "taskCreateSuccess",
        payload: data.task,
      });
    } catch (error) {
      dispatch({
        type: "taskCreateFail",
        payload: error.response.data.message,
      });
    }
  };


  // get all tasks
  export const getAllTasks = () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllTasksRequest",
      });
  
      const { data } = await axios.get(`${server}/task/get-all-tasks`);
      dispatch({
        type: "getAllTasksSuccess",
        payload: data.tasks,
      });
    } catch (error) {
      dispatch({
        type: "getAllTasksFailed",
        payload: error.response.data.message,
      });
    }
  };


  // get All Tasks of a shop
export const getAllTasksShop = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "getAllTasksShopRequest",
      });
  
      const { data } = await axios.get(
        `${server}/task/get-all-tasks-shop/${id}`
      );
      dispatch({
        type: "getAllTasksShopSuccess",
        payload: data.tasks,
      });
    } catch (error) {
      dispatch({
        type: "getAllTasksShopFailed",
        payload: error.response.data.message,
      });
    }
  };


  // load task
export const loadTask = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadTaskRequest",
    });
    const { data } = await axios.get(`${server}/task/get-task`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadTaskSuccess",
      payload: data.task,
    });
  } catch (error) {
    dispatch({
      type: "LoadTaskFail",
      payload: error.response.data.message,
    });
  }
};

  // delete task of a shop
export const deleteTask = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteTaskRequest",
      });
  
      const { data } = await axios.delete(
        `${server}/task/delete-shop-task/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteTaskSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteTaskFailed",
        payload: error.response.data.message,
      });
    }
  };

  // get all tasks --- Open
export const getAllTasksOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllTasksOpenRequest",
    });

    const { data } = await axios.get(`${server}/task/open-all-tasks`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllTasksOpenSuccess",
      payload: data.tasks,
    });
  } catch (error) {
    dispatch({
      type: "getAllTasksOpenFailed",
      payload: error.response.data.message,
    });
  }
};