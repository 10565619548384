import axios from "axios";
import { server } from "../../server";

// create eventAd
export const createEventAd =
  (
      name,
      eventDate,
      eventTime,
      eventVenue,
      eventCoverAddress,
      eventLink,
      eventBy,
      poweredBy,
      supportedBy,
      speaker1,
      speaker2,
      speaker3,
      speaker4,
      speaker5,
      shopId,
      shopEmail,
      start_Date,
      finish_Date,
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "eventAdCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/eventAd/create-eventAd`,
        name,
        eventDate,
        eventTime,
        eventVenue,
        eventCoverAddress,
        eventLink,
        eventBy,
        poweredBy,
        supportedBy,
        speaker1,
        speaker2,
        speaker3,
        speaker4,
        speaker5,
        shopId,
        shopEmail,
        start_Date,
        finish_Date,
      );
      dispatch({
        type: "eventAdCreateSuccess",
        payload: data.eventAd,
      });
    } catch (error) {
      dispatch({
        type: "eventAdCreateFail",
        payload: error.response.data.message,
      });
    }
  };

  // delete eventAd of a shop
export const deleteEventAd = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteEventAdRequest",
      });
  
      const { data } = await axios.delete(
        `${server}/eventAd/delete-shop-eventAd/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteEventAdSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteEventAdFailed",
        payload: error.response.data.message,
      });
    }
  };

  