import React from 'react';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import RWHome from './RWHome';
import RCHomeFoot from "../../pages/ResourceCenter/RCHomeFoot";
import Rewards from './Rewards';
import SliderMobile from '../../components/Slider/SliderMobile';

function RWHomePage() {
  return (
    <div>
        <Header activeHeading={1} />
        <SliderMobile />
        <RWHome />
        <Rewards />
        <RCHomeFoot />
        <Footer />
    </div>
  )
}

export default RWHomePage